import { WizardNew as Wizard, Section } from "../../../../components/wizard";
import { useWizardStepsManager } from "hooks/useWizardStepsManager";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { reset } from "../state/GroupTemplateSlice";
import CreateConfiguration from "./CreateConfiguration";
import CreateCustomers from "./CreateCustomers";
import CreateEnrollment from "./CreateEnrollment";
import CreatePacks from "./CreatePacks";
import Observable from "utils/Observable";
import { GroupTemplatePacksConfirmationModal } from "components/assignmentModals/groupTemplatePacksConfirmationModal/GroupTemplatePacksConfirmationModal";
import { savePacks } from "../state/thunks/TemplateAvailablePacksThunk";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { bindAction } from "interfaces";
import { TemplateTypes } from "enums";
import { NotifyStepSwitch } from "components/notifyStep/NotifyStepSwitch";
import NotifyWizardStep from "components/notifyStep/NotifyWizardStep/NotifyWizardStep";
import { createNotificationSettings } from "features/People/Groups/AddGroup/state/addGroupActions";
import useNotify from "../hooks/useNotify";

export type Props = PropsFromRedux;

enum Pages {
  Configuration,
  Enrollment,
  Customers,
  Packs,
  Notify,
}

const pageIndexes = [Pages.Configuration, Pages.Enrollment, Pages.Customers, Pages.Packs, Pages.Notify];

export function CreateGroupTemplate(props: Props) {
  const {
    isLoadingGroupInfo,
    groupId,
    isEnrollmentValid,
    resetTemplateState,
    selectedCustomerIds,
    selectedPackIds,
    addPacksToGroupTemplate,
    addNotificationSettings,
  } = props;
  const [isConfigValid, setIsConfigValid] = useState(false);
  const [isConfigDirty, setIsConfigDirty] = useState(false);
  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager(pageIndexes);
  const selectedPacks = useRef<PackMap>(new Map());
  const onTriggerPacksConfirmationObserver = useMemo(() => new Observable(), []);
  const [notyStep] = useNotify(addNotificationSettings);

  useEffect(() => {
    return () => {
      resetTemplateState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const onCancel = () => navigate("/people/groups");

  const handleFinish = async () => {
    await notyStep.saveNotificationSettings(`TemplateGroup_${groupId}`);
    onFinish();
    navigate("/people/groups");
  };

  const handleOnFinish = async () => {
    if (selectedCustomerIds.length > 0 && selectedPackIds.length > 0) {
      onTriggerPacksConfirmationObserver.notify(() => handleFinish(), selectedPackIds);
    } else {
      await addPacksToGroupTemplate(groupId, [...selectedPacks.current.values()], []);
      await handleFinish();
    }
  };

  const nextConfigButton = getNextButtonText(groupId > 0, isConfigDirty);

  return (
    <>
      <Dimmer active={isLoadingGroupInfo} inverted>
        <Loader active={isLoadingGroupInfo} />
      </Dimmer>
      <Wizard
        title="Create Group Template"
        finishButtonLabel="Finish"
        onCancel={onCancel}
        onProgress={onNext}
        onRegress={onPrevious}
        onFinish={handleOnFinish}
        isSaveInProgress={false}
      >
        <Section
          label="Configuration"
          className="scrollable-content"
          isLocked={!isConfigValid}
          nextButtonLabel={nextConfigButton}
          required
        >
          <CreateConfiguration
            acceptHandlers={wizardPages[Pages.Configuration]}
            onIsValidChange={setIsConfigValid}
            onIsDirtyChange={setIsConfigDirty}
            isDirty={isConfigDirty}
          />
        </Section>
        <Section label="Enrollment" className="scrollable-content" isLocked={!isEnrollmentValid}>
          <CreateEnrollment acceptHandlers={wizardPages[Pages.Enrollment]} />
        </Section>
        <Section label="Customers" className="scrollable-content">
          <CreateCustomers acceptHandlers={wizardPages[Pages.Customers]} />
        </Section>
        <Section label="Packs" className="scrollable-content">
          <CreatePacks acceptHandlers={wizardPages[Pages.Packs]} packMap={selectedPacks.current} />
        </Section>
        <Section
          label="Notify"
          className="scrollable-content"
          isLocked={!(notyStep.isNotifyStepLoaded && notyStep.isNotifyStepValid)}
          preRender
        >
          <NotifyWizardStep
            templateType={TemplateTypes.ApproveGroupTemplate}
            onLoaded={notyStep.onNotifyStepLoaded}
            onValidChange={notyStep.onNotifyTabValidChanged}
            shouldNotify={selectedCustomerIds.length > 0 && notyStep.shouldNotify}
            communicationChannel={notyStep.communicationChannel}
            hideCustomSender={true}
            isDisabled={selectedCustomerIds.length <= 0}
            renderSwitch={(switchProps) => (
              <NotifyStepSwitch
                config={notyStep.notifyConfig}
                onNotifyConfigChange={notyStep.setNotifyConfig}
                switchProps={switchProps}
              />
            )}
          />
        </Section>
      </Wizard>
      <GroupTemplatePacksConfirmationModal
        onTriggerModalObserver={onTriggerPacksConfirmationObserver}
        packMap={selectedPacks.current}
        templateId={groupId}
        selectedCustomerIds={selectedCustomerIds}
        onConfirmAction={addPacksToGroupTemplate}
      />
    </>
  );
}

const getNextButtonText = (isCreated: boolean, isDirty: boolean) => {
  if (!isCreated) {
    return "Save & Continue";
  }

  return isDirty ? "Update & Continue" : "Continue";
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;

  return {
    groupId: groupTemplate.base.groupId,
    isLoadingGroupInfo: groupTemplate.base.isLoading,
    isEnrollmentValid: groupTemplate.base.isEnrollmentValid,
    selectedCustomerIds: state.people.groupTemplate.customers.customersToAdd,
    selectedPackIds: state.people.groupTemplate.availablePacks.packsToAdd,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addPacksToGroupTemplate: bindAction(savePacks, dispatch),
    addNotificationSettings: bindAction(createNotificationSettings, dispatch),
    resetTemplateState: () => dispatch(reset()),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateGroupTemplate);
