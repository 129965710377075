import { Table } from "semantic-ui-react";

import dateTimeUtils from "../../../../utils/dateTimeUtils";
import PriorityCell from "../../../priorityLevels/priorityCell/PriorityCell";
import ExpiresListTemplate from "../../../ExpiresTemplate/ExpiresListTemplate/ExpiresListTemplate";
import EllipsisPopupButton from "../../../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { Tooltip } from "../../../common/tooltip";
import { PublishedStatus } from "../../../common/publishedStatus";
import { TextTruncate } from "../../../textTruncators/TextTruncators";
import { ColumnOptions } from "../../../../interfaces";
import { ContentType, PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { PriorityLevels, PublishedStatusTypes, RouteNames } from "../../../../enums";
import { EditPriorityLinkButton } from "../../../buttons/linkButtons";
import { useNavigate } from "react-router-dom";
import { Title } from "components/listViewTemplates";
import cn from "classnames";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";
import { FlowGoals } from "features/Library/Flows/types/models";

import styles from "./flowsAssignmentListRow.module.scss";

export interface FlowsViewItemModel {
  id: number;
  title: string;
  publisher: string;
  dateCreated: string;
  dateModified: string;
  usersCount: number;
  published: boolean;
  objective: string | null;
  priorityLevelId: number;
  dueDate?: string;
  daysToComplete?: number;
  inherited: boolean;
  canRemove: boolean;
  permissionIssuedDate: string;
  expirationDate?: string | null;
  goals?: FlowGoals;
}

export interface FlowsAssignmentListRowProps {
  flow: FlowsViewItemModel;
  peopleType: PeopleType;
  isSelected?: boolean;
  columnOptions: ColumnOptions[];
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  isReadOnly?: boolean;
  deepLink?: boolean;
}

export const FlowsAssignmentListRow = (props: FlowsAssignmentListRowProps): React.ReactElement => {
  const { flow, columnOptions, contextMenuButtonHandlers, isReadOnly, isSelected, peopleType, deepLink } = props;

  const navigate = useNavigate();

  return (
    <>
      <Table.Cell width={columnOptions[0].width} disabled={flow.inherited}>
        <Tooltip
          target={
            deepLink ? (
              <Title
                url={!flow.inherited ? `/${RouteNames.contentFlows}/${flow.id}` : undefined}
                title={flow.title}
                className={cn({ "normal-color": !flow.inherited })}
                onTitleClick={() => navigate(`/${RouteNames.contentFlows}/${flow.id}`)}
              />
            ) : (
              <TextTruncate className="flow-title">{flow.title}</TextTruncate>
            )
          }
          content={flow.title}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <ObjectiveLabel objective={flow.goals?.objective} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width} disabled={flow.inherited}>
        <Tooltip target={<TextTruncate>{flow.publisher}</TextTruncate>} content={flow.publisher} />
      </Table.Cell>
      {!columnOptions[3].isHide && (
        <Table.Cell width={columnOptions[3].width} disabled={flow.inherited}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(flow.published)} />
        </Table.Cell>
      )}
      {!columnOptions[4].isHide && (
        <Table.Cell width={columnOptions[4].width}>
          <PriorityCell
            inherited={flow.inherited}
            peopleType={peopleType}
            contentType={ContentType.Flow}
            priorityLevel={flow.priorityLevelId as PriorityLevels}
            dueDate={flow.dueDate}
            daysToComplete={flow.daysToComplete}
          />
        </Table.Cell>
      )}
      {!columnOptions[5].isHide && (
        <Table.Cell width={columnOptions[5].width} disabled={flow.inherited}>
          <Tooltip
            target={<TextTruncate className="asset-title">{dateTimeUtils.formatDate(flow.dateCreated)}</TextTruncate>}
            content={dateTimeUtils.formatDate(flow.dateCreated)}
          />
        </Table.Cell>
      )}
      {!columnOptions[6].isHide && (
        <Table.Cell width={columnOptions[6].width} disabled={flow.inherited}>
          <ExpiresListTemplate expirationDate={flow.expirationDate} />
        </Table.Cell>
      )}
      <Table.Cell className={styles["align-right"]} width={columnOptions[7].width}>
        <EllipsisPopupButton circle disabled={flow.inherited || isReadOnly || isSelected}>
          <EditPriorityLinkButton onClick={() => contextMenuButtonHandlers?.onEditPriority?.(flow.id)} />
          <RemovePriorityButton
            onClick={() => contextMenuButtonHandlers?.onRemove?.(flow.id)}
            isDisabled={!flow.canRemove}
            peopleType={peopleType}
            showTooltip={!flow.canRemove}
            context="people"
            entityType={ContentType.Flow}
            multiple={false}
          />
        </EllipsisPopupButton>
      </Table.Cell>
    </>
  );
};
