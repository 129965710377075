import { FC, SyntheticEvent, useState } from "react";
import { DropdownProps } from "semantic-ui-react";
import { UsersGroupsContext } from "../../../../../../enums";
import { PeopleSegmentationDropdown } from "../../../../../../components";

export interface PeopleContextWrapperProps {
  onContextChanged: (context: UsersGroupsContext) => void;
  usersGroupsContext: UsersGroupsContext;
  renderUserLicenses: (customHeaderContent: React.ReactElement) => React.ReactElement;
  renderGroupLicenses: (customHeaderContent: React.ReactElement) => React.ReactElement;
}

const PeopleContextWrapper: FC<PeopleContextWrapperProps> = ({
  renderUserLicenses,
  renderGroupLicenses,
  usersGroupsContext,
  onContextChanged,
}): React.ReactElement => {
  const [context, setContext] = useState<UsersGroupsContext>(usersGroupsContext);

  const onChangeContext = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
    const value = data.value as UsersGroupsContext;
    setContext(value);
    onContextChanged(value);
  };

  const renderContextSwitcher = (): React.ReactElement => (
    <PeopleSegmentationDropdown onChange={onChangeContext} defaultOption={context} />
  );

  const contentList = () => ({
    [UsersGroupsContext.Users]: renderUserLicenses(renderContextSwitcher()),
    [UsersGroupsContext.Groups]: renderGroupLicenses(renderContextSwitcher()),
  });

  return contentList()[context];
};

export default PeopleContextWrapper;
