import { FC, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { first } from "lodash";
import moment from "moment";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { WizardNew as Wizard } from "../../../../../components/wizard";
import { useWizardStepsManager } from "../../../../../hooks/useWizardStepsManager";
import Section from "../../../../../components/wizard/Section";
import ScheduleTypes from "../../../../../enums/scheduleTypes";
import { Schedule } from "./Schedule";
import People from "./People";
import { Confirmation } from "./Confirmation";
import { connect, ConnectedProps } from "react-redux";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { createEmailSend } from "../state/thunks/emailSendsThunk";
import { resetCreation } from "../state/slices/emailCreateSendSlice";
import { resetContentPacksAction } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import PreventTransitionPrompt from "../../../../../components/preventTransition/PreventTransitionPrompt";
import { clearEmail, getEmail } from "../state/actions/emailDetailsActions";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import AccessRestrictedMessage from "../../../../../components/restrictedRoute/AccessRestrictedMessage";
import userPermissionActions from "../../../PeopleAssignments/state/actions/userPermissionActions";
import { bindAction } from "../../../../../interfaces";
import Sections from "../../../../../enums/sections";
import groupPermissionActions from "../../../PeopleAssignments/state/actions/groupPermissionActions";
import { useNavigate, useParams } from "react-router-dom";
import { shouldShowModalForCommunications } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import { Dimmer, Loader } from "semantic-ui-react";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import SenderInformation from "./SenderInformation";
import accountsDataService from "features/Accounts/services/accountsDataService";
import { ScheduleConfiguration } from "./types";
import CommunicationTypes from "../../../../../enums/communicationTypes";

const SEND_SESSION_CREATION_EXIT_MODAL_TITLE = "Exit Without Saving?";
const SEND_SESSION_CREATION_EXIT_MODAL_MESSAGE =
  "You have unsaved changes. Are you sure you want to exit? All information entered will be lost.";

export enum CreateEmailSendSessionSteps {
  "People",
  "Schedule",
  "Confirmation",
}

export enum EmailSendSessionPeopleType {
  user = "user",
  group = "group",
}

export type EmailSendSession = {
  sessionType: ScheduleTypes;
  sendDateTime: string;
  peopleIds: PeopleId[];
  packId?: number;
  senderName: string | undefined;
  senderEmail: string | undefined;
  timeZone: string | null;
};

export type PeopleId = {
  id: number;
  peopleType: EmailSendSessionPeopleType;
};

export type SmtpSenderInfo = {
  smtpSettingsExist: boolean;
  sender: {
    id: number;
    senderName: string;
    senderEmail: string;
  };
};

export type CreateEmailSendSessionsAll = PropsFromRedux;

const goToStartPage = (navigate: ReturnType<typeof useNavigate>, emailId: string | undefined) => {
  navigate(`/content/communications/emails/${emailId}`, { replace: true });
};

const renderHeaderStub = () => <></>;

export const CreateEmailSendSessionComponent: FC<CreateEmailSendSessionsAll> = (props) => {
  const {
    emailTitle,
    packContextItems,
    isLoading,
    isShouldShowModalLoading,
    getEmailDetails,
    clearEmailDetails,
    cleanAssignmentModalState,
    createSendSession,
    resetContentPacks,
    shouldShowLicensingModal,
    resetLicensingModal,
    resetSendCreation,
    created,
    selectedUserIds,
    selectedGroupIds,
  } = props;

  const params = useParams();
  const navigate = useNavigate();
  const cancel = () => goToStartPage(navigate, params.id);
  const [scheduleType, setScheduleType] = useState(ScheduleTypes.SendNow);
  const [scheduleDateString, setScheduleDateString] = useState(dateTimeUtils.formatCurrentDate("MM/DD/YYYY"));
  const [scheduleTimeString, setScheduleTimeString] = useState(
    dateTimeUtils.dateToTimeString(dateTimeUtils.roundTime(new Date(), 5), "hh:mm A"),
  );
  const [isSenderInformationValid, setIsSenderInformationValid] = useState<boolean>(true);
  const [senderName, setSenderName] = useState<string | undefined>();
  const [senderEmail, setSenderEmail] = useState<string | undefined>();
  const [customizeSender, setCustomizeSender] = useState<boolean>(false);
  const [smtpSenderInfo, setSmtpSenderInfo] = useState<SmtpSenderInfo>();
  const smtpSettingsFeature = useFeatureFlag(FeatureFlags.SmtpDirectSend);
  const communicationTimeZoneFeature = useFeatureFlag(FeatureFlags.CommunicationTimezone);
  const [scheduleTimeZoneString, setScheduleTimeZoneString] = useState(
    communicationTimeZoneFeature ? props.timeZone ?? "UTC" : null,
  );

  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager([
    CreateEmailSendSessionSteps.People,
    CreateEmailSendSessionSteps.Schedule,
    CreateEmailSendSessionSteps.Confirmation,
  ]);

  useEffect(() => {
    getEmailDetails(Number(params.id));
    setDefaultSenderSettings(getAccountId());
    return () => {
      clearEmailDetails();
      resetSendCreation();
      cleanAssignmentModalState();
      resetLicensingModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (created) {
      goToStartPage(navigate, params.id);
    }
  }, [created, navigate, params.id]);

  const setDefaultSenderSettings = async (accountId: number): Promise<void> => {
    const smtpSenderSettings = (await accountsDataService.getSmtpSenderSettings(accountId)) as SmtpSenderInfo;
    setSmtpSenderInfo(smtpSenderSettings);
    setCustomizeSender(false);
    if (smtpSenderSettings?.smtpSettingsExist) {
      setSenderName(smtpSenderSettings.sender.senderName);
      setSenderEmail(smtpSenderSettings.sender.senderEmail);
    }
  };

  const scheduleConfiguration: ScheduleConfiguration = useMemo(() => {
    return { type: scheduleType, date: scheduleDateString, time: scheduleTimeString, timeZone: scheduleTimeZoneString };
  }, [scheduleType, scheduleDateString, scheduleTimeString, scheduleTimeZoneString]);

  const scheduledAsDate = useMemo(() => {
    const selectedDateTimeString = moment(`${scheduleDateString} ${scheduleTimeString}`).format("YYYY-MM-DD HH:mm:ss");
    if (scheduleTimeZoneString && scheduleType !== ScheduleTypes.SendNow) {
      return moment.tz(selectedDateTimeString, scheduleTimeZoneString).toDate();
    }

    return new Date(`${scheduleDateString} ${scheduleTimeString}`);
  }, [scheduleType, scheduleDateString, scheduleTimeString, scheduleTimeZoneString]);

  const getAccountId = (): number => {
    const { accountId } = props;
    const { moboId } = params;
    return moboId ? parseInt(moboId) : accountId;
  };

  const onChangeSchedule = (
    newScheduleType: ScheduleTypes,
    newDateString: string,
    newTimeString: string,
    newTimeZoneString: string | null,
  ) => {
    setScheduleType(newScheduleType);
    setScheduleDateString(newDateString);
    setScheduleTimeString(newTimeString);
    setScheduleTimeZoneString(newTimeZoneString);
  };

  const create = () => {
    const packId = first(packContextItems.map((p) => p.packId));
    const config = {
      sessionType: scheduleType,
      peopleIds: selectedGroupIds
        .map((x) => ({ id: x, peopleType: EmailSendSessionPeopleType.group }))
        .concat(selectedUserIds.map((x) => ({ id: x, peopleType: EmailSendSessionPeopleType.user }))),
      sendDateTime: scheduledAsDate.toISOString(),
      packId: packId,
      senderName: isSenderInformationValid && customizeSender ? senderName : undefined,
      senderEmail: isSenderInformationValid && customizeSender ? senderEmail : undefined,
      timeZone: scheduleType === ScheduleTypes.SendOn ? scheduleTimeZoneString : null,
    };

    createSendSession(Number.parseInt(params.id!, 10), config);
    resetContentPacks();
  };

  const isValid = scheduleType === ScheduleTypes.SendNow || scheduledAsDate > new Date();

  return (
    <Restricted
      permissions={[RolePermissions.UsersView]}
      renderContent={(hasPermissions) => (
        <>
          <Wizard
            className="create-send-session"
            title="Send Emails"
            finishButtonLabel={scheduleType === ScheduleTypes.SendNow ? "Send Now" : "Schedule"}
            onCancel={cancel}
            onFinish={onFinish}
            onProgress={onNext}
            onRegress={onPrevious}
            isSaveInProgress={false}
            renderCustomHeader={renderHeaderStub}
          >
            <Section
              label="People"
              className="no-padding"
              required
              isLocked={!hasPermissions || (selectedUserIds.length === 0 && selectedGroupIds.length === 0)}
            >
              <Restricted
                permissions={[]}
                placeholder={<AccessRestrictedMessage />}
                permissionPredicate={() => hasPermissions}
              >
                <People />
              </Restricted>
            </Section>
            <Section
              label="Schedule"
              className="scrollable-content"
              isLocked={!hasPermissions || !isSenderInformationValid}
              required
            >
              {smtpSettingsFeature && smtpSenderInfo?.smtpSettingsExist && (
                <>
                  <SenderInformation
                    senderName={senderName}
                    senderEmail={senderEmail}
                    defaultSenderName={smtpSenderInfo?.sender.senderName}
                    defaultSenderEmail={smtpSenderInfo?.sender.senderEmail}
                    customizeSender={customizeSender}
                    onIsValidChange={setIsSenderInformationValid}
                    onSenderNameChange={setSenderName}
                    onSenderEmailChange={setSenderEmail}
                    onCustomizeSenderChange={setCustomizeSender}
                  />
                </>
              )}

              <Schedule
                communicationType={CommunicationTypes.Email}
                useTimeZone={communicationTimeZoneFeature}
                configuration={scheduleConfiguration}
                onScheduleChange={onChangeSchedule}
              />
              {
                /* istanbul ignore next */
                !isValid && <div>Specify date in the future.</div>
              }
            </Section>
            <Section
              label="Confirmation"
              className="scrollable-content"
              isLocked={!hasPermissions || !isSenderInformationValid}
            >
              <Confirmation
                schedule={scheduleType === ScheduleTypes.SendNow ? dateTimeUtils.getCurrentDate() : scheduledAsDate}
                scheduleTimeZone={scheduleType === ScheduleTypes.SendNow ? null : scheduleTimeZoneString}
                communicationId={params.id!}
                communicationTitle={emailTitle}
                communicationType={CommunicationTypes.Email}
                selectedGroupIds={selectedGroupIds}
                selectedUserIds={selectedUserIds}
                onConfirm={create}
                onCancel={resetContentPacks}
                shouldShowLicensingModal={shouldShowLicensingModal}
                promptText={
                  scheduleType === ScheduleTypes.SendNow
                    ? "Clicking Send Now below will send all emails and cannot be undone."
                    : "Clicking Schedule below will set emails to send on the scheduled date and time and cannot be undone."
                }
                acceptHandlers={wizardPages[CreateEmailSendSessionSteps.Confirmation]}
              />
            </Section>
          </Wizard>
          <PreventTransitionPrompt
            when={!created}
            title={SEND_SESSION_CREATION_EXIT_MODAL_TITLE}
            message={SEND_SESSION_CREATION_EXIT_MODAL_MESSAGE}
          />
          <Dimmer active={isLoading || isShouldShowModalLoading} inverted>
            <Loader />
          </Dimmer>
        </>
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  emailTitle: state.library.emails.emailDetailsReducer.email.title,
  created: state.library.emails.emailSendSession.create.created,
  packContextItems: state.licensing.contentPacks.items,
  selectedUserIds: state.library.emails.assignedUsers.userIds,
  selectedGroupIds: state.library.emails.assignedGroups.groupIds,
  isLoading: state.library.emails.emailSendSession.create.isLoading,
  isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  accountId: state.userProfile.accountId,
  timeZone: state.userProfile.timeZone,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createSendSession: bindAction(createEmailSend, dispatch),
  resetContentPacks: () => dispatch(resetContentPacksAction()),
  resetSendCreation: bindAction(resetCreation, dispatch),
  getEmailDetails: (id: number) => dispatch(getEmail(id)),
  clearEmailDetails: bindAction(clearEmail, dispatch),
  cleanAssignmentModalState: () => {
    bindActionCreators(userPermissionActions(Sections.emails), dispatch).resetUsersAssignment();
    bindActionCreators(groupPermissionActions(Sections.emails), dispatch).resetGroupsAssignment();
  },
  shouldShowLicensingModal: bindAction(shouldShowModalForCommunications, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const CreateEmailSendSession = connector(CreateEmailSendSessionComponent);
