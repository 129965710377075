import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { GroupTemplatePacks } from "../Details/Packs/GroupTemplatePacks";
import { SortingDirection } from "enums";
import { getPacks } from "../state/thunks/TemplatePacksThunk";
import { bindAction } from "interfaces";
import { noop } from "lodash";

type Props = PropsFromRedux & {
  templateId: number;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  onRemovePackClick: (id: number) => void;
};

export const EditTemplatePacks = (props: Props) => {
  const { templateId, items, itemsCount, isLoading, getData, selectedIds, setSelectedIds, onRemovePackClick } = props;
  const loadPacks = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    getData(templateId, skip, top, sortingColumnName, sortingDirection);
  };

  return (
    <GroupTemplatePacks
      items={items}
      itemsCount={itemsCount}
      isLoading={isLoading}
      getGridData={noop}
      getListData={loadPacks}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onRemovePackClick={onRemovePackClick}
      isCreate={false}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.groupTemplate.packs.items,
    itemsCount: state.people.groupTemplate.packs.itemsCount,
    isLoading: state.people.groupTemplate.packs.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getData: bindAction(getPacks, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditTemplatePacks);
