import { Loader, Dimmer } from "semantic-ui-react";
import styles from "./Root.module.scss";
import cn from "classnames";

export const Root = (props: React.PropsWithChildren<{ isLoading?: boolean }>) => {
  const { isLoading } = props;
  return (
    <Dimmer.Dimmable dimmed={isLoading} className={styles["root"]}>
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <form className={cn(styles["form"], "ui form")}>{props.children}</form>
    </Dimmer.Dimmable>
  );
};
