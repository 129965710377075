import React, { useState } from "react";
import MultiStateDropdown, {
  MultiStateDropdownOption,
} from "components/dropdowns/multiStateDropdown/MultiStateDropdown";
import GroupsOverview from "../Groups/GroupsOverview/GroupsOverview";
import TemplatesOverview from "../GroupTemplate/Overview/TemplatesOverview";
import { faUsers, faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

enum GroupContentTypesEnum {
  Groups = "Groups",
  Templates = "Templates",
}

const contentOptions: MultiStateDropdownOption<GroupContentTypesEnum>[] = [
  {
    label: GroupContentTypesEnum.Groups,
    icon: faUsers,
  },
  {
    label: GroupContentTypesEnum.Templates,
    icon: faBuildingUser,
  },
];

export const GroupsSwitcher = () => {
  const [context, setContext] = useState(GroupContentTypesEnum.Groups);

  const templatesFlag = useFeatureFlag(FeatureFlags.GroupTemplates);

  const switcher = () => {
    return (
      <MultiStateDropdown
        className="groups-segmentation-dropdown"
        options={contentOptions}
        defaultOption={contentOptions.find((option) => option.label === context)!}
        onContextChange={setContext}
        defaultValue={context}
      />
    );
  };

  const components = {
    [GroupContentTypesEnum.Groups]: <GroupsOverview renderContentSwitcher={templatesFlag ? switcher : undefined} />,
    [GroupContentTypesEnum.Templates]: <TemplatesOverview renderContentSwitcher={switcher} />,
  };

  return components[context];
};
