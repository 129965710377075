import * as Yup from "yup";
import { CUSTOM_EMAIL } from "../emailUtils";

const nameLengths = {
  min: 1,
  max: 128,
};
const portLengths = {
  min: 1,
  max: 65535,
};
const passwordLengths = {
  min: 1,
  max: 64,
};

const validIpAddressRegex = RegExp(/^((\d|\d\d|1\d{2}|2\d\d|25\d)\.){3}(\d|\d\d|1\d{2}|2\d\d|25\d)$/);
const validHostnameRegex = RegExp(
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/,
);
const validPasswordRegex = RegExp(/^(?!\s+$).*/);

const hostMaxLength = 128;
const hostHasIncorrectFormatMessage = `Host has incorrect format`;

const senderName = () => {
  return Yup.string()
    .trim()
    .required("Sender name is required")
    .min(nameLengths.min, `At least one character should be inserted`)
    .max(nameLengths.max, `Sender Name is too long. The maximum allowed length is ${nameLengths.max} characters`);
};

const senderEmail = () => {
  return Yup.string()
    .trim()
    .required("Sender email is required")
    .matches(CUSTOM_EMAIL, "Invalid Email")
    .max(nameLengths.max, `Default Sender Email is too long. Maximum allowed length is ${nameLengths.max} characters`)
    .test("valid-domain", `This email domain is not allowed`, (emailValue) => {
      const forbiddenDomainsList = [
        "gmail",
        "googlemail",
        "outlook",
        "hotmail",
        "live",
        "msn",
        "yahoo",
        "ymail",
        "rocketmail",
        "icloud",
        "me",
        "aol",
        "aim",
        "mail",
        "email",
        "protonmail",
        "zoho",
        "zoho.eu",
        "gmx",
        "hotmail",
      ].join("|");
      return !String(emailValue).toLowerCase().match(`(${forbiddenDomainsList})(\\.com)`);
    });
};

const host = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Host is required")
    .max(hostMaxLength, `Host is too long. Maximum allowed length is ${128} characters`)
    .test("valid-host", hostHasIncorrectFormatMessage, (hostValue) => {
      const hostString = String(hostValue);
      const withoutWhiteSpaces = RegExp(/^\S+$/).test(hostString);
      return (validHostnameRegex.test(hostString) || validIpAddressRegex.test(hostString)) && withoutWhiteSpaces;
    });

const userName = (required: boolean) => {
  const field = Yup.string()
    .trim()
    .min(nameLengths.min, `Username must have at least ${nameLengths.min} characters`)
    .max(nameLengths.max, `Username is too long. The maximum allowed length is ${nameLengths.max} characters`);

  if (required) {
    return field.required("UserName is required");
  } else {
    return field;
  }
};

const port = (): Yup.NumberSchema<number | undefined, object> =>
  Yup.number()
    .typeError("Port should be a number")
    .required("Port is required")
    .min(portLengths.min, `Port should be greater than ${portLengths.min}`)
    .max(portLengths.max, `Port should be less than ${portLengths.max}`);

const password = (required: boolean): Yup.StringSchema<string | undefined, object> => {
  const field = Yup.string()
    .min(passwordLengths.min, `Password must have at least ${passwordLengths.min} characters`)
    .max(passwordLengths.max, `Password must be maximum of ${passwordLengths.max} characters long`)
    .test("valid-password", "Password should not contain only whitespaces", (value) =>
      validPasswordRegex.test(String(value)),
    );
  if (required) {
    return field.required("Password is required");
  } else {
    return field;
  }
};

const verifyEmail = () => {
  return Yup.string()
    .trim()
    .required("Email is required")
    .matches(CUSTOM_EMAIL, "Invalid Email")
    .max(nameLengths.max, `Email is too long. Maximum allowed length is ${nameLengths.max} characters`);
};

export const getEmailSettingsSchema = (isEnabled: boolean, credentialsRequired: boolean) => {
  return Yup.object({
    id: Yup.number(),
    senderName: !isEnabled ? Yup.string() : senderName(),
    senderEmail: !isEnabled ? Yup.string() : senderEmail(),
    host: !isEnabled ? Yup.string() : host(),
    port: !isEnabled ? Yup.number() : port(),
    userName: !isEnabled ? Yup.string() : userName(credentialsRequired),
    password: !isEnabled ? Yup.string() : password(credentialsRequired),
    isSsl: Yup.boolean(),
    replyToEmail: Yup.string(),
  });
};

export const getEmailSettingsSenderInfoSchema = () => {
  return Yup.object({
    senderName: senderName(),
    senderEmail: senderEmail(),
  });
};

export const getVerifyEmailSchema = () => {
  return Yup.object({
    receiverValidationEmail: verifyEmail(),
  });
};
