import { FC, useCallback, useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { first } from "lodash";
import moment from "moment";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { WizardNew as Wizard } from "../../../../../components/wizard";
import { useWizardStepsManager } from "../../../../../hooks/useWizardStepsManager";
import Section from "../../../../../components/wizard/Section";
import ScheduleTypes from "../../../../../enums/scheduleTypes";
import { Schedule } from "../../Emails/SendSessions/Schedule";
import People from "./People";
import { Confirmation } from "../../Emails/SendSessions/Confirmation";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { createMessageSend } from "../state/thunks/messageSendsThunk";
import { resetCreation } from "../state/slices/messageCreateSendSlice";
import PreventTransitionPrompt from "../../../../../components/preventTransition/PreventTransitionPrompt";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import AccessRestrictedMessage from "../../../../../components/restrictedRoute/AccessRestrictedMessage";
import userPermissionActions from "../../../PeopleAssignments/state/actions/userPermissionActions";
import { bindAction } from "../../../../../interfaces";
import Sections from "../../../../../enums/sections";
import groupPermissionActions from "../../../PeopleAssignments/state/actions/groupPermissionActions";
import { getMessage, clearMessage } from "../state/actions/messageDetailsActions";
import { useScheduleStep } from "./useScheduleStep";
import { resetContentPacksAction } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { shouldShowModalForCommunications } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import { Dimmer, Loader } from "semantic-ui-react";
import { ScheduleConfiguration } from "../../Emails/SendSessions/types";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import { FeatureFlags } from "../../../../../featureFlags";
import CommunicationTypes from "../../../../../enums/communicationTypes";

const SEND_SESSION_CREATION_EXIT_MODAL_TITLE = "Exit Without Saving?";
const SEND_SESSION_CREATION_EXIT_MODAL_MESSAGE =
  "You have unsaved changes. Are you sure you want to exit? All information entered will be lost.";

export enum CreateMessageSendSessionSteps {
  "People",
  "Schedule",
  "Confirmation",
}

export enum MessageSendSessionPeopleType {
  user = "user",
  group = "group",
}

export type PeopleId = {
  id: number;
  peopleType: MessageSendSessionPeopleType;
};

export type CreateMessageSendSessionsAll = PropsFromRedux;

const goToEditPage = (navigate: ReturnType<typeof useNavigate>, messageId: number) => {
  navigate(`/content/communications/messages/${messageId}`, { replace: true });
};

const renderHeaderStub = () => <></>;

export const CreateMessageSendSessionComponent: FC<CreateMessageSendSessionsAll> = (props) => {
  const {
    messageTitle,
    isLoading,
    isShouldShowModalLoading,
    getMessageDetails,
    clearMessageDetails,
    cleanAssignmentModalState,
    createSendSession,
    resetSendCreation,
    resetContentPacks,
    packContextItems,
    created,
    selectedUserIds,
    selectedGroupIds,
    shouldShowLicensingModal,
    resetLicensingModal,
    timeZone,
  } = props;

  const navigate = useNavigate();
  const params = useParams();
  const returnToEdit = useCallback(() => goToEditPage(navigate, Number(params.id)), [navigate, params.id]);
  const schedule = useScheduleStep(timeZone);
  const communicationTimeZoneFeature = useFeatureFlag(FeatureFlags.CommunicationTimezone);

  const scheduleConfiguration: ScheduleConfiguration = useMemo(() => {
    return { type: schedule.type, date: schedule.date, time: schedule.time, timeZone: schedule.timeZone };
  }, [schedule.type, schedule.date, schedule.time, schedule.timeZone]);

  const scheduledAsDate = useMemo(() => {
    const selectedDateTimeString = moment(`${schedule.date} ${schedule.time}`).format("YYYY-MM-DD HH:mm:ss");
    if (schedule.timeZone && schedule.type !== ScheduleTypes.SendNow) {
      return moment.tz(selectedDateTimeString, schedule.timeZone).toDate();
    }

    return new Date(`${schedule.date} ${schedule.time}`);
  }, [schedule.type, schedule.date, schedule.time, schedule.timeZone]);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager([
    CreateMessageSendSessionSteps.People,
    CreateMessageSendSessionSteps.Schedule,
    CreateMessageSendSessionSteps.Confirmation,
  ]);
  /* eslint-enable @typescript-eslint/no-unused-vars */

  useEffect(() => {
    getMessageDetails(Number(params.id));

    return () => {
      clearMessageDetails();
      resetSendCreation();
      cleanAssignmentModalState();
      resetLicensingModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (created) {
      goToEditPage(navigate, Number(params.id));
    }
  }, [created, navigate, params.id]);

  const isValid = schedule.type === ScheduleTypes.SendNow || scheduledAsDate > new Date();

  const handleCreateSendSession = () => {
    const packId = first(packContextItems.map((p) => p.packId));
    const config = {
      sessionType: schedule.type,
      peopleIds: selectedGroupIds
        .map((x) => ({ id: x, peopleType: MessageSendSessionPeopleType.group }))
        .concat(selectedUserIds.map((x) => ({ id: x, peopleType: MessageSendSessionPeopleType.user }))),
      sendDateTime: scheduledAsDate.toISOString(),
      packId: packId,
      timeZone: schedule.type === ScheduleTypes.SendOn ? schedule.timeZone : null,
    };
    createSendSession(Number.parseInt(params.id!, 10), config);
    resetContentPacks();
  };

  return (
    <Restricted
      permissions={[RolePermissions.UsersView]}
      renderContent={(hasPermissions) => (
        <>
          <Wizard
            className="create-send-session"
            title="Send Messages"
            finishButtonLabel={schedule.type === ScheduleTypes.SendNow ? "Send Now" : "Schedule"}
            onCancel={returnToEdit}
            onFinish={onFinish}
            onProgress={onNext}
            onRegress={onPrevious}
            isSaveInProgress={false}
            renderCustomHeader={renderHeaderStub}
          >
            <Section
              label="People"
              className="no-padding"
              required
              isLocked={!hasPermissions || (selectedUserIds.length === 0 && selectedGroupIds.length === 0)}
            >
              <Restricted
                permissions={[]}
                placeholder={<AccessRestrictedMessage />}
                permissionPredicate={() => hasPermissions}
              >
                <People />
              </Restricted>
            </Section>
            <Section label="Schedule" className="scrollable-content" isLocked={!hasPermissions}>
              <Schedule
                communicationType={CommunicationTypes.Message}
                onScheduleChange={schedule.onChange}
                useTimeZone={communicationTimeZoneFeature}
                configuration={scheduleConfiguration}
              />
              {
                /* istanbul ignore next */
                !isValid && <div>Specify date in the future.</div>
              }
            </Section>
            <Section label="Confirmation" className="scrollable-content" isLocked={!hasPermissions} required>
              <Confirmation
                schedule={schedule.type === ScheduleTypes.SendNow ? dateTimeUtils.getCurrentDate() : scheduledAsDate}
                scheduleTimeZone={schedule.type === ScheduleTypes.SendNow ? null : schedule.timeZone}
                communicationId={params.id!}
                communicationTitle={messageTitle}
                communicationType={CommunicationTypes.Message}
                selectedGroupIds={selectedGroupIds}
                selectedUserIds={selectedUserIds}
                promptText={
                  schedule.type === ScheduleTypes.SendNow
                    ? "Clicking Send Now below will send all messages and cannot be undone."
                    : "Clicking Schedule below will set messages to send on the scheduled date and time and cannot be undone."
                }
                acceptHandlers={wizardPages[CreateMessageSendSessionSteps.Confirmation]}
                onCancel={resetContentPacks}
                onConfirm={handleCreateSendSession}
                shouldShowLicensingModal={shouldShowLicensingModal}
              />
            </Section>
          </Wizard>
          <PreventTransitionPrompt
            when={!created}
            title={SEND_SESSION_CREATION_EXIT_MODAL_TITLE}
            message={SEND_SESSION_CREATION_EXIT_MODAL_MESSAGE}
          />
          <Dimmer active={isLoading || isShouldShowModalLoading} inverted>
            <Loader />
          </Dimmer>
        </>
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  messageTitle: state.library.messages.messageDetails.message.title,
  created: state.library.messages.sendSession.create.created,
  isLoading: state.library.messages.sendSession.create.isLoading,
  packContextItems: state.licensing.contentPacks.items,
  selectedUserIds: state.library.messages.assignedUsers.userIds,
  selectedGroupIds: state.library.messages.assignedGroups.groupIds,
  isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  timeZone: state.userProfile.timeZone,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createSendSession: bindAction(createMessageSend, dispatch),
  resetSendCreation: bindAction(resetCreation, dispatch),
  resetContentPacks: () => dispatch(resetContentPacksAction()),
  getMessageDetails: (id: number) => dispatch(getMessage(id)),
  clearMessageDetails: bindAction(clearMessage, dispatch),
  cleanAssignmentModalState: () => {
    bindActionCreators(userPermissionActions(Sections.messages), dispatch).resetUsersAssignment();
    bindActionCreators(groupPermissionActions(Sections.messages), dispatch).resetGroupsAssignment();
  },
  shouldShowLicensingModal: bindAction(shouldShowModalForCommunications, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const CreateMessageSendSession = connector(CreateMessageSendSessionComponent);
