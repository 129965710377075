import React, { Dispatch, SetStateAction, SyntheticEvent, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { intersection, isEmpty } from "lodash";
import { DropdownProps, Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { ViewType, SortingDirection, RolePermissions, ItemsTypes, SortOptions } from "../../../../../enums";
import {
  NoResults,
  SearchInput,
  NoSearchResults,
  RestrictedByTooltip,
  AccountPackCard,
  PackTitleCell,
  RemovePackFromAccountConfirmationModal,
  ArchivePackConfirmationDialog,
} from "../../../../../components";
import ItemsView from "../../../../../views/ItemsView/ItemsView";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { AccountPack } from "../../../types";
import { gridSortingOptions } from "./sortOptions";
import columnOptions, { columnsMap } from "./columnOptions";
import {
  AddPacksLicensesToAccountSuccess,
  UpdatePackAccountLicenseSuccess,
  RemovePackFromAccountSuccess,
  PackArchiveSuccess,
  AddAllPacksToOwnAccountSuccess,
} from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import * as notificationsActions from "../../../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { PackFilter } from "../types/models";
import { SearchState } from "../../../../Application/slices/createSearchSlice";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { CardsViewerItem } from "../../../../../components/cardsViewer/types";
import accountsDataService from "../../../../Accounts/services/accountsDataService";
import { AccountPackMenu } from "../../../../Licensing/Packs/shared/AccountPackMenu/AccountPackMenu";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { ExpirationCell } from "./ExpirationCell/ExpirationCell";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import packsDataService from "../../../../Licensing/Packs/services/packsDataService";

import "./accountPacksList.scss";

export interface AccountPacksListProps {
  canAddPack: boolean;
  isReadOnly: boolean;
  loadListItems: (skip: number, top: number, order: string, appliedFilter: any, searchTerm?: string) => void;
  resetListItems: () => void;
  loadGridItems: (skip: number, order: string, appliedFilter: any, searchTerm?: string) => any;
  resetGridItems: () => void;
  renderAddPacksButton: () => React.ReactElement;
  handleEditLicensingClick: (id: number) => void;
  handleExtendLicensesClick: (id: number, title: string, thumbnailImageUrl: string) => void;
  handleRestorePackClick: (id: number, title: string, thumbnailImageUrl: string) => void;
  filter: PackFilter;
  selectedViewType: ViewType;
  setSelectedViewType: Dispatch<SetStateAction<ViewType>>;
  packsList: {
    items: Array<AccountPack>;
    isLoading: boolean;
    itemsCount: number;
  };
  packsGrid: {
    items: Array<AccountPack>;
    isLoading: boolean;
    itemsCount: number;
    areAllLoaded: boolean;
  };
  searchState?: SearchState;
  setSearch: (term: string) => { payload: string; type: string };
  triggerRefreshPacksGrid: (viewType: ViewType) => void;
  selectedAccountId: number;
  permissionsToAccount: RolePermissions[];
  hasPurchasePower: boolean;
  isAccountHome: boolean;
}

export type AccountPacksListAllProps = AccountPacksListProps & PropsFromRedux;

export function AccountPacksList(props: AccountPacksListAllProps) {
  const [gridOrderBy, setGridOrderBy] = React.useState(SortOptions.Title);
  const [gridDataRequest, setGridDataRequest] = React.useState<any>();
  const navigate = useNavigate();
  const reloadListItemsRef = useRef<() => void>();

  const defaultSortingColumnName = "Date Added";
  const packsRefreshRtnEvents = [
    AddPacksLicensesToAccountSuccess,
    UpdatePackAccountLicenseSuccess,
    RemovePackFromAccountSuccess,
    PackArchiveSuccess,
    AddAllPacksToOwnAccountSuccess,
  ];
  const {
    resetListItems,
    resetGridItems,
    selectedViewType,
    setSelectedViewType,
    searchState,
    setSearch,
    triggerRefreshPacksGrid,
    accountId,
    isAccountHome,
    selectedAccountId,
    hasPurchasePower,
  } = props;

  const [triggerOpenRemovePackDialog, setTriggerOpenRemovePackDialog] = React.useState(false);
  const [triggerArchivePackDialog, setTriggerArchivePackDialog] = React.useState(false);
  const [selectedPackId, setSelectedPackId] = React.useState(-1);
  const hasAccountManageRole =
    intersection(props.permissionsToAccount, [RolePermissions.AccountsSettingsManage]).length > 0;
  useEffect(() => {
    if (selectedViewType === ViewType.GRID) {
      getPacksGridItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewType, searchState, gridOrderBy]);

  const onSetReloadListItems = (callback: () => void) => {
    reloadListItemsRef.current = callback;
  };

  useEffect(() => {
    RtnEventsEmitter.subscribe(packsRefreshRtnEvents, refreshPacksGrid);

    return () => {
      resetListItems();
      resetGridItems();
      setSearch("");
      RtnEventsEmitter.unsubscribe(packsRefreshRtnEvents, refreshPacksGrid);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshPacksGrid = () => {
    triggerRefreshPacksGrid(ViewType.GRID);
  };

  const buildTableBody = (pack: AccountPack) => {
    const isOwnPack = pack.isOwn;
    const expirationDate = isOwnPack ? "Never" : dateTimeUtils.formatDate(pack.expirationDate);
    const readOnlyClass = cn({
      rowDisabled: pack.isArchived,
    });
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
          <PackTitleCell pack={pack} handleTitleClick={() => handleTitleClick(pack.id)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
          {isOwnPack ? "Unlimited" : pack.licensesCount}
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
          {dateTimeUtils.formatDate(pack.issuedDate)}
        </Table.Cell>
        <ExpirationCell isExpired={pack.isExpired} date={expirationDate} className={readOnlyClass} />
        <Table.Cell className="align-right-packs" width={columnOptions[4].width}>
          <RestrictedByTooltip hasPermission={hasAccountManageRole}>
            <AccountPackMenu
              id={pack.id}
              title={pack.title}
              thumbnailImageUrl={pack.thumbnailImageUrl}
              isExpired={pack.isExpired}
              isTrial={pack.isTrial}
              isArchived={pack.isArchived || false}
              onEditLicensing={props.handleEditLicensingClick}
              onExtendLicenses={props.handleExtendLicensesClick}
              onRemovePackFromAccount={handleRemovePackFromAccountClick}
              isAllowedToRemovePackFromAccount={isAllowedToRemovePackFromAccount}
              hasAccountManageRole={hasAccountManageRole}
              hasPurchasePower={hasPurchasePower}
              isAccountHome={isAccountHome}
              onArchivePack={pack.isArchived ? undefined : handleArchivePack}
              onRestorePack={pack.isArchived ? props.handleRestorePackClick : undefined}
              isOwnPack={isOwnPack}
            />
          </RestrictedByTooltip>
        </Table.Cell>
      </React.Fragment>
    );
  };

  const viewState = selectedViewType === ViewType.GRID ? props.packsGrid : props.packsList;

  const handleTitleClick = (id: number) => {
    if (props.isAccountHome) {
      navigate(`/licensing/packs/purchased/${id}`);
    } else {
      navigate(`/licensing/packs/listings/${id}`);
    }
  };

  const getPacksListItems = (
    skip?: number,
    top?: number,
    sortingColumnName = defaultSortingColumnName,
    sortingDirection = SortingDirection.Descending,
  ) => {
    const orderParams = `${columnsMap[sortingColumnName]} ${sortingDirection}`;
    props.loadListItems(skip ?? 0, top ?? 10, orderParams, {}, searchState?.term);
  };

  const getPacksGridItems = () => {
    gridDataRequest && gridDataRequest.abort();
    const loadedPacksCount = props.packsGrid.items.length;
    const request = props.loadGridItems(loadedPacksCount, gridOrderBy, {}, searchState?.term);
    setGridDataRequest(request);
  };

  const isFiltered = !isEmpty(searchState?.term);

  const noResultsContent = () =>
    isFiltered ? (
      <NoSearchResults />
    ) : (
      <NoResults
        title="No packs for this account"
        description={
          <span>
            Looks like this account doesn't have any packs yet.
            <br />
            {props.canAddPack && "Add some now using the button below."}
          </span>
        }
        iconClassName="fa-box-full"
      >
        {props.canAddPack && props.renderAddPacksButton()}
      </NoResults>
    );

  const onSortChange = (_ev: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    resetPacks();
    setGridOrderBy(data.value as SortOptions);
  };

  const onContinuePackRemove = () => {
    return (async () => {
      try {
        setTriggerOpenRemovePackDialog(false);
        await accountsDataService.removePackFromAccount(selectedAccountId, selectedPackId);
      } catch (error) {}
    })();
  };

  const handleRemovePackFromAccountClick = async (packId: number) => {
    setSelectedPackId(packId);
    setTriggerOpenRemovePackDialog(true);
  };

  const onCancelPackRemove = () => {
    setSelectedPackId(-1);
    setTriggerOpenRemovePackDialog(false);
  };

  const isAllowedToRemovePackFromAccount = () => {
    return hasAccountManageRole;
  };

  const onCancelArchivePack = () => {
    setSelectedPackId(-1);
    setTriggerArchivePackDialog(false);
  };

  const onContinueArchivePack = async () => {
    setTriggerArchivePackDialog(false);
    const params = {
      id: "ArchivePack",
      title: "Archive Pack",
      indeterminate: true,
      getOperationProps: async () => {
        const packAccountId = isAccountHome ? accountId : selectedAccountId;
        const { data } = await packsDataService.archivePack(packAccountId, selectedPackId);
        return data;
      },
      successTransientMessage: "Pack was archived.",
      failureTransientMessage: "Pack archive failed!",
    };

    const {
      backgroundTasksActions: { addOperationDistributedOp },
      notificationsActions: { sendTransientNotification },
    } = props;

    await backgroundTask.updateEntityDistributedOp(params, {
      addOperation: addOperationDistributedOp,
      sendTransientNotification,
    });
  };

  const handleArchivePack = (packId: number) => {
    setTriggerArchivePackDialog(true);
    setSelectedPackId(packId);
  };

  const cardButtonHandlers = {
    onTitleClick: handleTitleClick,
    onEditLicensing: props.handleEditLicensingClick,
    onExtendLicenses: props.handleExtendLicensesClick,
    onRemovePackFromAccount: handleRemovePackFromAccountClick,
    isAllowedToRemovePackFromAccount: isAllowedToRemovePackFromAccount,
    onArchivePack: handleArchivePack,
    onRestorePack: props.handleRestorePackClick,
  };

  const resetPacks = async () => {
    const mapResetPacks = {
      [ViewType.GRID]: resetGridItems,
      [ViewType.LIST]: resetListItems,
    };
    return mapResetPacks[selectedViewType]();
  };

  const onViewTypeChange = (viewType: ViewType) => {
    resetPacks();
    setSelectedViewType(viewType);
  };

  const onSearchChange = (search: string) => {
    resetPacks();
    setSearch(search);
    reloadListItemsRef.current?.();
  };

  const renderCard = (pr: CardsViewerItem<AccountPack>) => {
    return (
      <AccountPackCard
        hasPurchasePower={props.hasPurchasePower}
        isAccountHome={props.isAccountHome}
        {...pr}
        {...cardButtonHandlers}
      />
    );
  };

  return (
    <>
      <ItemsView
        className="account-packs-list"
        viewType={selectedViewType}
        renderCard={renderCard}
        columnOptions={columnOptions}
        getData={selectedViewType === ViewType.GRID ? getPacksGridItems : getPacksListItems}
        itemsInlineCount={viewState.itemsCount}
        isLoading={viewState.isLoading}
        items={viewState.items}
        isAllDataLoaded={props.packsGrid.areAllLoaded}
        itemsType={ItemsTypes.Pack}
        buildTableBody={(pack: AccountPack) => buildTableBody(pack)}
        sortingColumnName={defaultSortingColumnName}
        sortingDirection={SortingDirection.Descending}
        noResultsContent={noResultsContent()}
        withSelection={false}
        renderSearch={() => (
          <SearchInput placeholder="Search for packs..." onChange={onSearchChange} defaultValue={searchState?.term} />
        )}
        setReloadListItems={onSetReloadListItems}
        onViewTypeChange={onViewTypeChange}
        sortOptions={gridSortingOptions}
        onSortChange={onSortChange}
        doNotLoadPersistentViewType={true}
        listViewRtnEvents={packsRefreshRtnEvents}
      />
      <RemovePackFromAccountConfirmationModal
        triggerOpen={triggerOpenRemovePackDialog}
        onCancel={onCancelPackRemove}
        onContinue={onContinuePackRemove}
      />
      <ArchivePackConfirmationDialog
        triggerOpen={triggerArchivePackDialog}
        onCancel={onCancelArchivePack}
        onContinue={onContinueArchivePack}
      />
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AccountPacksList);
