import { AppDispatch } from "../../../../Application/globaltypes/redux";
import _ from "lodash";

import objUtils from "../../../../../utils/objectUtils";
import PromiseStatuses from "../../../../../enums/promiseStatuses";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import * as contentAssignments from "../../../../People/ContentAssignments/state/slices/contentAssignmentsFiltersSlice";

import { FiltersMap, GenericFiltersMap, mapFilterOption } from "../../../../../utils/filterUtils";
import { batch } from "react-redux";
import { AccountBase, PackName } from "../../../../../interfaces";
import { Tag } from "../../../../../interfaces/Tag";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { TagsEnumCamel, VideoFiltersEnum } from "../../types/models";
import {
  setError as setOverviewError,
  setFilterOptions as setOverviewFilterOptions,
  setIsLoading as setOverviewIsLoading,
} from "../slices/videoFiltersSlice";

export interface GetFilterOptionsProps {
  showPurchased?: boolean;
  filters?: VideoFiltersEnum[];
}

interface BaseFilterOptionsProps {
  begin: () => void;
  success: (filterOptions: FiltersMap) => void;
  failure: (error: Error) => void;
}

const defaultFiltersOptions = [VideoFiltersEnum.Publishers, VideoFiltersEnum.Tags, VideoFiltersEnum.Packs];

export const getFilterOptions = async (
  props: BaseFilterOptionsProps & GetFilterOptionsProps,
  includePublishers: boolean = true,
) => {
  const { showPurchased, begin, success, failure } = props;
  const selectedFilters = !props.filters?.length ? defaultFiltersOptions : props.filters;

  let filterOptions: FiltersMap = {};

  const tagToFilterMap: { [key in TagsEnumCamel]?: VideoFiltersEnum } = {
    [TagsEnumCamel.Label]: VideoFiltersEnum.Labels,
    [TagsEnumCamel.SoftwareApplication]: VideoFiltersEnum.SoftwareApplications,
  };

  const readPublishersData = async () => {
    const publishers = await DataService.assets.getPublishersFilterOptions();
    filterOptions = mapFilterOption(filterOptions, publishers.data, VideoFiltersEnum.Publishers, (item: PackName) => ({
      text: item.name,
      value: item.id,
    }));
  };

  const readPacksData = async () => {
    const packs = await DataService.packs.getPacksFilterOptions("video", !!showPurchased);
    filterOptions = mapFilterOption(filterOptions, packs.data, VideoFiltersEnum.Packs, (item: AccountBase) => ({
      text: item.name,
      value: item.id,
    }));
  };

  const readTagsData = async () => {
    const tags = await DataService.assets.getTagsFilterOptions(!!showPurchased);
    for (const [key, value] of objUtils.typedEntries<TagsEnumCamel, Tag[]>(tags.data)) {
      if (tagToFilterMap[key]) {
        filterOptions = mapFilterOption(filterOptions, value, tagToFilterMap[key]!, (item: Tag) => ({
          text: item.title,
          value: item.title,
        }));
      }
    }
  };

  const filterHandlers: { [key in VideoFiltersEnum]?: () => Promise<void> } = {
    [VideoFiltersEnum.Packs]: readPacksData,
    [VideoFiltersEnum.Tags]: readTagsData,
    ...(includePublishers && { [VideoFiltersEnum.Publishers]: readPublishersData }),
  };

  begin();

  const filtersHandlersPromises = Object.values(_.pick(filterHandlers, selectedFilters)).map(
    (handler: () => Promise<void>) => handler(),
  );

  const result = await Promise.allSettled(filtersHandlersPromises);

  batch(() => {
    let someFulfilled = false;
    result.forEach((item) => {
      ({
        [PromiseStatuses.rejected]: () => failure((item as PromiseRejectedResult).reason),
        [PromiseStatuses.fulfilled]: () => (someFulfilled = true),
      }[item.status]());
    });

    if (someFulfilled) {
      success(filterOptions);
    }
  });
};

const getFilterOptionsWrapper = (
  setIsLoading: ActionCreatorWithPayload<boolean>,
  setFilterOptions: ActionCreatorWithPayload<GenericFiltersMap<string>>,
  setError: ActionCreatorWithPayload<Error>,
) => {
  return (dispatch: AppDispatch) =>
    getFilterOptions({
      showPurchased: true,
      filters: [VideoFiltersEnum.Publishers, VideoFiltersEnum.Tags, VideoFiltersEnum.Packs],
      begin: () => dispatch(setIsLoading(true)),
      success: (filterOptions: FiltersMap) =>
        batch(() => {
          dispatch(setFilterOptions(filterOptions));
          dispatch(setIsLoading(false));
        }),
      failure: (error: Error) =>
        batch(() => {
          dispatch(setError(error));
          dispatch(setIsLoading(false));
        }),
    });
};

export const getContentAssignmentModalFilterOptions = () =>
  getFilterOptionsWrapper(
    contentAssignments.setIsLoading,
    contentAssignments.setFilterOptions,
    contentAssignments.setError,
  );

export const getOverviewFilterOptions = () =>
  getFilterOptionsWrapper(setOverviewIsLoading, setOverviewFilterOptions, setOverviewError);
