import { debounce, isEmpty, isNil } from "lodash";
import React from "react";
import { Label, Loader } from "semantic-ui-react";

import UserSource from "../../../../../enums/userSource";
import enrollmentRulesUtils, { FilterOptionsEnum } from "../../../../../utils/enrollmentRulesUtils";
import {
  NumberRangeFilterValues,
  RangeFilterValues,
  formatFilterParamsMultOperators,
  operators,
} from "../../../../../utils/filterUtils";
import { Filters } from "../../../../../utils/queryUtils";
import userListUtils from "../../../../../utils/userListUtils";
import { GetGroupEnrollmentUsersPreviewRequest, UserInfo } from "../../../types";
import GroupUsersList from "../../AddGroup/GroupUsers/GroupUsersList/GroupUsersList";
import EnrollmentBuilder from "../EnrollmentBuilder/EnrollmentBuilder";

import "./automaticallyDetect.scss";

export const convertEnrollmentRulesToFilter = (enrollmentRules: EnrollmentRule[]) => {
  const enumToColumnNameDictionary = [
    "title",
    "department",
    "createDate",
    "country",
    "officeLocation",
    "hiredDate",
    "manager",
  ];
  const filter: Filters = {};
  enrollmentRules.forEach((x: EnrollmentRule) => {
    if (!Boolean(x.value)) {
      return;
    }
    const columnName = enumToColumnNameDictionary[x.type!];
    const columnFilters = filter[columnName] as Array<any>;
    if (!isNil(x.source) && x.source !== UserSource.None) {
      filter.sourceId = [{ values: [x.source], operator: operators.or }];
    }

    const operator = mapFilterToOperator(x.filter!);

    if (columnFilters) {
      const columnFilter = columnFilters.find((f) => f.operator === operator);

      if (columnFilter) {
        columnFilter.values.push(x.value);
      } else {
        columnFilters.push({ values: [x.value], operator: operator });
      }

      return;
    }

    filter[columnName] = [{ values: [x.value], operator: operator }];
  });

  return filter;
};

const mapFilterToOperator = (filter: number): string | symbol => {
  switch (filter) {
    case FilterOptionsEnum.IsEqualTo:
      return operators.or;
    case FilterOptionsEnum.IsNotEqualTo:
      return operators.notIn;
    case FilterOptionsEnum.IsBetween:
      return operators.isBetween;
    case FilterOptionsEnum.IsNotBetween:
      return operators.isNotBetween;
    case FilterOptionsEnum.IsOnOrAfter:
      return operators.isOnOrAfter;
    case FilterOptionsEnum.IsOnOrBefore:
      return operators.isOnOrBefore;
    case FilterOptionsEnum.IsWithinLastDays:
      return operators.isWithinLastDays;
    default:
      throw new Error(`Group. Automatically detect. Unknown filter code: ${filter}`);
  }
};

const debounceTimeout = 1000;
const defaultCosmosRuleEnginePreviewTop = 50;

export interface EnrollmentRule {
  source?: UserSource | null;
  filter?: number | null;
  type: number | null;
  value: string | Date | RangeFilterValues | null | undefined | NumberRangeFilterValues;
}

export interface AutomaticallyDetectPropTypes {
  values: { enrollmentRules: EnrollmentRule[] };
  validatedFieldProps: any;
  users: UserInfo[];
  usersCount: number;
  isLoading: boolean;
  loadUsers: (skip: number, top: number, orderParams: any, filterParams: any) => void;
  fetchGroupEnrollmentUsers: (request: GetGroupEnrollmentUsersPreviewRequest) => void;
  loadGroupEnrollmentPreview?: (top: number, rules: EnrollmentRule[]) => void;
  gridFilter: Filters;
  setGridFilter: (filter: Filters) => void;
  resetEnrollmentUsers: () => void;
  goToEditUser: () => void;
  onIsValidChange: () => void;
  disabled: boolean;
  deepLink?: boolean;
  rulesEngineOnCosmos?: boolean;
}

export class AutomaticallyDetect extends React.Component<AutomaticallyDetectPropTypes> {
  componentWillUnmount() {
    const { resetEnrollmentUsers } = this.props;
    resetEnrollmentUsers && resetEnrollmentUsers();
  }

  loadPage = (skip: number, top: number, sortingColumnName: any, sortingDirection: any, filter: any) => {
    const {
      validatedFieldProps,
      rulesEngineOnCosmos,
      values: { enrollmentRules },
    } = this.props;

    if (enrollmentRules.some((x: any) => !x.value)) {
      return;
    }

    if (rulesEngineOnCosmos) {
      if (!validatedFieldProps.isFormValid || !enrollmentRulesUtils.validateEnrollmentRules(enrollmentRules)) {
        return;
      }

      const { fetchGroupEnrollmentUsers } = this.props;
      fetchGroupEnrollmentUsers({
        top: defaultCosmosRuleEnginePreviewTop,
        rules: enrollmentRulesUtils.mapRulesToSeverFormat(enrollmentRules),
      });
      return;
    }

    let orderParams = null;
    let filterParams = null;

    if (sortingColumnName) {
      orderParams = userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    }

    if (enrollmentRules.length !== 0 && isEmpty(filter)) {
      filter = convertEnrollmentRulesToFilter(enrollmentRules);
    }

    if (filter) {
      filterParams = formatFilterParamsMultOperators(filter);
    }

    const { loadUsers } = this.props;
    loadUsers(skip, top, orderParams, filterParams);
  };

  debouncedChangeEnrollmentFilter = debounce(() => {
    const {
      values: { enrollmentRules },
      setGridFilter,
    } = this.props;
    setGridFilter(convertEnrollmentRulesToFilter(enrollmentRules));
  }, debounceTimeout);

  handleRuleChange = async (i: number) => {
    const {
      values: { enrollmentRules },
    } = this.props;
    if (enrollmentRules[i].value) {
      await this.debouncedChangeEnrollmentFilter();
    }
  };

  /* istanbul ignore next */
  renderPeopleLabelBadge = () => {
    const { isLoading, usersCount } = this.props;
    return (
      <Label circular color="blue" size="medium">
        {isLoading ? <Loader active size="mini" inline="centered" /> : usersCount}
      </Label>
    );
  };

  render() {
    const { validatedFieldProps, goToEditUser, disabled, onIsValidChange, rulesEngineOnCosmos } = this.props;
    return (
      <section className="automatically-detect">
        <EnrollmentBuilder
          values={this.props.values}
          disabled={disabled}
          onChange={this.debouncedChangeEnrollmentFilter}
          validatedFieldProps={validatedFieldProps}
          handleCriteriaChange={this.handleRuleChange}
          onIsValidChange={onIsValidChange}
        />
        <div className="people-label">
          PEOPLE
          {this.renderPeopleLabelBadge()}
        </div>
        <div className="enrollment-rules-list-container">
          <GroupUsersList
            isGroupCreation={false}
            isAutoEnrollment={true}
            filter={this.props.gridFilter}
            users={this.props.users}
            usersCount={this.props.usersCount}
            withSelection={false}
            goToEditUser={goToEditUser}
            isLoading={this.props.isLoading}
            loadPage={this.loadPage}
            renderHeaderSection={false}
            deepLink={this.props.deepLink}
            rulesEngineOnCosmos={rulesEngineOnCosmos}
          />
        </div>
      </section>
    );
  }
}

export default AutomaticallyDetect;
