import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { GroupTemplateCustomers } from "../../Details/GroupTemplateCustomers";
import { ModalWithStepsStep } from "components/modal/ModalWithSteps";
import { SortingDirection } from "enums";
import { CustomerView } from "../../types/models";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { setCustomersToAdd } from "../../state/TemplateCustomersSlice";

import DataService from "features/Application/services/dataServices/typedDataService";

export interface AddCustomersModalStepProps extends ModalWithStepsStep {
  filter?: object;
  id: number;
}

export type AddCustomersModalStepPropsAll = PropsFromRedux & AddCustomersModalStepProps;

export const AddCustomersModalStep: React.FC<AddCustomersModalStepPropsAll> = ({ setSelectedIds, id }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsState, setState] = useState({
    items: [] as CustomerView[],
    itemsCount: 0,
    isLoading: false,
  });

  const loadCustomers = async (
    skip: number,
    top: number,
    sortBy: string,
    sortOrder: SortingDirection,
    templateId?: number,
  ) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { data } = await DataService.groupTemplates.getAvailableCustomers({
      top,
      skip,
      sortBy,
      sortOrder,
      term: searchTerm,
      templateId,
    });
    setState({ items: data.items, itemsCount: data.count, isLoading: false });
  };

  const onSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
  };

  return (
    <GroupTemplateCustomers
      getData={loadCustomers}
      items={itemsState.items}
      isLoading={itemsState.isLoading}
      itemsCount={itemsState.itemsCount}
      setSelectedIds={setSelectedIds}
      onSearchChange={onSearchChange}
      templateId={id}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSelectedIds: (ids: number[]) => dispatch(setCustomersToAdd(ids)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddCustomersModalStep);
