import { batch } from "react-redux";

import * as events from "../events/library/libraryEvents";
import {
  publishDraftPackSuccess,
  packUpdated,
  updatePackCommandSuccess,
  fetchDiscardPackSuccess,
  fetchLockedPackSuccess,
} from "../../../../Licensing/Packs/state/actions/packEntityStateActions";
import { IMapping } from "./types";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { AlertTypes } from "../../../../../enums";
import { DraftEntityUpdatedPayload } from "../../../../../interfaces/rtnTypes";
import {
  updateFailedTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

const VISIBILITY_RULES_UPDATED_MESSAGE = "Visibility rules have been successfully updated";

export const packsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess, dispatchTaskOperationSuccess, dispatchTaskOperationFailure } =
    createDispatcher(dispatcher);

  const payloadMapping = (data: { executionId: string; id: number }) => ({
    messageId: data.executionId,
    entityId: data.id,
  });

  const dispatchAccountPackVisibilitySuccess = (payload: DraftEntityUpdatedPayload) => {
    batch(() => {
      dispatcher(packUpdated(payload));
      dispatcher(sendTransientNotification({ type: AlertTypes.success, message: VISIBILITY_RULES_UPDATED_MESSAGE }));
    });
  };

  mapping[events.PackEditSuccess] = dispatch(packUpdated);
  mapping[events.PacksCommandCompleted] = dispatch(updatePackCommandSuccess);
  mapping[events.PackDiscardSuccess] = dispatch(fetchDiscardPackSuccess);

  mapping[events.PackPublishSuccess] = dispatchPublishSuccess<{ id: number; title: string }>(
    publishDraftPackSuccess,
    (data) => `${data.title} was published.`,
  );

  mapping[events.PackContentEditSuccess] = dispatch(packUpdated);

  mapping[events.EditPackIsPrivateSuccess] = dispatch(packUpdated);

  mapping[events.PackLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedPackSuccess,
    (message) => ({ entityId: message.id }),
  );

  mapping[events.PackDeleteSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[events.PackDeleteFailure] = dispatchTaskOperationFailure(payloadMapping);

  mapping[events.AddContentToPackSuccess] = dispatchTaskOperationSuccess((payload) => ({
    messageId: payload.messageId,
    entityId: payload.id,
  }));
  mapping[events.AddContentToPackFailure] = dispatchTaskOperationFailure((payload) => ({
    messageId: payload.messageId,
    entityId: payload.id,
  }));

  mapping[events.EditAccountPackVisibilitySuccess] = dispatchAccountPackVisibilitySuccess;
  mapping[events.EditAccountTypePackVisibilitySuccess] = dispatchAccountPackVisibilitySuccess;

  mapping[events.AddPackUserSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddAllPackUserSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllPackUserFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AddPackGroupSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddAllPackGroupSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllPackGroupFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.AddUserLicenseSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddUserLicenseFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllUserLicenseMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllUserLicenseMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.AddGroupLicenseSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddGroupLicenseFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllGroupLicenseMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddAllGroupLicenseMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.RemoveUserLicenseSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.RemoveUserLicenseFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllUserLicenseMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllUserLicenseMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.RemoveGroupLicenseSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.RemoveGroupLicenseFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllGroupLicenseMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllGroupLicenseMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.AddTemplateGroupPackAccountSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.AddTemplateGroupPacksAccountMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.AddTemplateGroupPackAccountFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.AddTemplateGroupPacksAccountMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.RemovePackFromTemplateGroupSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.RemoveAllPackFromTemplateGroupMessageSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.RemovePackFromTemplateGroupFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.RemoveAllPackFromTemplateGroupMessageFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
};
