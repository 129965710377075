import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { reset } from "../state/GroupTemplateSlice";
import { reset as resetCustomers } from "../state/TemplateCustomersSlice";
import { reset as resetPacks } from "../state/TemplatePacksSlice";
import { GenericSectionHeader, RemoveLinkButton, RestrictedResource } from "../../../../components";
import navigationUtils from "../../../../utils/navigationUtils";
import { RouteNames } from "../../../../enums";
import Segments from "../../../../components/navigation/segments/Segments";
import EditConfiguration from "./EditConfiguration";
import EditTemplateEnrollment from "./EditTemplateEnrollment";
import EditTemplateCustomers, { CustomersRef } from "./EditTemplateCustomers";
import { bindAction } from "interfaces";
import { fetchTemplate } from "../state/thunks/TemplateThunk";
import SectionSubHeader from "components/sectionHeader/sectionSubHeader/SectionSubHeader";
import PeopleContent from "../Details/PeopleContent";
import EditTemplatePacks from "./EditTemplatePacks";
import { RemovePacksConfirmationModal } from "./Packs/RemovePacksConfirmationModal";
import { Observable } from "utils";
import { removePacks } from "../state/thunks/TemplatePacksThunk";
import { Button } from "components/buttons/button/Button";
import AddPacksModal from "./Packs/AddPacksModal";

export type Props = PropsFromRedux;

enum Pages {
  Configuration = "configuration",
  Enrollment = "enrollment",
  Packs = "packs",
  Customers = "customers",
  Content = "content",
}

export function EditGroupTemplate(props: Props) {
  const {
    isLoadingGroupInfo,
    groupInfo,
    isAccessAuthorized,
    fetchTemplate,
    resetTemplateState,
    resetCustomersState,
    resetPacksState,
    removePacksFromGroupTemplate,
  } = props;
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();
  const customersRef = useRef<CustomersRef>(null);
  const onTriggerRemovePacksObserver = useMemo(() => new Observable(), []);
  const [selectedPackIds, setSelectedPackIds] = useState<number[]>([]);
  const [showAddPacksModal, setShowAddPacksModal] = useState(false);

  const groupId = useMemo(
    () => parseInt(params.id!.toString()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return () => {
      resetTemplateState();
      resetCustomersState();
      resetPacksState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTemplate(groupId);
  }, [groupId, fetchTemplate]);

  const navigate = useNavigate();

  const renderDropdownButtons = () => {
    if (selectedPackIds.length) {
      return null;
    }
    return [];
  };

  const renderHeaderButtons = () => {
    switch (currentStep) {
      case Pages.Packs:
        if (selectedPackIds.length) {
          return [renderRemovePacksButton()];
        }
        return [renderAddPacksButton()];
      case Pages.Customers:
        return [customersRef.current?.renderAddCustomersButton()];
      default:
        return [];
    }
  };

  const onRemovePacksClick = (id?: number) => {
    onTriggerRemovePacksObserver.notify(
      () => removePacksFromGroupTemplate(groupId, id ? [id] : selectedPackIds),
      id ? 1 : selectedPackIds.length,
    );
  };
  const renderRemovePacksButton = () => <RemoveLinkButton onClick={() => onRemovePacksClick()} />;
  const renderAddPacksButton = () => (
    <Button primary content={"Add Packs"} className="create-button" onClick={() => setShowAddPacksModal(true)} />
  );

  const currentStep = useMemo(() => {
    return pathname.split("/").at(-1);
  }, [pathname]);

  const isInfoTab = currentStep === Pages.Configuration;

  return (
    <>
      <Dimmer active={isLoadingGroupInfo} inverted>
        <Loader active={isLoadingGroupInfo} />
      </Dimmer>

      <RestrictedResource isAuthorized={!!isAccessAuthorized}>
        <section className="nested-content">
          <GenericSectionHeader
            title={groupInfo.name || ""}
            titleForGA="Template Details"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.peopleGroups}`)}
            dropdownButtons={renderDropdownButtons()}
            buttons={renderHeaderButtons()}
          />
          <SectionSubHeader
            lastModifiedDateTime={isInfoTab ? groupInfo.dateModified : undefined}
            isUpdateInProgress={false}
            publishedStatus={"published"}
          >
            <RemovePacksConfirmationModal
              onTriggerRemovePacksObserver={onTriggerRemovePacksObserver}
              onConfirmed={() => setSelectedPackIds([])}
            />
            <AddPacksModal showModal={showAddPacksModal} setShowModal={setShowAddPacksModal} templateId={groupId} />
            <Segments to={`/${RouteNames.peopleGroups}/template/${groupId}`}>
              <Segments.Segment label="Configuration" />
              <Segments.Segment to={Pages.Enrollment} label="Enrollment" />
              <Segments.Segment to={Pages.Packs} label="Packs" />
              <Segments.Segment to={Pages.Customers} label="Customers" />
              <Segments.Segment to={Pages.Content} label="Content" />
            </Segments>
          </SectionSubHeader>
          <Routes>
            <Route path="/" element={<EditConfiguration isLoading={isLoadingGroupInfo} groupId={groupId} />} />
            <Route path={Pages.Enrollment} element={<EditTemplateEnrollment templateId={groupId} disabled={false} />} />
            <Route
              path={Pages.Packs}
              element={
                <EditTemplatePacks
                  templateId={groupId}
                  selectedIds={selectedPackIds}
                  setSelectedIds={setSelectedPackIds}
                  onRemovePackClick={onRemovePacksClick}
                />
              }
            />
            <Route path={Pages.Customers} element={<EditTemplateCustomers templateId={groupId} ref={customersRef} />} />
            <Route path={Pages.Content} element={<PeopleContent templateId={groupId} />} />
            <Route path="*" element={<Navigate to="../" replace />} />
          </Routes>
        </section>
      </RestrictedResource>
    </>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;

  return {
    groupInfo: groupTemplate.base.configuration,
    isLoadingGroupInfo: groupTemplate.base.isLoading,
    isAccessAuthorized: groupTemplate.base.isAccessAuthorized,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetTemplateState: () => dispatch(reset()),
    resetCustomersState: () => dispatch(resetCustomers()),
    resetPacksState: () => dispatch(resetPacks()),
    fetchTemplate: bindAction(fetchTemplate, dispatch),
    removePacksFromGroupTemplate: bindAction(removePacks, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditGroupTemplate);
