import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { intersection, isEmpty } from "lodash";

import { RootState, AppDispatch } from "../../Application/globaltypes/redux";
import { RolePermissions, RouteNames, ViewType, SortOptions } from "../../../enums";
import Segments from "../../../components/navigation/segments/Segments";
import GenericSectionHeader from "../../../components/genericSectionHeader/GenericSectionHeader";
import * as editAccountActions from "./state/editAccountActions";
import * as accountInformationActions from "../AccountInformation/state/accountInformationActions";
import EditContacts from "./EditContacts/EditContacts";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import * as notificationsActions from "../../Notifications/state/notificationsActions";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import EditInfo from "./EditInfo/EditInfo";
import accountsDataService from "../services/accountsDataService";
import { MessageIdsType } from "../../../interfaces/updateParams";
import EditAddOns from "./EditAddOns/EditAddOns";
import { RestrictedResource } from "../../../components";
import Restricted from "../../Application/Restricted";
import EditPacks from "./EditPacks/EditPacks";
import packsDataService from "../../Licensing/Packs/services/packsDataService";
import { Filters } from "../../../utils/queryUtils";
import { AccountPackLicense, EmailSetting, SavingAddOn } from "../types";
import SubscriptionAgreement from "./subscriptionAgreement/SubscriptionAgreement";
import { AssignAccountPack } from "../../../components/assignmentModals/packAssignmentModal/types";
import {
  availablePacksFiltersSlice,
  appliedFiltersSelector as availablePacksAppliedFiltersSelector,
  filterOptionsSelector as availablePacksFilterOptionsSelector,
  resetAppliedFilter as availablePacksResetAppliedFilter,
  setAppliedFilter as availablePacksSetAppliedFilter,
  isLoadingSelector as availablePacksFilterIsLoadingSelector,
} from "../../Licensing/Packs/state/slices/availablePacksFiltersSlice";
import {
  accountPacksFiltersSlice,
  appliedFiltersSelector as accountPacksAppliedFiltersSelector,
  filterOptionsSelector as accountPacksFilterOptionsSelector,
  resetAppliedFilter as accountPacksResetAppliedFilter,
  setAppliedFilter as accountPacksSetAppliedFilter,
  isLoadingSelector as accountPacksFilterIsLoadingSelector,
} from "../../Licensing/Packs/state/slices/accountPacksFiltersSlice";
import { getFilterOptions } from "../../Licensing/Packs/state/thunks/packsFiltersThunk";
import { packsModalSearchSelector, setTerm } from "../../Licensing/Packs/state/slices/availablePacksSearchSlice";
import { fetchavAilablePacks } from "../../Licensing/Packs/state/thunks/availablePacksThunk";
import { reset } from "../../Licensing/Packs/state/slices/availablePacksSlice";
import {
  accontPacksSearchSelector,
  setAccountPacksSearchTerm,
} from "../../Licensing/Packs/state/slices/accountPacksSearchSlice";
import { resetAccountPacksGrid } from "../../Licensing/Packs/state/slices/accountPacksSlice";
import { addPacksToAccount, fetchAccountPacksGrid } from "../../Licensing/Packs/state/thunks/accountPacksThunk";
import { PacksRequest } from "../../Licensing/Packs/types/requests";
import * as contractTypesThunk from "./../state/thunks/contractTypesThunk";
import { fetchPotentialContacts } from "./state/thunks/potentialContactsThunk";
import { fetchPermissionsToAccount } from "./state/thunks/permissionsToAccountThunk";
import { FiltersMap } from "../../../utils/filterUtils";
import { permissionsToAccountSelector } from "./state/slices/permissionsToAccountSlice";
import { bindAction } from "../../../interfaces";
import IntegrationsOverview from "../Integrations/IntegrationsOverview/IntegrationsOverview";
import EditPublicApi from "../Api/EditPublicApi/EditPublicApi";
import IdentityProvidersOverview from "../IdentityProvidersOverview/IdentityProvidersOverview";
import { withRouter, WithRouterProps } from "../../../adapters/withRouter/withRouter";
import navigationUtils from "../../../utils/navigationUtils";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import "./editAccount.scss";
import { EmailSettings } from "../EmailSettings/EmailSettings";
import { emailSettingsSelector } from "../EmailSettings/state/slices/emailSettingsSlice";
import {
  deleteEmailSettings,
  fetchEmailSettings,
  upsertEmailSettings,
  verifyEmailSettings,
} from "../EmailSettings/state/thunks/emailSettingsThunk";
import { FeatureFlags } from "featureFlags";
import { LDProps } from "../../LDProps";
import { parentAccountsSelector } from "../state/slices/parentAccountSlice";
import { fetchParentAccounts } from "../state/thunks/parentAccountThunk";

const overviewUrl = `/${RouteNames.linkedAccountsManagement}`;
const accountHomePath = `/${RouteNames.accounts}/home`;

interface EditAccountState {
  showAddContactsModal: boolean;
  showAddPacksModal: boolean;
  getAvailablePacksForModalRequest?: any;
}

export type EditAccountProps = PropsFromRedux & WithRouterProps & LDProps;

export class EditAccount extends React.Component<EditAccountProps, EditAccountState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showAddContactsModal: false,
      showAddPacksModal: false,
      getAvailablePacksForModalRequest: undefined,
    };
  }
  private static readonly segmentPaths = {
    legal: "legal",
    contacts: "contacts",
    content: "content",
    addOns: "addons",
    packs: "packs",
    integrations: "integrations",
    sso: "sso",
    api: "api",
    emailSettings: "email-settings",
  };

  componentDidMount() {
    const accountId = this.getAccountId();
    this.props.editAccountActions.fetchAccountInfo(accountId);
    this.props.fetchPermissionsToAccount(accountId);
  }

  componentWillUnmount() {
    this.props.editAccountActions.resetAccountContacts();
    this.props.editAccountActions.resetAddOns();
    this.props.editAccountActions.resetAccountPacksForbidden();
  }

  goToEditUser = (userId: number) => {
    this.props.navigate(`/${RouteNames.peopleUsers}/${userId}`);
  };

  getAccountId = () => {
    const { params, accountId } = this.props;
    return parseInt(params.moboId) || accountId;
  };

  isOwnHomePage() {
    return this.isHomeUrl() && this.isOwnAccount();
  }

  isContactsTab() {
    return this.isActiveRoute(EditAccount.segmentPaths.contacts);
  }

  isAddOnsTab() {
    return this.isActiveRoute(EditAccount.segmentPaths.addOns);
  }

  isPacksTab() {
    return this.isActiveRoute(EditAccount.segmentPaths.packs);
  }

  isActiveRoute(route: string) {
    const {
      location: { pathname },
    } = this.props;

    return pathname.includes(route);
  }

  isHomeUrl = () => {
    const { location } = this.props;
    return location.pathname.includes(accountHomePath);
  };

  renderAddContactsButton = () => {
    return (
      <Button className="create-button" onClick={this.onAddContactsButtonClick} primary>
        Add Contact
      </Button>
    );
  };

  renderAddPacksButton = () => {
    return (
      <Button className="create-button" onClick={this.onAddPacksButtonClick} primary>
        Add Pack
      </Button>
    );
  };

  renderHeaderButtons = () => {
    let buttons: any = [];
    const { userPermissions, contactsList } = this.props;

    if (
      this.isContactsTab() &&
      userPermissions.includes(RolePermissions.AccountsSettingsManage) &&
      contactsList.isAccessAuthorized
    ) {
      buttons = [this.renderAddContactsButton()];
    }

    if (
      this.isPacksTab() &&
      this.props.isPacksAccessAuthorized &&
      this.props.params.moboId &&
      this.props.userPermissions.includes(RolePermissions.AccountsSettingsManage)
    ) {
      buttons = [this.renderAddPacksButton()];
    }

    return buttons;
  };

  onAddContactsButtonClick = () => {
    this.setState({ showAddContactsModal: true });
  };

  onAddPacksButtonClick = () => {
    this.setState({ showAddPacksModal: true });
  };

  onCloseAddPacksModal = () => {
    this.setState({ showAddPacksModal: false });
  };

  onCloseAddContactsModal = () => {
    this.props.editAccountActions.resetAddContactsSearch();
    this.setState({ showAddContactsModal: false });
  };

  onConfirmAddContactsModal = (userIds: number[]) => {
    this.onCloseAddContactsModal();

    const accountId = this.getAccountId();
    const userInMessageSingular = userIds.length === 1;
    const params = {
      id: "AddContactsToAccount",
      title: "Add contacts to account",
      indeterminate: true,
      getMessageIds: async () => {
        const executionId = await accountsDataService.addAccountContacts(accountId, userIds);
        return [executionId];
      },
      successTransientMessage: userInMessageSingular
        ? "Contact has been added to account successfully"
        : "Contacts have been added to account successfully",
      failureTransientMessage: userInMessageSingular
        ? "Adding contact to account failed!"
        : "Adding contacts to account failed!",
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  refreshPacksList = (accountId: number, selectedViewType: ViewType) => {
    if (selectedViewType === ViewType.GRID) {
      const { accountPacksAppliedFilter, accountPacksActions } = this.props;

      accountPacksActions.resetAccountPacksGrid();
      accountPacksActions.fetchAccountPacksGrid({
        accountId: accountId,
        skip: 0,
        orderBy: SortOptions.Title,
        filters: accountPacksAppliedFilter,
        searchTerm: this.props.accountPacksSearch.term,
      });
    }
  };

  triggerRefreshPacksGrid = (viewType: ViewType) => {
    this.refreshPacksList(this.getAccountId(), viewType);
  };

  onConfirmAddPacksModal = async (packs: AssignAccountPack[], selectedViewType: ViewType) => {
    this.setState({ showAddPacksModal: false });
    const accountId = this.getAccountId();
    this.props.addPacksToAccount(packs, accountId, () => {
      this.refreshPacksList(accountId, selectedViewType);
    });
  };

  onConfirmEditLicensingModal = (accountPackLicense: AccountPackLicense, selectedViewType: ViewType) => {
    const accountId = this.getAccountId();
    const params = {
      id: "EditPackLicensesForAccount",
      title: "Edit pack licenses for account",
      indeterminate: true,
      getMessageIds: async () => {
        const response = await packsDataService.updateAccountPackLicenseAsync(accountId, {
          id: accountPackLicense.packAccountLicensesId,
          expirationDate: accountPackLicense.expirationDate,
          licensesCount: accountPackLicense.totalLicensesCount,
          licenseType: accountPackLicense.licenseTypeId,
        });
        return [response.data];
      },
      successTransientMessage: "Licensing updated successfully.",
      failureTransientMessage: "Pack renewal failed!",
      onCompleted: () => {
        this.refreshPacksList(accountId, selectedViewType);
      },
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onConfirmExtendLicensesModal = (packs: AssignAccountPack[], selectedViewType: ViewType) => {
    const accountId = this.getAccountId();
    const params = {
      id: "ExtendPackLicensesToAccount",
      title: "Edit pack licenses for account",
      indeterminate: true,
      getMessageIds: async () => {
        const response = await packsDataService.extendPacksLicensesToAccountsAsync(accountId, {
          packInfos: packs,
        });
        return [response.data];
      },
      successTransientMessage: "Licensing updated successfully.",
      failureTransientMessage: "Pack renewal failed!",
      onCompleted: () => {
        this.refreshPacksList(accountId, selectedViewType);
      },
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onConfirmRestorePackModal = (packs: AssignAccountPack[], selectedViewType: ViewType) => {
    const accountId = this.getAccountId();
    const params = {
      id: "RestorePackForAccount",
      title: "Restore pack for account",
      indeterminate: true,
      getOperationProps: async () => {
        const response = await packsDataService.restoreAccountPackAsync(accountId, {
          packInfos: packs,
        });
        return response.data;
      },
      successTransientMessage: "Pack restored successfully.",
      failureTransientMessage: "Pack restoring failed!",
      onCompleted: () => {
        this.refreshPacksList(accountId, selectedViewType);
      },
    };

    const {
      backgroundTasksActions: { addOperationDistributedOp },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntityDistributedOp(params, {
      addOperation: addOperationDistributedOp,
      sendTransientNotification,
    });
  };

  updateContactsHandler = async (changes: {
    mainContactId?: number;
    unassignPreviousMainContactRoles: boolean;
    removedContactIds: number[];
  }) => {
    const params = {
      id: "UpdateAccountContacts",
      title: "Update contacts",
      indeterminate: true,
      getMessageIds: async () => {
        const messageId = await accountsDataService.updateAccountContacts(this.getAccountId(), changes);
        this.props.editAccountActions.updateAccountContacts({
          mainContactId: changes.mainContactId,
          removedContactIds: changes.removedContactIds,
        });
        return [messageId];
      },
      successTransientMessage: "Contacts have been updated successfully",
      failureTransientMessage: "Contacts update have been failed",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  updateAccountInfoHandler = (account: any, accountId: number, isReadOnly: boolean) => {
    const params = {
      id: "UpdateAccountInformation",
      title: "Update account information",
      indeterminate: true,
      getMessageIds: async (): Promise<MessageIdsType> => {
        const messageId = isReadOnly
          ? await accountsDataService.updateOwnAccount(account)
          : await accountsDataService.updateAccountInfo(accountId, account);
        this.props.editAccountActions.updateAccountInfo(account);
        if (this.isHomeUrl()) this.props.accountInformationActions(account);
        return [messageId];
      },
      successTransientMessage: "Account information update succeeded!",
      failureTransientMessage: "Account information update failed!",
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  updateAddOns = (addOns: Array<SavingAddOn>) => {
    const accountId = this.getAccountId();
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
      editAccountActions: { updateAccountAddOns },
    } = this.props;

    const params = {
      id: "UpdateAccountAddOns",
      title: "Update account Add-Ons",
      indeterminate: true,
      getMessageIds: async (): Promise<MessageIdsType> => {
        const messageId = await accountsDataService.updateAddOns(accountId, addOns);
        updateAccountAddOns(addOns);
        return [messageId];
      },
      successTransientMessage: "Account Add-Ons update succeeded!",
      failureTransientMessage: "Account Add-Ons update failed!",
    };
    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  fetchAddOns = () => {
    const accountId = this.getAccountId();
    this.props.editAccountActions.fetchAddOns(accountId);
  };

  fetchAccountContacts = (accountId: number, skip: number, top: number, orderParams: string, filterParams: any) => {
    this.props.editAccountActions.fetchAccountContacts(accountId, skip, top, orderParams, filterParams);
  };

  fetchAccountTypes = () => {
    const accountId = this.getAccountId();
    if (!this.isOwnHomePage()) {
      this.props.editAccountActions.fetchAccountTypes(accountId);
    }
  };

  fetchContractTypes = () => {
    this.props.fetchContractTypes();
  };

  fetchParentAccounts = (accountTypeId: number) => this.props.fetchParentAccounts(accountTypeId, this.getAccountId());

  updateEmailSettings = (emailSettings: EmailSetting) => {
    emailSettings.accountId = this.getAccountId();
    this.props.saveEmailSettings(emailSettings);
  };

  getEmailSettings = () => {
    const accountId = this.getAccountId();
    this.props.getEmailSettings(accountId);
  };

  deleteEmailSettings = () => {
    const accountId = this.getAccountId();
    this.props.deleteEmailSettings(accountId);
  };

  markTabAsReadOnly = () => {
    const isReadOnly = (viewPermission: RolePermissions, managePermission: RolePermissions) => {
      const { userPermissions } = this.props;
      return userPermissions.includes(viewPermission) && !userPermissions.includes(managePermission);
    };

    return this.isAddOnsTab()
      ? isReadOnly(RolePermissions.AddonsView, RolePermissions.AddonsManage)
      : isReadOnly(RolePermissions.AccountsSettingsView, RolePermissions.AccountsSettingsManage);
  };

  loadPacksForModal = (assignedAccountId: number, skip: number, orderBy: string, filters?: Filters) => {
    this.state.getAvailablePacksForModalRequest && this.state.getAvailablePacksForModalRequest?.abort();
    const request = this.props.packsModalActions.fetchItems({
      assignedAccountId: assignedAccountId,
      skip: skip,
      orderBy: orderBy,
      filters: filters,
      searchTerm: this.props.packsModalSearch.term,
    });
    this.setState({ getAvailablePacksForModalRequest: request });
  };

  loadPackForModal = (assignedAccountId: number, packIds: number[]) => {
    this.state.getAvailablePacksForModalRequest && this.state.getAvailablePacksForModalRequest?.abort();
    const request = this.props.packsModalActions.fetchItems({
      assignedAccountId: assignedAccountId,
      filters: { primaryId: packIds?.length ? packIds[0] : 0 },
    });
    this.setState({ getAvailablePacksForModalRequest: request });
  };

  resetPacksModal = () => {
    const { packsModalActions } = this.props;
    packsModalActions.resetGrid();
  };

  resetPackModalFilter = () => {
    this.props.resetPackModalFilter();
  };

  applyPackModalFilter = (filter: Filters) => {
    this.props.applyPackModalFilter(filter);
  };

  resetAccountPacksFilter = () => {
    this.props.resetAccountPacksFilter();
  };

  applyAccountPacksFilter = (filter: Filters) => {
    this.props.applyAccountPacksFilter(filter);
  };

  getPackModalFilterOptions = () => {
    this.props.getPackModalFilterOptions();
  };

  getAccountPacksFilterOptions = () => {
    this.props.getAccountPacksFilterOptions();
  };

  getAccountTypes = () => {
    const { accountTypeId, accountTypeName } = this.props.accountConfiguration;
    const { accountTypes } = this.props;

    return this.isOwnHomePage()
      ? [
          {
            id: accountTypeId,
            name: accountTypeName,
          },
        ]
      : accountTypes;
  };

  getParentAccounts = () => {
    const { hasGlobalAccess, parentAccounts, accountConfiguration } = this.props;
    if (!hasGlobalAccess || this.isOwnAccount()) {
      return accountConfiguration.parentAccountId
        ? {
            items: [
              {
                id: accountConfiguration.parentAccountId,
                name: accountConfiguration.parentName,
              },
            ],
            isResourceUnavailable: false,
            isLoading: false,
          }
        : { items: [], isResourceUnavailable: true, isLoading: false };
    }

    return parentAccounts;
  };

  isOwnAccount = () => {
    const { isMobo, accountId } = this.props;
    return !isMobo && accountId === this.getAccountId();
  };

  hasPermissionsToAccountPredicate =
    (userPermissions: RolePermissions[]) => (_: any, requiredPermissions?: RolePermissions[]) =>
      !!requiredPermissions && intersection(userPermissions, requiredPermissions).length > 0;

  render() {
    const {
      accountConfiguration,
      isLoadingAccountTypes,
      isLoadingContractTypes,
      isLoadingPermissionsToAccount,
      isLoading,
      isAccessAuthorizedForInfo,
      contactsList,
      packsList,
      packsGrid,
      onFetchPotentialContacts,
      editAccountActions: { fetchAccountPacks, setAddContactsSearch, resetAccountPacksList },
      potentialContacts,
      isMobo,
      hasGlobalAccess,
      addOns,
      contractTypes,
      packsModalFilterOptions,
      packsModalAppliedFilter,
      packsModalFilterIsLoading,
      accountPacksFilterOptions,
      accountPacksAppliedFilter,
      accountPacksFilterIsLoading,
      packsModal,
      packsModalSearch,
      accountPacksActions,
      accountPacksSearch,
      addContactsSearch,
      userPermissions,
      location,
      navigate,
      areAllLoaded,
    } = this.props;

    const accountTypes = this.getAccountTypes();
    const smtpDirectSend = !!this.props.flags?.[FeatureFlags.SmtpDirectSend];

    const { showAddContactsModal, showAddPacksModal } = this.state;

    const isInfoLoading = isLoading || isLoadingAccountTypes || isLoadingPermissionsToAccount;
    const accountId = this.getAccountId();

    const basePath = this.isHomeUrl() ? accountHomePath : `${overviewUrl}/${accountId}`;
    let isFiltered = !isEmpty(packsModalAppliedFilter) || !isEmpty(packsModalSearch.term);

    return (
      <section className="edit-account nested-content">
        <GenericSectionHeader
          title={!this.isHomeUrl() ? accountConfiguration.name : "Account Settings"}
          titleForGA="Account Details"
          goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, overviewUrl)}
          showGoBackIcon={!this.isHomeUrl()}
          buttons={this.renderHeaderButtons()}
          isReadOnly={!this.isOwnAccount() && this.markTabAsReadOnly()}
        />

        <Segments to={basePath}>
          <Segments.Segment label="Configuration" />
          <Segments.Segment to={EditAccount.segmentPaths.packs} label="Packs" />
          <Segments.Segment to={EditAccount.segmentPaths.addOns} label="Add-ons" />
          <Segments.Segment to={EditAccount.segmentPaths.contacts} label="Contacts" />
          <Segments.Segment to={EditAccount.segmentPaths.legal} label="Legal" />
          <Segments.Segment to={EditAccount.segmentPaths.integrations} label="Integrations" />
          <Segments.Segment to={EditAccount.segmentPaths.sso} label="SSO" />
          <Segments.Segment to={EditAccount.segmentPaths.api} label="API" />
          {smtpDirectSend && <Segments.Segment to={EditAccount.segmentPaths.emailSettings} label="Email Settings" />}
        </Segments>
        <Routes>
          <Route
            path="/"
            element={
              <RestrictedResource isAuthorized={isAccessAuthorizedForInfo}>
                <EditInfo
                  accountId={accountId}
                  parentAccounts={this.getParentAccounts()}
                  accountTypes={accountTypes}
                  contractTypes={contractTypes}
                  fetchAccountTypes={this.fetchAccountTypes}
                  fetchContractTypes={this.fetchContractTypes}
                  fetchParentAccounts={this.fetchParentAccounts}
                  currentConfiguration={accountConfiguration}
                  isLoadingAccountTypes={isLoadingAccountTypes}
                  isLoadingContractTypes={isLoadingContractTypes}
                  onInfoUpdated={this.updateAccountInfoHandler}
                  isOwnAccount={this.isOwnAccount()}
                  isLoading={isInfoLoading}
                  isMobo={isMobo}
                  hasGlobalAccess={hasGlobalAccess}
                />
              </RestrictedResource>
            }
          />
          {/* istanbul ignore next */}
          <Route path={EditAccount.segmentPaths.legal} element={<SubscriptionAgreement accountId={accountId} />} />
          {/* istanbul ignore next */}
          <Route
            path={EditAccount.segmentPaths.contacts}
            element={
              <RestrictedResource isAuthorized={contactsList.isAccessAuthorized}>
                <EditContacts
                  accountId={accountId}
                  contactsList={contactsList}
                  onFetchContacts={this.fetchAccountContacts}
                  fetchPotentialContacts={onFetchPotentialContacts}
                  onCloseAddContactsModal={this.onCloseAddContactsModal}
                  onConfirmAddContactsModal={this.onConfirmAddContactsModal}
                  renderAddContactsButton={this.renderAddContactsButton}
                  isReadOnly={!accountConfiguration.canEdit}
                  isAccountInfoLoading={isInfoLoading}
                  goToEditUser={this.isHomeUrl() ? this.goToEditUser : undefined}
                  showAddContactsModal={showAddContactsModal}
                  potentialContacts={potentialContacts}
                  onContactsUpdated={this.updateContactsHandler}
                  addContactsSearch={addContactsSearch}
                  setAddContactsSearch={setAddContactsSearch}
                  permissionsToAccount={userPermissions}
                />
              </RestrictedResource>
            }
          />
          {/* istanbul ignore next */}
          <Route path={EditAccount.segmentPaths.content} element={<div> Coming soon...</div>} />
          <Route
            path={EditAccount.segmentPaths.packs}
            element={
              <RestrictedResource isAuthorized={this.props.isPacksAccessAuthorized}>
                <EditPacks
                  selectedAccountId={accountId}
                  accountId={accountId}
                  packsList={packsList}
                  packsGrid={packsGrid}
                  onFetchPacks={fetchAccountPacks}
                  onResetPacksList={resetAccountPacksList}
                  onFetchPacksGrid={accountPacksActions.fetchAccountPacksGrid}
                  onResetPacksGrid={accountPacksActions.resetAccountPacksGrid}
                  renderAddPacksButton={this.renderAddPacksButton}
                  isReadOnly={!accountConfiguration.canEdit}
                  showAddPacksModal={showAddPacksModal}
                  isAccountInfoLoading={isInfoLoading}
                  canAddPack={
                    !this.isHomeUrl() && this.props.userPermissions.includes(RolePermissions.AccountsSettingsManage)
                  }
                  hasPurchasePower={this.props.userPermissions.includes(RolePermissions.PacksPurchase)}
                  onCloseAddPacksModal={this.onCloseAddPacksModal}
                  onConfirmAddPacksModal={this.onConfirmAddPacksModal}
                  onConfirmEditLicensingModal={this.onConfirmEditLicensingModal}
                  onConfirmExtendLicensesModal={this.onConfirmExtendLicensesModal}
                  onConfirmRestorePackModal={this.onConfirmRestorePackModal}
                  addPacksModal={packsModal}
                  loadPacksForModal={this.loadPacksForModal}
                  loadPackForModal={this.loadPackForModal}
                  resetModalGrid={this.resetPacksModal}
                  packsModalSearch={this.props.packsModalSearch}
                  setPacksModalSearch={this.props.setPacksModalSearch}
                  isPacksModalFiltered={isFiltered}
                  modalFilter={{
                    filterOptions: packsModalFilterOptions,
                    appliedFilter: packsModalAppliedFilter,
                    applyFilter: this.applyPackModalFilter,
                    resetFilter: this.resetPackModalFilter,
                    fetchFilterOptions: this.getPackModalFilterOptions,
                    isLoading: packsModalFilterIsLoading,
                  }}
                  accountPacksFilter={{
                    filterOptions: accountPacksFilterOptions,
                    appliedFilter: accountPacksAppliedFilter,
                    applyFilter: this.applyAccountPacksFilter,
                    resetFilter: this.resetAccountPacksFilter,
                    fetchFilterOptions: this.getAccountPacksFilterOptions,
                    isLoading: accountPacksFilterIsLoading,
                  }}
                  accountPacksSearch={accountPacksSearch}
                  setAccountPacksSearch={accountPacksActions.setAccountPacksSearch}
                  triggerRefreshPacksGrid={this.triggerRefreshPacksGrid}
                  isAccountHome={this.isHomeUrl()}
                  permissionsToAccount={userPermissions}
                />
              </RestrictedResource>
            }
          />

          <Route
            path={EditAccount.segmentPaths.addOns}
            element={
              <Restricted
                permissionPredicate={this.hasPermissionsToAccountPredicate(userPermissions)}
                permissions={[RolePermissions.AddonsManage]}
                renderContent={(canManage) => (
                  <RestrictedResource isAuthorized={addOns.isAccessAuthorized}>
                    <EditAddOns
                      fetchAddOns={this.fetchAddOns}
                      updateAddOns={this.updateAddOns}
                      addOns={addOns.items}
                      isLoading={addOns.isLoading}
                      isReadOnly={!canManage}
                    />
                  </RestrictedResource>
                )}
              />
            }
          />

          <Route
            path={EditAccount.segmentPaths.integrations}
            element={
              <>
                {areAllLoaded ? (
                  <RestrictedResource
                    isAuthorized={this.props.userPermissions.includes(RolePermissions.AccountsSettingsView)}
                  >
                    <IntegrationsOverview
                      userPermissions={this.props.userPermissions}
                      isReadOnly={!accountConfiguration.canEdit}
                    />
                  </RestrictedResource>
                ) : (
                  <Loader active />
                )}
              </>
            }
          />
          <Route
            path={EditAccount.segmentPaths.sso}
            element={
              <>
                {areAllLoaded ? (
                  <RestrictedResource
                    isAuthorized={this.props.userPermissions.includes(RolePermissions.AccountsSettingsView)}
                  >
                    <IdentityProvidersOverview isReadOnly={!accountConfiguration.canEdit} />
                  </RestrictedResource>
                ) : (
                  <Loader active />
                )}
              </>
            }
          />
          <Route
            path={EditAccount.segmentPaths.api}
            element={
              <>
                {areAllLoaded ? (
                  <RestrictedResource isAuthorized={this.props.userPermissions.includes(RolePermissions.APIsView)}>
                    <EditPublicApi />
                  </RestrictedResource>
                ) : (
                  <Loader active />
                )}
              </>
            }
          />
          {smtpDirectSend && (
            <Route
              path={EditAccount.segmentPaths.emailSettings}
              element={
                <>
                  {areAllLoaded ? (
                    <RestrictedResource
                      isAuthorized={
                        this.props.userPermissions.includes(RolePermissions.AccountsSettingsManage) ||
                        this.props.userPermissions.includes(RolePermissions.AccountsSettingsView) ||
                        this.props.userPermissions.includes(RolePermissions.AccountsSettingsCreate)
                      }
                    >
                      <EmailSettings
                        onSubmit={this.updateEmailSettings}
                        currentConfiguration={this.props.emailSettings.settings}
                        isLoading={this.props.emailSettings.isLoading}
                        getEmailSettings={this.getEmailSettings}
                        deleteEmailSettings={this.deleteEmailSettings}
                        canEdit={this.props.userPermissions.includes(RolePermissions.AccountsSettingsManage)}
                        error={this.props.emailSettings.error?.message}
                        verifyEmailSettings={this.props.verifyEmailSettings}
                      />
                    </RestrictedResource>
                  ) : (
                    <Loader active />
                  )}
                </>
              }
            />
          )}
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { edit, contractTypes } = state.accounts;
  const { userProfile } = state;

  return {
    isPacksAccessAuthorized: state.accounts.edit.accountPacks.isAccessAuthorized,
    accountId: state.userProfile.accountId,
    accountTypes: edit.accountInfo.accountTypes,
    contractTypes: contractTypes.items,
    accountConfiguration: edit.accountInfo.accountConfiguration,
    isLoadingAccountTypes: edit.accountInfo.isLoadingAccountTypes,
    isLoadingContractTypes: contractTypes.isLoading,
    isLoadingPermissionsToAccount: edit.permissionsToAccount.isLoading,
    isAccessAuthorizedForInfo: edit.accountInfo.isAccessAuthorized,
    isLoading: edit.accountInfo.isLoading,
    error: edit.accountInfo.error,
    contactsList: edit.contactsList,
    potentialContacts: edit.potentialContacts,
    addOns: edit.addOns,
    isMobo: userProfile.isMobo,
    hasGlobalAccess: userProfile.hasGlobalAccess,
    userPermissions: permissionsToAccountSelector(state),
    areAllLoaded: state.accounts.edit.permissionsToAccount.areAllLoaded,
    packsList: edit.packsList,
    packsGrid: edit.packsGrid,
    packsModal: state.packs.packsModal,
    packsModalSearch: packsModalSearchSelector(state),
    packsModalFilterOptions: availablePacksFilterOptionsSelector(state),
    packsModalAppliedFilter: availablePacksAppliedFiltersSelector(state),
    packsModalFilterIsLoading: availablePacksFilterIsLoadingSelector(state),
    accountPacksFilterOptions: accountPacksFilterOptionsSelector(state),
    accountPacksAppliedFilter: accountPacksAppliedFiltersSelector(state),
    accountPacksFilterIsLoading: accountPacksFilterIsLoadingSelector(state),
    accountPacksSearch: accontPacksSearchSelector(state),
    addContactsSearch: edit.addContactsSearch,
    emailSettings: emailSettingsSelector(state),
    parentAccounts: parentAccountsSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    editAccountActions: bindActionCreators(editAccountActions, dispatch),
    accountInformationActions: bindActionCreators(accountInformationActions.updateData, dispatch),
    onFetchPotentialContacts: bindActionCreators(
      (accountId: number, skip: number, top: number, orderBy: string, filter: FiltersMap, search?: string) =>
        fetchPotentialContacts({ accountId, skip, top, orderBy, filter, search }),
      dispatch,
    ),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
    packsModalActions: {
      resetGrid: () => dispatch(reset()),
      fetchItems: (requestData: any) => dispatch(fetchavAilablePacks(requestData)),
    },
    getPackModalFilterOptions: () => dispatch(getFilterOptions(availablePacksFiltersSlice)),
    getAccountPacksFilterOptions: () => dispatch(getFilterOptions(accountPacksFiltersSlice, true)),
    applyPackModalFilter: (filters: any) => dispatch(availablePacksSetAppliedFilter(filters)),
    resetPackModalFilter: () => dispatch(availablePacksResetAppliedFilter()),
    applyAccountPacksFilter: (filters: any) => dispatch(accountPacksSetAppliedFilter(filters)),
    resetAccountPacksFilter: () => dispatch(accountPacksResetAppliedFilter()),
    setPacksModalSearch: bindAction(setTerm, dispatch),
    accountPacksActions: {
      resetAccountPacksGrid: () => dispatch(resetAccountPacksGrid()),
      fetchAccountPacksGrid: (requestData: PacksRequest) => dispatch(fetchAccountPacksGrid(requestData)),
      setAccountPacksSearch: (term: string) => dispatch(setAccountPacksSearchTerm(term)),
    },
    fetchContractTypes: bindActionCreators(contractTypesThunk.fetchContractTypes, dispatch),
    fetchPermissionsToAccount: bindAction(fetchPermissionsToAccount, dispatch),
    addPacksToAccount: bindActionCreators(addPacksToAccount, dispatch),
    getEmailSettings: bindAction(fetchEmailSettings, dispatch),
    saveEmailSettings: bindAction(upsertEmailSettings, dispatch),
    deleteEmailSettings: bindAction(deleteEmailSettings, dispatch),
    verifyEmailSettings: bindAction(verifyEmailSettings, dispatch),
    fetchParentAccounts: bindAction(fetchParentAccounts, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(EditAccount)));
