import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

import "./ellipsisButton.scss";

export class EllipsisButton extends Component {
  render() {
    const { clickHandler } = this.props;

    return (
      <div className="text-center">
        <Icon className="fa-ellipsis-h ellipsis-button" size="big" onClick={clickHandler} />
      </div>
    );
  }
}

export default EllipsisButton;
