import { RootState } from "features/Application/globaltypes/redux";
import GroupConfiguration from "features/People/Groups/AddEditGroupForms/GroupConfiguration/GroupConfiguration";
import { useFormik } from "formik";
import { noop } from "lodash";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { validationSchemas } from "utils";
import { ConnectedProps, connect } from "react-redux";
import { TemplateConfigurationView } from "../state/GroupTemplateSlice";
import nameof from "utils/nameof";

interface Props {
  onIsValidChange?: (isValid: boolean) => void;
  onIsDirtyChange: (isDirty: boolean) => void;
  isLoadingGroupInfo: boolean;
}

export type ConfigurationRef = {
  resetForm: () => void;
  getValues: () => TemplateConfigurationView;
};

type AllProps = Props & PropsFromRedux;

export const Configuration = forwardRef<ConfigurationRef, AllProps>((props, ref) => {
  const { onIsDirtyChange, groupId, template } = props;
  const isCreated = groupId > 0;
  const validationSchema = useMemo(() => {
    return isCreated ? validationSchemas.getEditGroupSchema(groupId) : validationSchemas.group;
  }, [isCreated, groupId]);

  const initialValues = useMemo(() => {
    return {
      name: template.name,
      description: template.description,
      imageUrl: template.imageUrl,
      isAware: template.isAware,
      isVisibleToAll: template.isVisibleToAll,
      isOpen: template.isOpen,
      isWithAutoEnroll: true,
    };
  }, [template]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: noop,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { setFieldValue, setValues, values, resetForm } = formik;

  useImperativeHandle(
    ref,
    () => ({
      resetForm: () => resetForm(),
      getValues: () => values,
    }),
    [resetForm, values],
  );

  const onIsAwareChanged = (_: any, data: any) => {
    const isAware = data.checked;
    setValues((prev) => ({
      ...prev,
      [nameof<TemplateConfigurationView>("isAware")]: isAware,
      [nameof<TemplateConfigurationView>("isVisibleToAll")]: isAware ? isAware : null,
      [nameof<TemplateConfigurationView>("isOpen")]: isAware ? isAware : null,
    }));
  };

  const onVisibleToAllChanged = (_: any, data: any) => {
    const isVisibleToAll = data.checked;
    setFieldValue(nameof<TemplateConfigurationView>("isVisibleToAll"), isVisibleToAll, true);
  };

  const onIsOpenChanged = (_: any, data: any) => {
    const isOpen = !data.checked;
    setFieldValue(nameof<TemplateConfigurationView>("isOpen"), isOpen, true);
  };

  return (
    <GroupConfiguration
      {...formik}
      onIsValidChange={props.onIsValidChange}
      onDirtyChanged={onIsDirtyChange}
      groupSettings={{
        ...formik.values,
      }}
      onIsAwareChanged={onIsAwareChanged}
      onVisibleToAllChanged={onVisibleToAllChanged}
      onIsOpenChanged={onIsOpenChanged}
      disableEnrollmentEdit={isCreated}
      isLoadingGroupInfo={props.isLoadingGroupInfo}
      autoEnroll
    />
  );
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;
  return {
    groupId: groupTemplate.base.groupId,
    template: groupTemplate.base.configuration,
  };
};

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Configuration);
