import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Component } from "react";
import { Table } from "semantic-ui-react";
import { SearchInput, TextTruncate } from "../../../../../../components";
import MinusButton from "../../../../../../components/buttons/iconButtons/minusButton/MinusButton";
import Tooltip from "../../../../../../components/common/tooltip/Tooltip";
import { UsersFilterForm } from "../../../../../../components/filterForms";
import { ListViewBase } from "../../../../../../components/listView";
import NoResults from "../../../../../../components/noResults/NoResults";
import NoSearchResults from "../../../../../../components/noSearchResults";
import UserInfoCell from "../../../../../../components/userInfoCell/UserInfoCell";
import { SortingDirection, ViewType } from "../../../../../../enums";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { ItemsView } from "../../../../../../views";
import {
  AddUserToGroupAllSuccess,
  UserRemoveFromGroupSuccess,
} from "../../../../../Application/services/realTimeNotification/events/people/peopleEvents";
import "./groupUsersList.scss";

const membersListViewRtnEvents = [AddUserToGroupAllSuccess, UserRemoveFromGroupSuccess];
const getCommonColumnOptions = (sortable) => [
  {
    name: "Name",
    width: 4,
    isSortable: sortable,
  },
  {
    name: "Department",
    width: 3,
    isSortable: sortable,
  },
  {
    name: "Job title",
    width: 3,
    isSortable: sortable,
  },
];

const getColumnOptionsAutoEnroll = (rulesEngineOnCosmos) => [
  ...getCommonColumnOptions(!rulesEngineOnCosmos),
  {
    name: "Added",
    width: 3,
    isSortable: !rulesEngineOnCosmos,
  },
];

const columnOptionsManualEnroll = [
  ...getCommonColumnOptions(true),
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default class GroupUsersList extends Component {
  defaultSortingColumnName = "Added";

  buildTableBodyAutoEnroll = (user) => {
    const columnOptionsAutoEnroll = getColumnOptionsAutoEnroll(this.props.rulesEngineOnCosmos);
    return (
      <>
        <Table.Cell width={columnOptionsAutoEnroll[0].width}>
          <UserInfoCell deepLink={this.props.deepLink} user={user} />
        </Table.Cell>
        <Table.Cell width={columnOptionsAutoEnroll[1].width}>
          <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
        </Table.Cell>
        <Table.Cell width={columnOptionsAutoEnroll[2].width}>
          <Tooltip target={<TextTruncate>{user.title ?? user.jobTitle}</TextTruncate>} content={user.title ?? user.jobTitle} />
        </Table.Cell>
        <Table.Cell width={columnOptionsAutoEnroll[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
      </>
    );
  };

  buildTableBodyManualEnroll = (user, hideUserRemoveColumn) => {
    return (
      <>
        <Table.Cell width={columnOptionsManualEnroll[0].width}>
          <UserInfoCell deepLink={this.props.deepLink} user={user} />
        </Table.Cell>
        <Table.Cell width={columnOptionsManualEnroll[1].width}>
          <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
        </Table.Cell>
        <Table.Cell width={columnOptionsManualEnroll[2].width}>
          <Tooltip target={<TextTruncate>{user.title}</TextTruncate>} content={user.title} />
        </Table.Cell>
        <Table.Cell width={columnOptionsManualEnroll[3].width}>
          {user.joinedDate != null
            ? dateTimeUtils.formatDate(user.joinedDate)
            : dateTimeUtils.formatDate(user.createDate)}
        </Table.Cell>
        {!hideUserRemoveColumn && this.renderRemoveButton(user.id)}
      </>
    );
  };

  renderRemoveButton = (userId) => {
    const { isReadOnly, onRemoveMemberClick, selectedItemIds } = this.props;

    return (
      <Table.Cell className="align-right-groups" width={columnOptionsManualEnroll[4].width}>
        <Tooltip
          position="top center"
          hideOnScroll
          showAlways
          disabled={selectedItemIds.includes(userId) || isReadOnly}
          target={
            <MinusButton
              onClick={() => onRemoveMemberClick(userId)}
              isDisabled={selectedItemIds.includes(userId) || isReadOnly}
            />
          }
          content="Remove"
        />
      </Table.Cell>
    );
  };

  noResultsContent = () => {
    if (this.props.isAutoEnrollment) {
      return (
        <NoResults
          title="No Members"
          description="Select some enrollment rules to preview members here"
          iconClassName="fal fa-user"
        />
      );
    }
    if (this.props.isGroupCreation) {
      return <NoSearchResults />;
    }

    if (!isEmpty(this.props.search)) {
      return <NoSearchResults />;
    }

    if (isEmpty(this.props.appliedUserFilter)) {
      return (
        <NoResults
          title="You don't have any members"
          description="Looks like you don't have any members yet. Add some using the button below."
          iconClassName="fal fa-user"
        >
          {this.props.addMembersBtn}
        </NoResults>
      );
    }
    return <NoResults title="No Members found" iconClassName="fal fa-user" />;
  };

  getFilterForm = () => <UsersFilterForm />;

  renderWithoutHeader = () => {
    const {
      users,
      usersCount,
      isLoading,
      loadPage,
      filter,
      selectedItemIds,
      updateSelectedItems,
      rulesEngineOnCosmos,
    } = this.props;

    return (
      <ListViewBase
        className="user-selection-list"
        viewType={ViewType.LIST}
        columnOptions={getColumnOptionsAutoEnroll(rulesEngineOnCosmos)}
        loadPage={loadPage}
        itemsAmount={usersCount}
        isLoading={isLoading}
        items={users}
        withSelection={false}
        withFooter={!rulesEngineOnCosmos}
        filter={filter}
        noResultsContent={this.noResultsContent()}
        buildTableBody={this.buildTableBodyAutoEnroll}
        sortingColumnName={this.defaultSortingColumnName}
        sortingDirection={SortingDirection.Descending}
        selectedItemIds={selectedItemIds}
        updateSelectedItems={updateSelectedItems}
      />
    );
  };

  renderWithHeader = () => {
    const {
      users,
      usersCount,
      loadPage,
      appliedUserFilter,
      filterOptions,
      setUserFilter,
      resetUserFilter,
      isLoading,
      updateSelectedItems,
      selectedItemIds,
      getFilterOptions,
      search,
      onSearchChanged,
      hideUserRemoveColumn,
      isReadOnly,
      setReloadListItems,
      disableSearch,
    } = this.props;

    return (
      <ItemsView
        className="user-selection-list"
        viewType={ViewType.LIST}
        columnOptions={columnOptionsManualEnroll}
        getData={loadPage}
        itemsInlineCount={usersCount}
        isLoading={isLoading}
        items={users}
        buildTableBody={(user) => this.buildTableBodyManualEnroll(user, hideUserRemoveColumn)}
        onSelectedListItemsChanged={updateSelectedItems}
        renderFilterForm={this.getFilterForm}
        filterOptionsLoading={filterOptions.isLoading}
        appliedFilter={appliedUserFilter}
        filterOptions={filterOptions}
        getFilterOptions={getFilterOptions}
        applyFilter={setUserFilter}
        resetFilter={resetUserFilter}
        sortingColumnName={this.defaultSortingColumnName}
        sortingDirection={SortingDirection.Descending}
        noResultsContent={this.noResultsContent()}
        selectedIds={selectedItemIds}
        isSelectDisabled={() => isReadOnly}
        renderSearch={() => (
          <SearchInput
            placeholder="Search for Users..."
            defaultValue={search}
            onChange={onSearchChanged}
            disabled={disableSearch}
          />
        )}
        setReloadListItems={setReloadListItems}
        listViewRtnEvents={membersListViewRtnEvents}
      />
    );
  };

  render() {
    return this.props.renderHeaderSection ? this.renderWithHeader() : this.renderWithoutHeader();
  }
}

GroupUsersList.propTypes = {
  isAutoEnrollment: PropTypes.bool,
  isGroupCreation: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  addMembersBtn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  renderHeaderSection: PropTypes.bool.isRequired,
  filter: PropTypes.object,
  users: PropTypes.array.isRequired,
  usersCount: PropTypes.number.isRequired,
  loadPage: PropTypes.func.isRequired,
  selectedItemIds: PropTypes.array,
  updateSelectedItems: PropTypes.func,
  onSelectedUsersChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  appliedUserFilter: PropTypes.object,
  filterOptions: PropTypes.object,
  setUserFilter: PropTypes.func,
  resetUserFilter: PropTypes.func,
  getFilterOptions: PropTypes.func,
  search: PropTypes.string,
  onSearchChanged: PropTypes.func,
  hideUserRemoveColumn: PropTypes.bool,
  setReloadListItems: PropTypes.func,
  deepLink: PropTypes.bool,
  rulesEngineOnCosmos: PropTypes.bool,
};
