import { FC, useEffect, useState } from "react";
import intersection from "lodash/intersection";
import { RolePermissions, SortingDirection } from "../../../../enums";
import Strings from "../../../../enums/strings";
import { NotifyStepSettings, GroupInfo, PacksContextItem } from "../../../../interfaces";
import { Filters } from "../../../../utils/queryUtils";
import { GroupListStep } from "../packIssueGroupLicenseModal/steps";
import { RenderModalActionsType } from "components/assignmentModals/types";
import PackIssueLicenseToPeopleModal from "../PackIssueLicenseToPeopleModal";

export interface PackIssueLicenseToGroupModalProps {
  showModal: boolean;
  itemsPacks: PacksContextItem[];
  groupsAmount: number;
  isListLoading: boolean;
  isStepLoading: boolean;
  groups: GroupInfo[];
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: Filters,
  ) => void;
  onSecondStep: (selectedIds: number[]) => Promise<void>;
  onConfirm: (notificationSettings?: NotifyStepSettings) => Promise<void>;
  onCancel: () => void;
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  setReloadListItems: (reloadItems: (enableSorting: boolean) => void) => void;
  setIssueLicensePackIds: (ids: number[]) => void;
}

export const PackIssueGroupLicenseModal: FC<PackIssueLicenseToGroupModalProps> = (
  props: PackIssueLicenseToGroupModalProps,
) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { showModal, groupsAmount, isListLoading, groups, loadPage, setReloadListItems } = props;

  useEffect(() => {
    if (!showModal) {
      setSelectedIds([]);
    }
  }, [showModal]);

  const renderGroupsStep = (renderModalActions: RenderModalActionsType) => (
    <GroupListStep
      header={Strings.modalTitles.issueLicense}
      renderModalActions={renderModalActions}
      loadPage={loadPage}
      isLoading={isListLoading}
      groups={groups}
      groupsAmount={groupsAmount}
      onSelectedGroupsChanged={setSelectedIds}
      selectedGroupIds={selectedIds}
      hasPermissionPredicate={(userPermissions) =>
        intersection(userPermissions, [RolePermissions.GroupsView]).length > 0
      }
      setReloadListItems={setReloadListItems}
    />
  );

  return (
    <PackIssueLicenseToPeopleModal
      className={"pack-group-license-modal"}
      selectedIds={selectedIds}
      renderFirstStep={renderGroupsStep}
      licenseConfirmationData={{
        groupIds: selectedIds,
        userIds: [],
      }}
      {...props}
    />
  );
};

export default PackIssueGroupLicenseModal;
