import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { FC } from "react";

import styles from "./userPerformanceDrilldown.module.scss";
import { ColumnOption } from "interfaces/columnOptions";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { SelectedView, UserAssetTypes, UserReportDetailsWithRoute } from "./types";

import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { useQuery } from "@tanstack/react-query";
import { getUserDetailsQuery } from "./Queries/queries";
import { Link } from "react-router-dom";

export interface Props {
  type: UserAssetTypes;
  view: SelectedView;
  userId: number;
  filter: PerformanceFilter;
}

const columnOptions: ColumnOption<UserReportDetailsWithRoute>[] = [
  {
    name: "TITLE",
    width: 5,
    isSortable: false,
    render: (entity: UserReportDetailsWithRoute) => {
      return (
        <Link to={`/${entity.Route}/${entity.Id}`} className={styles.linkButton}>
          <Tooltip target={<TextTruncate>{entity.Title}</TextTruncate>} content={entity.Title} />
        </Link>
      );
    },
  },
  {
    name: "DATE",
    width: 5,
    isSortable: false,
    render: (entity: UserReportDetailsWithRoute) => {
      return <div>{dateTimeUtils.formatDate(entity.Date)}</div>;
    },
  },
  {
    name: "TIME",
    width: 5,
    isSortable: false,
    render: (entity: UserReportDetailsWithRoute) => {
      return <div>{entity.Time}</div>;
    },
  },
];

export const UserPerformanceDrilldown: FC<Props> = ({ type, userId, view, filter }) => {
  const detailsQuery = useQuery({
    queryKey: ["detailsQuery", filter, userId, type, view],
    queryFn: getUserDetailsQuery,
    enabled: type !== undefined,
  });

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>{type}</h2>
      </div>
      <RequestStatusRenderer state={detailsQuery.status}>
        <GenericPerformanceList rows={detailsQuery.data!} columns={columnOptions} filter={filter} key="key" />
      </RequestStatusRenderer>
    </div>
  );
};
