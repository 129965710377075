import React, { useEffect } from "react";
import moment, { Moment, unitOfTime } from "moment";
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import DatePicker from "../datePicker/DatePicker";
import { LicenseType } from "./types/LicenseType";
import LicenseDurationType from "../../enums/licensing/licenseDurationType";
import { LicenseTypes } from "../../enums/licensing/licenseType";

import "./licensePicker.scss";
import { DropdownWrapper } from "components/dropdowns";

export interface LicensePickerProps {
  options: LicenseType[];
  preselectedOptionId?: number;
  preselectedExpirationDate?: Date;
  applyOnEditMinDateValidationRule?: boolean;
  onOptionChange?: (optionId?: number) => void;
  onExpirationDateChange?: (endDate?: Date) => void;
  verticalPosition?: "top" | "bottom";
  popupPosition?: string;
}

const LicensePicker: React.FC<LicensePickerProps> = ({ verticalPosition = "bottom", ...props }) => {
  const [selectedOption, setSelectedOption] = React.useState<null | LicenseType>(null);

  const [expirationDate, setExpirationDate] = React.useState<null | Moment>(
    props.preselectedExpirationDate ? moment(props.preselectedExpirationDate) : null,
  );

  const defaultDurationType: unitOfTime.DurationConstructor = "M";
  const [minDate, setMinDate] = React.useState<Moment>(moment().add(1, defaultDurationType));
  const maxDate: Moment = moment().add(5, "y"); //maxDate is 5 years due to req

  const dropdownOptions: DropdownItemProps[] = props.options.map((option) => {
    return {
      text: option.title,
      value: option.id,
    };
  });

  const { onOptionChange, onExpirationDateChange } = props;

  useEffect(() => {
    const currentSelectedOption = props.preselectedOptionId
      ? props.options.find((o) => o.id === props.preselectedOptionId)
      : null;
    setSelectedOption(currentSelectedOption ? currentSelectedOption : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preselectedOptionId]);

  useEffect(() => {
    onOptionChange && onOptionChange(selectedOption?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpirationDate(getDate(selectedOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    onExpirationDateChange && onExpirationDateChange(expirationDate?.toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationDate]);

  const getDate = (option?: null | LicenseType): null | Moment => {
    //custom date selection
    if (!option?.duration) {
      const preselectedExpirationDate = props.preselectedExpirationDate;
      // If preselectedExpirationDate is before minDate and they are in different months, page will crash
      // set null until https://github.com/arfedulov/semantic-ui-calendar-react/issues/202 is fixed or other calendar module is used
      if (preselectedExpirationDate && moment(preselectedExpirationDate).isAfter(minDate, "day")) {
        if (props.applyOnEditMinDateValidationRule) {
          setMinDate(moment().add(1, "d"));
        }
        return moment(preselectedExpirationDate);
      } else {
        return null;
      }
    }

    return moment().add(option?.duration, getDurationType(option?.durationType));
  };

  const getDurationType = (durationType?: LicenseDurationType): unitOfTime.DurationConstructor => {
    switch (durationType) {
      case LicenseDurationType.Days:
        return "d";
      case LicenseDurationType.Months:
        return "M";
      default:
        return defaultDurationType;
    }
  };

  const handleOptionChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    let selected = props.options.find((item) => item.id === data.value);
    if (selected) {
      setSelectedOption(selected);
      onOptionChange && onOptionChange(selected.id);
    }
  };

  const handleDateChange = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    let newDate = moment(new Date(data.value + " " + moment().local().format("HH:mm:ss")));
    const isDateValid = newDate.isAfter(minDate, "day") && newDate.isSameOrBefore(maxDate, "day");
    setExpirationDate(isDateValid ? newDate : minDate);
  };

  const shouldRenderExpirationDate = (option: LicenseType | null) =>
    option && option.id !== LicenseTypes.Trial && option.duration === undefined;

  const renderExpirationDate = () => {
    return (
      <div className="license-expiration-date">
        <span className="date-label">Date</span>
        <DatePicker
          value={expirationDate ? expirationDate.format("MM/DD/YYYY") : ""}
          onChangeDate={handleDateChange}
          disabled={!selectedOption || selectedOption.duration !== undefined}
          minDate={minDate}
          initialDate={minDate}
          marked={expirationDate ? [] : [minDate]}
          maxDate={maxDate}
          placeholder="Select"
          verticalPosition={verticalPosition}
          popupPosition={props.popupPosition}
        />
      </div>
    );
  };

  return (
    <div className="license-row">
      <>
        <div className="license-type">
          <span className="required-asterisk">Expiration</span>
          <DropdownWrapper
            fluid
            blur
            selection
            value={selectedOption?.id}
            handleOptionChange={handleOptionChange}
            items={dropdownOptions}
            className="field"
            multiple={false}
            clearable={false}
            data-testid="license-picker"
            placeholder="Select Expiration"
          />
        </div>
        {shouldRenderExpirationDate(selectedOption) && renderExpirationDate()}
      </>
    </div>
  );
};

export default LicensePicker;
