import React from "react";
import { isEmpty } from "lodash";

import Restricted from "../../../Application/Restricted";
import { RolePermissions } from "../../../../enums";
import { MultiStateButton } from "../../../../components/buttons/multiStateButton/MultiStateButton";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";

interface AddUserButtonProps {
  selectedUsers: number[];
  createPermissions: RolePermissions[];
  onCreateUser: () => void;
  onImportUsers: () => void;
}

const AddUserButton: React.FC<AddUserButtonProps> = ({
  selectedUsers,
  createPermissions,
  onCreateUser,
  onImportUsers,
}) => {
  const getMenuItems = () => {
    return [
      {
        icon: "user-plus",
        text: "Manually Create",
        onClick: onCreateUser,
      },
      {
        icon: "file-import",
        text: "Import CSV",
        onClick: onImportUsers,
      },
    ];
  };

  if (isEmpty(selectedUsers)) {
    return (
      <Restricted
        permissions={createPermissions}
        renderContent={(hasAnyPermission) => (
          <RestrictedByTooltip hasPermission={hasAnyPermission} className="create-button user">
            <MultiStateButton
              id="add-users"
              trigger="Add Users"
              simple
              items={getMenuItems()}
              disabled={!hasAnyPermission}
            />
          </RestrictedByTooltip>
        )}
      />
    );
  }
  return null;
};

export default AddUserButton;
