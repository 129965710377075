import PackAccountItems from "components/assignmentModals/groupTemplatePacksConfirmationModal/packAccountItems/PackAccountItems";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { ModalWithStepsStep } from "components/modal/ModalWithSteps";

export interface PacksLicensingModalStepProps extends ModalWithStepsStep {
  selectedIds: number[];
  packMap: PackMap;
  setIsDisabled: (value: boolean) => void;
}

export const PacksLicensingModalStep: React.FC<PacksLicensingModalStepProps> = ({
  packMap,
  selectedIds,
  setIsDisabled,
}) => {
  return <PackAccountItems selectedIds={selectedIds} packMap={packMap} setIsDisabled={setIsDisabled} isEdit />;
};
