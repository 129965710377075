import { TagsEnum } from "../../../../../../interfaces/TagsEnum";
import { FilterEntity, FiltersMap } from "../../../../../../utils/filterUtils";
import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { LandingPagesFilters, LandingPagesFiltersEnum } from "../../types/models";
import { setError, setFilterOptions, setIsLoading } from "../slices/landingPagesFiltersSlice";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import objUtils from "../../../../../../utils/objectUtils";
import { Tag } from "../../../../../../interfaces/Tag";

const mapFilterOption = <T>(
  filterOptions: FiltersMap,
  data: T[],
  propertyName: string,
  dataItemMapping: (item: T) => FilterEntity,
) => {
  return {
    ...filterOptions,
    [propertyName]: data.map((item) => dataItemMapping(item)),
  };
};

export const getFilterOptions = () => {
  let filterOptions: FiltersMap = {};

  const tagToFilterMap: { [key in TagsEnum]?: string } = {
    [TagsEnum.Label]: LandingPagesFiltersEnum.Labels,
    [TagsEnum.SoftwareApplication]: LandingPagesFiltersEnum.SoftwareApplications,
  };

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    let result: LandingPagesFilters;
    try {
      const response = await dataService.threatDefence.getTags();
      result = response.data;
      for (let [key, value] of objUtils.typedEntries<TagsEnum, Tag[]>(result)) {
        if (tagToFilterMap[key]) {
          filterOptions = mapFilterOption(filterOptions, value, tagToFilterMap[key]!, (item: Tag) => ({
            text: item.title,
            value: item.id,
          }));
        }
      }

      dispatch(setFilterOptions(filterOptions));
    } catch (error: any) {
      dispatch(setError(error as Error));
    }

    dispatch(setIsLoading(false));
  };
};
