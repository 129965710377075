import { Dispatcher } from "interfaces";
import { createDispatcher } from "../dispatcher";
import * as peopleEventTypes from "../events/people/peopleEvents";
import { IMapping } from "./types";

/* istanbul ignore next */
export const templateMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatchTaskOperationSuccess } = createDispatcher(dispatcher);
  const payloadMapping = (data: { MessageId: string }) => ({
    messageId: data.MessageId,
  });
  mapping[peopleEventTypes.ApproveAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[peopleEventTypes.DeclineAccountTemplateSuccess] = dispatchTaskOperationSuccess(payloadMapping);
  mapping[peopleEventTypes.DeleteTemplateGroupSuccess] = dispatchTaskOperationSuccess(payloadMapping);
};
