import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import cn from "classnames";

function Segment(props) {
  const { to, label, className, onClick, disabled } = props;

  return (
    <>
      {disabled ? (
        <span style={{ cursor: "pointer" }} className={cn(className, "segment")}>
          {label}
        </span>
      ) : (
        <NavLink
          end
          to={to}
          className={({ isActive }) => cn(className, "segment", { "active-segment": isActive })}
          onClick={onClick}
        >
          {label}
        </NavLink>
      )}
    </>
  );
}

Segment.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Segment;
