import { SortingDirection } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { AppDispatch, AppThunk } from "features/Application/globaltypes/redux";
import { DistributedOpUpdateParams } from "interfaces";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { pluralize } from "utils/stringUtils";
import { fetchBegin, fetchFailure, fetchSuccess } from "../TemplatePacksSlice";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const getPacks =
  (id: number, skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getPacks(id, {
        top,
        skip,
        sortBy,
        sortOrder,
      });

      const recordsCount = Number.parseInt(result.headers[countHeaderName]);
      dispatch(
        fetchSuccess({
          items: result.data,
          totalCount: recordsCount,
        }),
      );
    } catch (error) {
      dispatch(fetchFailure(error as Error));
    }
  };

export const removePacks = (templateId: number, packIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    if (packIds.length === 0) return;

    const getOperationProps = async () => {
      const data = await DataService.groupTemplates.removePacks(templateId, packIds);
      return data;
    };

    const isSingle = packIds.length === 1;

    const updateParams: DistributedOpUpdateParams = {
      id: "RemovePacksFromTemplateAccounts",
      title: `Removing ${pluralize("pack", packIds.length)} from template`,
      getOperationProps,
      successTransientMessage: `${pluralize("Pack", packIds.length)} ${isSingle ? "has" : "have"} been removed from template successfully`,
      failureTransientMessage: `Removal ${pluralize("pack", packIds.length)} from template failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
