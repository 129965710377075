import { concat, flatMap, some, uniq } from "lodash";

import {
  NotifyStepSettings,
  PriorityLevel,
  DistributedOpUpdateParams,
  ContentPriorityLevel,
  AddOperationParamsDistributedOp,
} from "../../../../../interfaces";
import { AppDispatch, AppThunk, RootState } from "../../../../Application/globaltypes/redux";
import {
  ContentType,
  EntityToPeopleAssignments,
  EntityToPeopleRemovePriorities,
  EntityToPeopleUnAssignments,
  PeopleType,
  EntityToContentRemovePriorities,
} from "../../types";
import {
  toPeopleAssignmentsModel,
  toPeopleRemovePriorityModel,
  toPeopleUnAssignmentsModel,
  toContentRemovePriorityModel,
  getDueDate,
  getDaysToComplete,
  getFixedDueDate,
} from "../../utils/peopleAssignmentUtils";
import backgroundTask, { UpdateFeatureDistributedOpProps } from "../../../../BackgroundTasks/backgroundTask";
import { addOperationDistributedOp } from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { notificationReceived, sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import {
  assignPeopleToContentDistributed,
  editPriority,
  unassignPeopleDistributed as unassignPeopleDistributedTask,
  removePeoplePrioritiesDistributed as removePeoplePrioritiesDistributedTask,
  unassignContentDistributed as unassignContentDistributedTask,
  removeContentPrioritiesDistributed as removeContentPrioritiesDistributedTask,
  assignContentToPeopleDistributed,
  ContentToPeopleCommonProps,
} from "../../backgroundTasks/backgroundTasks";
import objUtils from "../../../../../utils/objectUtils";
import { PacksContextState } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/state/slices/packsContextSlice";
import { AssignmentPeopleContext, PriorityLevels } from "../../../../../enums";

const getAssignmentItems = (
  packItems: PacksContextState,
  priorityLevels: PriorityLevel[],
  contentType: ContentType,
) => {
  const contentTypeLower = contentType.toLowerCase();

  return priorityLevels.map((priorityItem) => {
    let packIds: number[];
    if (contentTypeLower === ContentType.Flow) {
      packIds = uniq([
        ...packItems.items.filter((p) => p.flowIds.indexOf(priorityItem.id) > -1).map((p) => p.packId!),
        ...packItems.flowAssignments.filter((p) => p.flowId === priorityItem.id).flatMap((p) => p.packIds),
      ]);
    } else {
      packIds = packItems.items
        .filter((p) => p.type.toLowerCase() === contentTypeLower && p.id === priorityItem.id.toString())
        .map((p) => p.packId!);
    }

    return { ...priorityItem, packIds };
  });
};

export const assignPeopleDistributed = (
  priorityLevels: PriorityLevel[],
  contentType: ContentType,
  people: { [key in PeopleType]?: number[] },
  useFlexibleDueDate: boolean,
  notificationSettings?: NotifyStepSettings,
): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!some(priorityLevels)) {
      return;
    }

    const packItems = getState().licensing.contentPacks;
    const assignmentItems = getAssignmentItems(packItems, priorityLevels, contentType);

    let assignments: EntityToPeopleAssignments[] = [];

    for (const [key, value] of objUtils.typedEntries<PeopleType, number[]>(people)) {
      assignments = concat(
        assignments,
        flatMap(value, (userId) =>
          assignmentItems.map((item) =>
            toPeopleAssignmentsModel(userId, item, key, contentType, item.packIds, useFlexibleDueDate),
          ),
        ),
      );
    }
    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };
    const action = (params: DistributedOpUpdateParams) =>
      backgroundTask.updateEntityDistributedOp(params, updateFeatureProps);

    await assignPeopleToContentDistributed({
      action: action,
      contentType: contentType,
      items: assignments,
      groupsLength: people.group?.length,
      usersLength: people.user?.length,
      notificationSettings,
    });
  };
};

const mapToUnassignment = (
  ids: number[],
  type: AssignmentPeopleContext,
  contentIds: number[],
  contentType: ContentType,
) => {
  return flatMap(ids, (userId) => contentIds.map((id) => toPeopleUnAssignmentsModel(userId, id, type, contentType)));
};

const mapToPeopleRemovePriority = (
  ids: number[],
  type: AssignmentPeopleContext,
  contentIds: number[],
  contentType: ContentType,
) => {
  return flatMap(ids, (userId) => contentIds.map((id) => toPeopleRemovePriorityModel(userId, id, type, contentType)));
};

const mapToContentRemovePriority = (
  ids: number[],
  type: AssignmentPeopleContext,
  contentIds: number[],
  contentType: ContentType,
) => {
  return flatMap(ids, (userId) => contentIds.map((id) => toContentRemovePriorityModel(userId, id, type, contentType)));
};

export const unassignPeopleDistributed = (
  contentIds: number[],
  contentType: ContentType,
  people: { [key in AssignmentPeopleContext]?: number[] },
): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch) => {
    if (!some(contentIds)) {
      return;
    }

    let entities: EntityToPeopleUnAssignments[] = [];

    for (const [key, value] of objUtils.typedEntries<AssignmentPeopleContext, number[]>(people)) {
      entities = concat(entities, mapToUnassignment(value, key, contentIds, contentType));
    }

    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };

    await unassignPeopleDistributedTask({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      contentType: contentType,
      items: entities,
      groupsLength: people.group?.length,
      usersLength: people.user?.length,
    });
  };
};

export const removePeoplePrioritiesDistributed = (
  contentIds: number[],
  contentType: ContentType,
  people: { [key in AssignmentPeopleContext]?: number[] },
): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch) => {
    if (!some(contentIds)) {
      return;
    }

    let entities: EntityToPeopleRemovePriorities[] = [];

    for (const [key, value] of objUtils.typedEntries<AssignmentPeopleContext, number[]>(people)) {
      entities = concat(entities, mapToPeopleRemovePriority(value, key, contentIds, contentType));
    }

    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };

    await removePeoplePrioritiesDistributedTask({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      contentType: contentType,
      items: entities,
      groupsLength: people.group?.length,
      usersLength: people.user?.length,
    });
  };
};

export const assignContentDistributed = (props: ContentToPeopleCommonProps<EntityToPeopleAssignments>): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };

    await assignContentToPeopleDistributed({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      ...props,
    });
  };
};

export const unassignContentDistributed = (
  peopleId: number,
  peopleType: AssignmentPeopleContext,
  content: { [key in ContentType]?: number[] },
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    let entities: EntityToPeopleUnAssignments[] = [];

    for (const [key, value] of objUtils.typedEntries<ContentType, number[]>(content)) {
      entities = concat(entities, mapToUnassignment([peopleId], peopleType, value, key));
    }

    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };

    await unassignContentDistributedTask({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      peopleType: peopleType,
      items: entities,
    });
  };
};

export const removeContentPrioritiesDistributed = (
  peopleId: number,
  peopleType: AssignmentPeopleContext,
  content: { [key in ContentType]?: number[] },
): AppThunk => {
  return async (dispatch: AppDispatch) => {
    let entities: EntityToContentRemovePriorities[] = [];

    for (const [key, value] of objUtils.typedEntries<ContentType, number[]>(content)) {
      entities = concat(entities, mapToContentRemovePriority([peopleId], peopleType, value, key));
    }

    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
    };

    await removeContentPrioritiesDistributedTask({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      peopleType: peopleType,
      items: entities,
    });
  };
};

function dispatchAssignmentAction(
  assignmentItems: {
    contentId: number;
    contentType: string;
    peopleId: number;
    peopleType: PeopleType;
    priority: PriorityLevels;
    dueDate: number | undefined;
    daysToComplete: number | undefined;
    fixedDueDate: string | undefined;
  }[],
): AppThunk<Promise<void>> {
  return async (dispatch: AppDispatch) => {
    const updateFeatureProps: UpdateFeatureDistributedOpProps = {
      addOperation: (addOperationParams: AddOperationParamsDistributedOp) =>
        dispatch(addOperationDistributedOp(addOperationParams)),
      sendTransientNotification: (payload: any) => dispatch(notificationReceived(payload)),
    };

    await editPriority({
      action: (params) => backgroundTask.updateEntityDistributedOp(params, updateFeatureProps),
      items: assignmentItems,
    });
  };
}

export const editContentPeoplePriority = (
  priorityLevels: PriorityLevel[],
  contentType: ContentType,
  contentId: number,
  peopleType: PeopleType,
  useFlexibleDueDate: boolean,
): AppThunk<Promise<void>> => {
  const assignmentItems = priorityLevels.map((priorityItem) => {
    return {
      contentId: contentId,
      contentType: contentType[0].toUpperCase() + contentType.slice(1),
      peopleId: priorityItem.id,
      peopleType: peopleType,
      priority: priorityItem.priorityLevel,
      dueDate: getDueDate(useFlexibleDueDate, priorityItem),
      daysToComplete: getDaysToComplete(useFlexibleDueDate, priorityItem),
      fixedDueDate: getFixedDueDate(useFlexibleDueDate, priorityItem),
    };
  });

  return dispatchAssignmentAction(assignmentItems);
};

export const editPeopleContentPriority = (
  priorityLevels: ContentPriorityLevel[],
  peopleId: number,
  peopleType: PeopleType,
  useFlexibleDueDate: boolean,
): AppThunk => {
  const assignmentItems = priorityLevels.map((priorityItem) => {
    return {
      contentId: priorityItem.id,
      contentType: priorityItem.contentType,
      peopleId: peopleId,
      peopleType: peopleType,
      priority: priorityItem.priorityLevel,
      dueDate: getDueDate(useFlexibleDueDate, priorityItem),
      daysToComplete: getDaysToComplete(useFlexibleDueDate, priorityItem),
      fixedDueDate: getFixedDueDate(useFlexibleDueDate, priorityItem),
    };
  });
  return dispatchAssignmentAction(assignmentItems);
};
