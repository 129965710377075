import axios from "axios";
import { TrendingFlow } from "../Dashboard/Overview/TrendingFlows";
import { TrendingAsset } from "../Dashboard/Overview/TrendingAssets";
import { FlowGoalsEndpoint } from "../Dashboard/Overview/FlowGoals";
import { FlowOverview } from "features/Library/Flows/types/models";
import { FlowGoalQuery } from "../Dashboard/Overview/OverviewReport";

export const dashboardReportEndpoints = {
  trendingFlows: "/api/reports/v2/overview/account/trending/flows",
  trendingAssets: "/api/reports/v2/overview/account/trending/assets",
  flowGoals: "/api/reports/v2/overview/account/flows/goals",
  flowData: "/api/v4/flows",
};

export const fetchTrendingFlowsData = () => {
  return axios.get<TrendingFlow[]>(dashboardReportEndpoints.trendingFlows);
};

export const fetchTrendingAssetsData = () => {
  return axios.get<TrendingAsset[]>(dashboardReportEndpoints.trendingAssets);
};

export const fetchFlowGoalsData = (ids: FlowGoalQuery, dateTo: string) => {
  return axios.post<FlowGoalsEndpoint>(
    dashboardReportEndpoints.flowGoals,
    {
      ...ids,
    },
    { params: { dateTo } },
  );
};

export const fetchFlowData = (items: number[], top: number, skip: number, showPurchased: boolean) => {
  const params = new URLSearchParams();
  items.map((id) => params.append("goalObjectiveIds", id.toString()));
  params.append("top", top.toString());
  params.append("skip", skip.toString());
  params.append("showPurchased", showPurchased.toString());
  return axios.get<FlowOverview[]>(dashboardReportEndpoints.flowData, {
    params: params,
  });
};
