import axios, { AxiosResponse } from "axios";
import {
  Customer,
  GetAvailableCustomersRequest,
  GetTemplateInfoResponse,
  TemplateOverviewResponse,
  Pack,
  TemplateGroupPack,
  GetPacksRequest,
} from "./types";
import { isEmpty } from "lodash";
import { PageOfItems } from "interfaces";
import { TemplateConfigurationView } from "../state/GroupTemplateSlice";
import { DistributedOperationResult } from "types";
import { GetPagedDataRequestV2 } from "interfaces/getPagedDataRequest";
import KnownHttpHeaders from "features/Application/services/knownHttpHeaders";
import { ContentResponse } from "features/Application/services/dataServices/contentDataService";
import { serializeArrayAndFilterNullable } from "utils/queryUtils";

export const groupTemplateDataService = {
  async getTemplatesOverview(request: GetPagedDataRequestV2) {
    const { data, headers } = await axios.get<TemplateOverviewResponse[]>("/api/template-groups", {
      params: request,
    });
    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },
  getTemplateInfo(templateId: number) {
    return axios.get<GetTemplateInfoResponse>(`/api/people/groups/${templateId}/info`);
  },
  updateTemplate(templateId: number, data: TemplateConfigurationView) {
    return axios.put(`/api/groups/${templateId}/info`, {
      ...data,
      isTemplate: true,
    });
  },
  createTemplate(data: TemplateConfigurationView) {
    return axios.post("/api/v2/groups", {
      ...data,
      isTemplate: true,
    });
  },
  deleteTemplate(id: number) {
    return axios.delete(`api/template-groups/${id}`);
  },
  getAvailableCustomers(request: GetAvailableCustomersRequest) {
    return axios.get<PageOfItems<Customer>>("/api/accounts/customers", {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
        searchTerm: !isEmpty(request.term) ? request.term : undefined,
        templateId: request.templateId,
      },
    });
  },
  getCustomers(id: number, request: GetPagedDataRequestV2) {
    return axios.get<PageOfItems<Customer>>(`/api/template-groups/${id}/accounts`, {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
        searchTerm: !isEmpty(request.term) ? request.term : undefined,
      },
    });
  },
  saveCustomers(templateId: number, customers: number[]) {
    return axios.post(`/api/template-groups/${templateId}/accounts`, {
      accountIds: customers,
    });
  },
  getFlows(templateId: number, request: GetPagedDataRequestV2) {
    return axios.get<ContentResponse[]>(`/api/v4/template-groups/${templateId}/flows`, {
      params: {
        ...request,
        term: !isEmpty(request.term) ? request.term : undefined,
        showPurshased: true,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },
  getContent(templateId: number, type: string, request: GetPagedDataRequestV2) {
    return axios.get<ContentResponse[]>(`/api/template-groups/${templateId}/content`, {
      params: {
        ...request,
        term: !isEmpty(request.term) ? request.term : undefined,
        showPurshased: true,
        contentType: type,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },
  getAvailablePacks(id: number, request: GetPacksRequest, signal?: AbortSignal): Promise<AxiosResponse<Pack[]>> {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
      signal: signal,
    };

    return axios.get<Pack[]>(`/api/template-groups/${id}/available-packs`, config);
  },
  getPacks(id: number, request: GetPagedDataRequestV2) {
    return axios.get<Pack[]>(`/api/template-groups/${id}/packs`, {
      params: {
        top: request.top,
        skip: request.skip,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
      },
    });
  },
  async savePacks(templateId: number, groupPacks: TemplateGroupPack[]): Promise<DistributedOperationResult> {
    const response = await axios.post(`/api/template-groups/${templateId}/packs`, { templateGroupPacks: groupPacks });
    return response.data;
  },
  async removePacks(templateId: number, packIds: number[]): Promise<DistributedOperationResult> {
    const response = await axios.delete(`/api/template-groups/${templateId}/packs`, { data: { packIds: packIds } });
    return response.data;
  },

  approve(templateId: number) {
    return axios.post(`/api/template-groups/${templateId}/approve`);
  },

  decline(templateId: number) {
    return axios.post(`/api/template-groups/${templateId}/decline`);
  },
};
