import GenericItemsView from "views/ItemsView/GenericItemsView";
import { Title } from "components/listViewTemplates";
import dateTimeUtils from "utils/dateTimeUtils";
import { CustomerView } from "../types/models";
import { ColumnOption } from "interfaces/columnOptions";
import { noop } from "lodash";
import { SortingDirection } from "enums";
import { NoResults, SearchInput } from "components";
import { useReloadItemViewListItems } from "hooks/useReloadItemViewListItems";
import { Icon, Popup } from "semantic-ui-react";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useEffect } from "react";

type Props = {
  selectedIds?: number[];
  setSelectedIds: (ids: number[]) => void;
  items: CustomerView[];
  isLoading: boolean;
  itemsCount: number;
  getData: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    templateId?: number,
  ) => void;
  onSearchChange: (term: string) => void;
  templateId?: number;
};

export enum Columns {
  Name = "Customer Name",
  Permissions = "Permissions",
  Added = "Added",
  Users = "Users",
}

const columnToParamMap = ((): { [key: string]: string } => {
  return {
    [Columns.Name.toLowerCase()]: "name",
    [Columns.Added.toLowerCase()]: "added",
  };
})();

export const GroupTemplateCustomers = (props: Props) => {
  const { selectedIds, setSelectedIds, items, isLoading, itemsCount, getData, onSearchChange, templateId } = props;

  useEffect(() => {
    return () => {
      onSearchChange("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [setReloadListItems, onSearchChanged] = useReloadItemViewListItems(onSearchChange);

  const loadCustomers = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added,
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    const sortColumn = columnToParamMap[sortingColumnName];
    getData(skip, top, sortColumn, sortingDirection, templateId);
  };

  const columnOptions: ColumnOption<CustomerView>[] = [
    {
      name: Columns.Name,
      width: 6,
      isSortable: true,
      render: (item) => <Title title={item.name} onTitleClick={noop} clamp={1} className="normal-color" />,
    },
    {
      name: Columns.Permissions,
      width: 4,
      isSortable: false,
      render: () => <></>,
    },
    {
      name: Columns.Added,
      width: 3,
      isSortable: true,
      render: (item) => dateTimeUtils.formatDate(item.added),
    },
    {
      name: Columns.Users,
      width: 2,
      isSortable: false,
      render: (item) => item.userCount,
    },
    {
      name: "",
      width: 2,
      isSortable: false,
      isHide: !templateId,
      render: (item) => getIsAddedTooltip(item.isAdded),
    },
  ];

  const getIsAddedTooltip = (isAdded: boolean) => {
    return isAdded ? (
      <Popup
        hideOnScroll
        position="top center"
        size="small"
        className="popup-warning warning"
        content={"This customer is already associated with the template."}
        trigger={<Icon link className="fa fa-exclamation-circle" size="big" />}
      />
    ) : null;
  };

  return (
    <GenericItemsView
      fetchData={loadCustomers}
      items={items}
      isLoading={isLoading}
      dataCount={itemsCount}
      columnOptions={columnOptions}
      isSelectDisabled={(item) => item.isAdded}
      onSelectedItemChanged={setSelectedIds}
      noResultsContent={<NoResults title="You don't have any available customers." iconClassName="building" />}
      renderSearch={() => <SearchInput placeholder="Search for customers" onChange={onSearchChanged} />}
      setReloadListItems={setReloadListItems}
      selectedIds={selectedIds}
      listViewRtnEvents={[rtnEvents.AccountAddedToTemplateGroupSuccess]}
    />
  );
};
