import { FlowGoals } from "features/Library/Flows/types/models";
import { PackTrialPeriod } from "features/Licensing/Packs/types/state";

export enum TemplateStatusEnum {
  Pending,
  Approved,
  Rejected,
}

export interface TemplateOverview {
  name: string;
  id: number;
  publisher: string;
  imageUrl: Nullable<string>;
  users: number;
  customers: number;
  dateCreated: string;
  dateModified: string;
  status: TemplateStatusEnum;
  isOwn: boolean;
}

export interface CustomerView {
  name: string;
  id: number;
  userCount: number;
  added: string;
  isAdded: boolean;
}

export enum TemplatesFiltersEnum {
  DateCreated = "dateCreated",
}

export interface ContentItem {
  id: number;
  publisher: string;
  title: string;
  dateCreated: string;
  dateModified: string;
  isDraft: boolean;
  hasBeenPublished: boolean;
  description: string;
  isPurchased: boolean;
  canBePublished: boolean;
  canBeDeleted: boolean;
}

export interface FlowItem extends ContentItem {
  goals: FlowGoals;
  priorityLevelId: number;
  isInherited: boolean;
  published: boolean;
  dueDate: Nullable<string>;
  canRemove: boolean;
  isEditable: boolean;
  canAutoStart: boolean;
}

export interface VideoItem extends ContentItem {
  durationInSeconds: number;
}

export type TemplateContent = FlowItem | VideoItem | ContentItem;

export interface PackView {
  id: number;
  title: string;
  thumbnailImageUrl: string;
  isTrialAllowed: boolean;
  trialPeriod: PackTrialPeriod;
  description: string;
  publisher: string;
  publisherId: number;
  dateAdded?: string;
}

export interface AvailablePacksRequest {
  templateGroupId: number;
  skip: number;
  orderBy?: string;
  searchTerm?: string;
}

export enum PackColumns {
  Name = "Pack Name",
  Licenses = "License Count",
  Added = "Issued Date",
  Expiration = "Expiration Date",
}
