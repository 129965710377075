import { AppDispatch, AppThunk, RootState } from "../../../../../Application/globaltypes/redux";
import { EntityType } from "../../types";
import { flowInspectorTypeSelectSelector } from "../../selectors";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/flowDesignerItemsToDropSlice";
import { ExtrasTypes } from "../../Extras/types";
import {
  fetchAssessmentsItemsV2,
  fetchEmailsItemsV2,
  fetchEventsItemsV2,
  fetchMessagesItemsV2,
  fetchPdfsItemsV2,
  fetchVideoItemsV2,
  fetchSurveyItemsV2,
  ItemsToDropPayload,
} from "../../../../../../utils/fetchItemsToDrop";

export const fetchItemsToDropV2 = (payload: ItemsToDropPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const map: { [key in EntityType]?: () => AppThunk } = {
      Video: () => fetchVideoItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      Survey: () => fetchSurveyItemsV2(payload, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector, true),
      Assessment: () =>
        fetchAssessmentsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      Pdf: () => fetchPdfsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      ExternalEvent: () =>
        fetchEventsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      Email: () => fetchEmailsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      Message: () =>
        fetchMessagesItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, flowInspectorTypeSelectSelector),
      [ExtrasTypes.Extras]: () => fetchExtrasItems(),
    };

    const context = flowInspectorTypeSelectSelector(state);

    if (!context || !map[context]) {
      return;
    } else {
      await dispatch(map[context]!());
    }
  };
};

const fetchExtrasItems = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    dispatch(
      fetchSuccess({
        items: [
          { id: 1, title: "Section Header", publisher: "Next", cardType: ExtrasTypes.SectionHeader },
          { id: -1, title: "End of Flow", publisher: "Next", cardType: ExtrasTypes.FlowEnd },
        ],
        totalCount: 2,
      }),
    );
  };
};
