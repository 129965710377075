import { ContentSegmentationDropdown, SearchInput, TextTruncate } from "components";
import { ContentTypesEnum, PriorityLevels, PublishedStatusTypes, RouteNames, SortingDirection, ViewType } from "enums";
import { ContentType, PeopleType } from "features/Library/PeopleAssignments/types";
import React, { useEffect, useMemo, useState } from "react";
import { fetchTemplateContent } from "../state/thunks/TemplateContentThunk";
import { bindAction } from "interfaces";
import { fetchTemplateContentFilters } from "../state/thunks/TemplateContentFiltersThunk";
import { setSearch, resetAppliedFilter, setAppliedFilter, resetFilter } from "../state/TemplateContentFiltersSlice";
import { reset, resetSelected } from "../state/TemplateContentSlice";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { useReloadItemViewListItems } from "hooks/useReloadItemViewListItems";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ContentItem, FlowItem, TemplateContent, VideoItem } from "../types/models";
import { PublishedStatus } from "components/common/publishedStatus";
import { Tooltip } from "components/common/tooltip";
import { Title } from "components/listViewTemplates";
import { ColumnOption } from "interfaces/columnOptions";
import {
  AssessmentFilterForm,
  FlowFilterForm,
  PdfsFilterForm,
  SurveyFilterForm,
  VideosFilterForm,
} from "components/filterForms";
import FlowsNoResults from "views/library/flows/flowsOverview/FlowsNoResults";
import { PeopleEventCard, PeopleFlowCard } from "components/contentAssignment/cards";
import { CardsViewerItem } from "components/cardsViewer/types";
import { VideoAssetsNoResults } from "views/library/videos/VideoAssetsNoResults/VideoAssetsNoResults";
import PeopleVideoCard from "components/cards/VideoCard/People/PeopleVideoCard";
import { EventFilterForm } from "components/filterForms/EventFilterForm/EventFilterForm";
import EventsNoResults from "views/library/events/Overview/EventsNoResults";
import SurveysNoResults from "views/library/surveys/Overview/SurveysNoResults";
import AssessmentsNoResults from "views/library/assessments/Overview/AssessmentsNoResults";
import PdfsNoResults from "views/library/pdfs/contentAssignment/PdfsNoResults";
import { PeoplePdfCard } from "components/contentAssignment/cards/peoplePdfCard/PeoplePdfCard";
import { isEmpty, noop } from "lodash";
import { FiltersMap } from "utils/filterUtils";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";
import PriorityCell from "components/priorityLevels/priorityCell/PriorityCell";

type Props = PropsFromRedux & {
  templateId: number;
};

export enum Columns {
  Title = "Title", //NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Objective = "Objective",
  PriorityLevel = "Priority Level",
}

export const PeopleContent = (props: Props) => {
  const {
    items,
    isLoading,
    itemsCount,
    resetOverview,
    onSearchChanged,
    applyFilter,
    resetAppliedFilter,
    resetFilterState,
    filters,
    fetchContent,
    fetchFilters,
    templateId,
    areAllLoaded,
  } = props;
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(ViewType.GRID);
  const [gridSortBy, setGridSortBy] = useState<[string, SortingDirection]>([...defaultGridSorting]);
  const isGridView = selectedViewType === ViewType.GRID;
  const [context, setContext] = useState(ContentTypesEnum.Flows);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      resetFilterState();
      resetOverview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetch = (skip: number, top: number, sortBy: string, sortOrder: SortingDirection, fromStart = false) => {
    if (isGridView) {
      fetchGrid(fromStart);
    } else {
      fetchContent(templateId, context, skip, top, sortBy, sortOrder, fromStart);
    }
  };

  const fetchGrid = (fromStart: boolean, sorting?: [string, SortingDirection]) => {
    const [sortBy, sortOrder] = sorting ?? gridSortBy;
    fetchContent(templateId, context, fromStart ? 0 : items.length, 30, sortBy, sortOrder, !fromStart);
  };

  const fetchListView = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Title,
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<Columns>];
    handleFetch(skip, top, sortBy, sortingDirection);
  };

  const [setReloadListItems, handleSearchChange] = useReloadItemViewListItems((term) => {
    onSearchChanged(term);
    if (isGridView) {
      resetOverview();
      fetchGrid(true);
    }
  });

  const onSortChange = (_: React.SyntheticEvent<HTMLElement>, data: { value: [string, SortingDirection] }) => {
    resetOverview();
    setGridSortBy(data.value);
    fetchGrid(true, data.value);
  };

  const onViewTypeChange = (viewType: ViewType) => {
    setSelectedViewType(viewType);
    resetOverview();
    if (viewType === ViewType.GRID) {
      fetchGrid(true);
    }
  };

  const handleApplyFilter = (filter: FiltersMap) => {
    applyFilter(filter);
    resetOverview();
    if (isGridView) {
      fetchGrid(true);
    }
  };

  const handleResetFilter = () => {
    resetAppliedFilter();
    resetOverview();
    if (isGridView) {
      fetchGrid(true);
    }
  };

  const handleFiltersFetch = () => {
    fetchFilters(context);
  };

  const dropdown = (
    <ContentSegmentationDropdown
      onContextChange={(ctx: ContentTypesEnum) => {
        setSelectedViewType((prev) => {
          return contentPropsPovider(ctx, navigate).renderCard ? prev : ViewType.LIST;
        });
        resetOverview();
        resetFilterState();
        setContext(ctx);
      }}
      defaultValue={ContentTypesEnum[context]}
    />
  );

  const {
    getNoResults,
    getColumnOption,
    filterForm: FilterForm,
    renderCard,
  } = useMemo(() => contentPropsPovider(context, navigate), [context, navigate]);
  const noResults = useMemo(
    () => getNoResults(!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)),
    [filters.appliedFilter, filters.search, getNoResults],
  );
  const columnOptions = useMemo(() => getColumnOption() as ColumnOption<TemplateContent>[], [getColumnOption]);

  return (
    <GenericItemsView
      key={context}
      items={items}
      viewType={selectedViewType}
      isLoading={isLoading}
      columnOptions={columnOptions}
      fetchData={fetchListView}
      dataCount={itemsCount}
      customHeaderContent={dropdown}
      renderSearch={() => <SearchInput placeholder={`Search for ${context}`} onChange={handleSearchChange} />}
      // @ts-ignore
      getFilterForm={() => <FilterForm />}
      applyFilter={handleApplyFilter}
      resetFilter={handleResetFilter}
      setReloadListItems={setReloadListItems}
      appliedFilter={filters.appliedFilter}
      filterOptions={filters.filterOptions}
      noResultsContent={noResults}
      filterOptionsLoading={filters.isLoading}
      getFilterOptions={handleFiltersFetch}
      renderCard={renderCard}
      sortOptions={gridSortOptions}
      defaultSortingColumnName={Columns.Title}
      onSortChange={onSortChange}
      onViewTypeChange={onViewTypeChange}
      disableListViewButton={!renderCard}
      doNotLoadPersistentViewType={!renderCard}
      isAllDataLoaded={areAllLoaded}
      onLoad={isGridView ? () => fetchGrid(true) : noop}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { content, filters } = state.people.groupTemplate.content;

  return {
    ...content,
    filters,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetOverview: () => dispatch(reset()),
    resetSelection: bindAction(resetSelected, dispatch),
    fetchContent: bindAction(fetchTemplateContent, dispatch),
    fetchFilters: bindAction(fetchTemplateContentFilters, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetAppliedFilter: bindAction(resetAppliedFilter, dispatch),
    resetFilterState: bindAction(resetFilter, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PeopleContent);

export const columnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  status: "isDraft",
  publisher: "publisher",
  "priority level": "priorityLevelId",
  objective: "goalObjectiveId",
};

const defaultGridSorting = ["dateCreated", SortingDirection.Descending] as const;
export const gridSortOptions = [
  {
    text: "Most Recent",
    value: defaultGridSorting,
    default: true,
  },
  {
    text: "Last Modified",
    value: ["dateModified", SortingDirection.Descending],
  },
  {
    text: "Sorted A-Z",
    value: ["title", SortingDirection.Ascending],
  },
];

/* istanbul ignore next */
const getFlowColumnOptions = (onTitleClick: (id: number) => void): ColumnOption<FlowItem>[] => {
  return [
    {
      name: Columns.Title,
      width: 3,
      isSortable: true,
      render: (item) => (
        <Title title={item.title} className={"normal-color"} onTitleClick={() => onTitleClick(item.id)} />
      ),
    },
    {
      name: Columns.Objective,
      width: 3,
      isSortable: true,
      render: (item) => <ObjectiveLabel objective={item.goals?.objective} />,
    },
    {
      name: Columns.Publisher,
      width: 2,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Status,
      width: 2,
      isSortable: false,
      render: (item) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(item.published)} />
      ),
    },
    {
      name: Columns.PriorityLevel,
      width: 5,
      isSortable: false,
      render: (item) => (
        <PriorityCell
          inherited={item.isInherited}
          peopleType={PeopleType.Group}
          contentType={ContentType.Flow}
          priorityLevel={item.priorityLevelId as PriorityLevels}
          dueDate={item.dueDate}
        />
      ),
    },
  ];
};

/* istanbul ignore next */
export const getColumnOptions = (onTitleClick: (id: number) => void): ColumnOption<ContentItem>[] => {
  return [
    {
      name: Columns.Title,
      width: 4,
      isSortable: true,
      render: (item) => (
        <Title title={item.title} className="normal-color" onTitleClick={() => onTitleClick(item.id)} />
      ),
    },
    {
      name: Columns.Publisher,
      width: 3,
      isSortable: false,
      render: (item) => <Tooltip target={<TextTruncate>{item.publisher}</TextTruncate>} content={item.publisher} />,
    },
    {
      name: Columns.Status,
      width: 6,
      isSortable: false,
      render: (item) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!item.isDraft)} />
      ),
    },
  ];
};

/* istanbul ignore next */
const getContentPropsMap = (navigate: NavigateFunction) => ({
  [ContentTypesEnum.Flows]: {
    filterForm: FlowFilterForm,
    getColumnOption: () => getFlowColumnOptions((id: number) => navigate(`/${RouteNames.contentFlows}/${id}`)),
    renderCard: (item: CardsViewerItem<FlowItem>) => (
      <PeopleFlowCard {...item} peopleType={PeopleType.Group} disablePopupMenu deepLink />
    ),
    getNoResults: (filtered: boolean) => <FlowsNoResults byCriteria={filtered} />,
  },
  [ContentTypesEnum.Videos]: {
    filterForm: VideosFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentVideos}/${id}`)),
    renderCard: (item: CardsViewerItem<VideoItem>) => <PeopleVideoCard {...item} disablePopupMenu deepLink />,
    getNoResults: (filtered: boolean) => <VideoAssetsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.Events]: {
    filterForm: EventFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentEvents}/${id}`)),
    renderCard: (item: CardsViewerItem<ContentItem>) => (
      <PeopleEventCard {...item} peopleType={PeopleType.Group} disablePopupMenu deepLink />
    ),
    getNoResults: (filtered: boolean) => <EventsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.PDFs]: {
    filterForm: PdfsFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentPdfs}/${id}`)),
    renderCard: (item: CardsViewerItem<ContentItem>) => (
      <PeoplePdfCard {...item} peopleType={PeopleType.Group} deepLink />
    ),
    getNoResults: (filtered: boolean) => <PdfsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.Assessments]: {
    filterForm: AssessmentFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentAssessments}/${id}`)),
    getNoResults: (filtered: boolean) => <AssessmentsNoResults filtered={filtered} />,
    renderCard: undefined,
  },
  [ContentTypesEnum.Surveys]: {
    filterForm: SurveyFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentSurveys}/${id}`)),
    getNoResults: (filtered: boolean) => <SurveysNoResults filtered={filtered} />,
    renderCard: undefined,
  },
});

type ContentProps = ReturnType<typeof getContentPropsMap>[keyof ReturnType<typeof getContentPropsMap>];
type ContentMap = {
  [K in ContentTypesEnum]?: ContentProps;
};

const contentPropsPovider = (ctx: ContentTypesEnum, navigate: NavigateFunction): ContentProps => {
  const map: ContentMap = getContentPropsMap(navigate);
  return map[ctx]!;
};
