import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Icon, SemanticICONS } from "semantic-ui-react";

import { ColumnOption } from "../../../../interfaces/columnOptions";
import { bindAction } from "../../../../interfaces/redux";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { selectEngagementPeopleData, selectEngagementPeopleValue } from "../selectors/performanceSelectors";
import { fetchEngagementPeopleData } from "../state/thunks/pdfPerformanceThunk";
import { EngagementPeople, EngagementStatus } from "../types/state";

import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { EMPTY_CELL_CONTENT } from "../../../../utils/columnUtils";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";

import styles from "./PdfPerformanceBody.module.scss";

export type PdfPerformancePeopleProps = {
  pdfId: number;
  flowId?: number;
  filter: PerformanceFilter;
} & PropsFromRedux;

export type EmailStatusColors = "icon-send-complete" | "icon-send-progress" | "icon-send-canceled";

const StatusToString: { [key in EngagementStatus]: string } = {
  [EngagementStatus.Completed]: "Completed",
  [EngagementStatus.NotStarted]: "Not Started",
};

const StatusToIcon: { [key in EngagementStatus]: SemanticICONS } = {
  [EngagementStatus.Completed]: "check circle",
  [EngagementStatus.NotStarted]: "circle outline",
};

const StatusToColor: { [key in EngagementStatus]: EmailStatusColors } = {
  [EngagementStatus.Completed]: "icon-send-complete",
  [EngagementStatus.NotStarted]: "icon-send-canceled",
};

const columnOptions: ColumnOption<EngagementPeople>[] = [
  {
    name: "NAME",
    width: 5,
    isSortable: false,
    render: (entity: EngagementPeople) => (
      <div>
        <Link to={`/${RouteNames.peopleUsers}/${entity.UserId}`} className={styles.linkButton}>
          <Tooltip
            target={
              <TextTruncate>
                {entity.UserFirstName} {entity.UserLastName}
              </TextTruncate>
            }
            content={`${entity.UserFirstName} ${entity.UserLastName}`}
          />
        </Link>
        <div className={styles.department}>
          <Tooltip
            target={
              <TextTruncate>
                {entity.UserDepartment}
              </TextTruncate>
            }
            content={`${entity.UserDepartment}`}
          />
        </div>
      </div>
    ),
  },
  {
    name: "EMAIL",
    width: 5,
    isSortable: false,
    render: (entity: EngagementPeople) => <Tooltip
      target={
        <TextTruncate>
          {entity.UserEmail}
        </TextTruncate>
      }
      content={`${entity.UserEmail}`}
    />,
  },
  {
    name: "DATE VIEWED",
    width: 4,
    isSortable: false,
    render: (entity: EngagementPeople) =>
      entity.DateWatched ? dateTimeUtils.formatDate(entity.DateWatched) : EMPTY_CELL_CONTENT,
  },
  {
    name: "STATUS",
    width: 3,
    isSortable: false,
    render: (entity: EngagementPeople) => (
      <div className={styles.status}>
        <Icon name={StatusToIcon[entity.Status]} className={`${StatusToColor[entity.Status]} ${styles.icon}`} />
        {StatusToString[entity.Status]}
      </div>
    ),
  },
];

export const PdfPerformancePeople: FC<PdfPerformancePeopleProps> = ({
  fetchEngagementPeopleData,
  pdfId,
  peopleDetailsEntities,
  filter,
  flowId,
  test,
}) => {
  useEffect(() => {
    fetchEngagementPeopleData(pdfId, { ...filter, flowId });
  }, [fetchEngagementPeopleData, pdfId, filter, flowId]);

  return (
    <RequestStatusRenderer state={test}>
      {peopleDetailsEntities && (
        <GenericPerformanceList title="People" rows={peopleDetailsEntities} columns={columnOptions} filter={filter} />
      )}
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => ({
  peopleDetailsEntities: selectEngagementPeopleValue(state),
  test: selectEngagementPeopleData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchEngagementPeopleData: bindAction(fetchEngagementPeopleData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PdfPerformancePeople);
