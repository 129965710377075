import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { isEmpty, uniq } from "lodash";
import { bindActionCreators } from "@reduxjs/toolkit";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import Segments from "../../../../components/navigation/segments/Segments";
import { AssignmentPeopleContext, RolePermissions, RouteNames, UsersGroupsContext } from "../../../../enums";
import { DetailsHeader } from "../../../../components/sectionHeader";
import {
  fetchPurchasedPack,
  issuePackLicenseToPeople,
  removePackLicenseFromPeople,
} from "../state/thunks/packPurchasedViewThunk";
import UserLicenses from "./PeopleLicenses/UserLicenses/UserLicenses";
import GroupLicenses from "./PeopleLicenses/GroupLicenses/GroupLicenses";
import { show, hide, setSelectedPeopleIds, resetContext } from "../state/slices/packIssueLicenseModalSlice";
import { resetSearchTerm, setSearchTerm } from "../state/slices/packUsersAvailableForLicensingSlice";
import userListUtils from "../../../../utils/userListUtils";
import PackPerformance from "./Performance/PackPurchasedPerformance";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import Restricted from "../../../Application/Restricted";
import Rights, { hasContentRight, hasGroupRight, hasUserRight } from "../../../../enums/rights";
import PeopleContextWrapper from "./PeopleLicenses/PeopleContextWrapper/PeopleContextWrapper";
import { fetchGroupsAvailableForLicensing } from "../state/thunks/packGroupsAvailableForLicensingThunk";
import PackIssueLicenseToUserModal from "components/assignmentModals/packIssueLicenseModal/packIssueLicenseToUserModal/PackIssueLicenseToUserModal";
import PackIssueLicenseToGroupModal from "components/assignmentModals/packIssueLicenseModal/packIssueLicenseToGroupModal/PackIssueLicenseToGroupModal";
import { RemoveLinkButton } from "components";
import packsDataService from "../services/packsDataService";
import RemoveLicenseConfirmationDialog from "components/packs/removeLicenseConfirmationDialog/RemoveLicenseConfirmationDialog";
import RemoveGroupLicenseConfirmationDialog from "components/packs/removeGroupLicenseConfirmationDialog/RemoveGroupLicenseConfirmationDialog";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import "./packPurchasedViewSegments.scss";

export const availablePacksUrl = `/${RouteNames.availablePacks}`;
const pages = {
  performance: {
    index: 0,
    segment: {
      label: "Performance",
    },
  },
  licensing: {
    index: 1,
    segment: {
      label: "Licensing",
      to: "licensing",
    },
  },
} as const;

export type Props = PropsFromRedux;

export const PackPurchasedViewSegments: React.FC<Props> = ({
  pack,
  issueLicenseModal,
  getPack,
  showIssueLicenseModal,
  setUsersSearchTerm,
  resetUsersSearchTerm,
  hideIssueLicenseModal,
  resetIssueLicenseModalContext,
  getGroups,
  issuePackLicense,
  removePackLicense,
  selectPeople,
}) => {
  const params = useParams();
  const location = useLocation();
  const packId = Number(params.id);

  const licenseRemovalEnabled = !!useFeatureFlag(FeatureFlags.LicenseRemoval);

  useEffect(() => {
    getPack(packId);
    return () => {
      resetIssueLicenseModalContext(AssignmentPeopleContext.Group);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Memorize the original from location (if present)
  // Fixes issue with customer pack report after changing segment
  const fromRef = useRef<string>(location?.state?.from);

  const [usersGroupsContext, setUsersGroupsContext] = useState<UsersGroupsContext>(UsersGroupsContext.Groups);
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<number[]>([]);
  const [issueLicensePackIds, setIssueLicensePackIds] = useState<number[]>([]);

  const [triggerRemoveUserLicenseDialog, setTriggerRemoveUserLicenseDialog] = React.useState(false);
  const [triggerRemoveGroupLicenseDialog, setTriggerRemoveGroupLicenseDialog] = React.useState(false);

  const [groupLicensesCount, setGroupLicensesCount] = React.useState(0);
  const [peoplelicensesCount, setPeopleLicensesCount] = React.useState(0);
  const [clickedPackPeopleIdToRemove, setClickedPackPeopleIdToRemove] = React.useState(0);

  const backPath = fromRef.current || true;

  const requestLicenseIssue = () => {
    const { selectedPeopleIds, selectedPeopleType } = issueLicenseModal.modal;
    if (selectedPeopleIds.length > 0) {
      const assignments = selectedPeopleIds.flatMap((peopleId: number) =>
        issueLicensePackIds.map((packId) =>
          userListUtils.mapToAssignmentItem(peopleId, { id: packId }, "pack", selectedPeopleType),
        ),
      );

      issuePackLicense({
        peopleType: selectedPeopleType,
        items: assignments,
      });
    }

    resetUsersSearchTerm();
    hideIssueLicenseModal();

    return Promise.resolve();
  };

  const onCancel = () => {
    resetUsersSearchTerm();
    hideIssueLicenseModal();
  };

  const permissionPredicate = (userPermissions: RolePermissions[]): boolean => {
    const hasPeopleRight =
      issueLicenseModal.modal.selectedPeopleType === AssignmentPeopleContext.User ? hasUserRight : hasGroupRight;
    return (
      (hasContentRight(userPermissions, Rights.Manage) && hasPeopleRight(userPermissions, Rights.View)) ||
      (hasContentRight(userPermissions, Rights.View) && hasPeopleRight(userPermissions, Rights.Manage))
    );
  };

  const renderIssueLicenseBtn = () => {
    return (
      <Restricted
        key="Issue License"
        permissions={[]}
        permissionPredicate={permissionPredicate}
        renderContent={(hasAnyPermission) => (
          <RestrictedByTooltip hasPermission={hasAnyPermission}>
            <Button
              primary
              className="issue-license create-button"
              content="Issue License"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showIssueLicenseModal();
              }}
              disabled={!hasAnyPermission}
            />
          </RestrictedByTooltip>
        )}
      />
    );
  };

  const onRemoveLicenseClick = async (peopleId: number = 0) => {
    const assignmentPeopleContext = getAssignmentPeopleContext();

    const peopleCount = peopleId > 0 ? 1 : selectedPeopleIds.length;
    setPeopleLicensesCount(peopleCount);
    setClickedPackPeopleIdToRemove(peopleId);

    if (assignmentPeopleContext === AssignmentPeopleContext.User) {
      const userIds = peopleId > 0 ? [peopleId] : selectedPeopleIds;
      const userGroupLicenses = await packsDataService.getUserGroupLicenses(userIds, [packId]);
      const groupLicensesCount = uniq(userGroupLicenses.flatMap((i) => i.groupIds)).length;
      setGroupLicensesCount(groupLicensesCount);
      setTriggerRemoveUserLicenseDialog(true);
    } else {
      setTriggerRemoveGroupLicenseDialog(true);
    }
  };

  const closeRemoveLicenseConfirmation = () => {
    const assignmentPeopleContext = getAssignmentPeopleContext();

    setPeopleLicensesCount(0);
    setClickedPackPeopleIdToRemove(0);

    if (assignmentPeopleContext === AssignmentPeopleContext.User) {
      setGroupLicensesCount(0);
      setTriggerRemoveUserLicenseDialog(false);
    } else {
      setTriggerRemoveGroupLicenseDialog(false);
    }
  };

  const getPeopleToRemove = () => {
    return clickedPackPeopleIdToRemove > 0 ? [clickedPackPeopleIdToRemove] : selectedPeopleIds;
  };

  const onRemovePeopleLicense = () => {
    const assignmentPeopleContext = getAssignmentPeopleContext();
    const peopleToRemove = getPeopleToRemove();

    const licenses = peopleToRemove.map((peopleId) =>
      userListUtils.mapToAssignmentItem(peopleId, { id: packId }, "pack", assignmentPeopleContext),
    );

    removePackLicense({
      peopleType: assignmentPeopleContext,
      items: licenses,
    });

    closeRemoveLicenseConfirmation();
    setSelectedPeopleIds([]);
  };

  const renderRemoveLicenseBtn = () => {
    return (
      <Restricted
        permissions={[]}
        permissionPredicate={permissionPredicate}
        renderContent={(hasAnyPermission) => (
          <RestrictedByTooltip hasPermission={hasAnyPermission}>
            <RemoveLinkButton
              className="remove-pack-button"
              onClick={onRemoveLicenseClick}
              isDisabled={!hasAnyPermission}
            />
          </RestrictedByTooltip>
        )}
      />
    );
  };

  const renderHeaderButtons = () => {
    if (location.pathname.includes(pages.licensing.segment.to)) {
      if (selectedPeopleIds.length > 0) {
        return [renderRemoveLicenseBtn()];
      }
      return [renderIssueLicenseBtn()];
    } else {
      return null;
    }
  };

  const onSecondStep = async (selectedIds: number[]) => {
    selectPeople(selectedIds);
    resetUsersSearchTerm();
    return Promise.resolve();
  };

  const onSearchUsersTermChanged = (search: string) => {
    setUsersSearchTerm(search);
    reloadListItems.current?.(isEmpty(search));
  };

  const getAssignmentPeopleContext = (context: UsersGroupsContext = usersGroupsContext) =>
    context === UsersGroupsContext.Users ? AssignmentPeopleContext.User : AssignmentPeopleContext.Group;

  const onPeopleContextChanged = (context: UsersGroupsContext) => {
    setUsersGroupsContext(context);
    const assignmentPeopleContext = getAssignmentPeopleContext(context);
    resetIssueLicenseModalContext(assignmentPeopleContext);
  };

  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const createReloadListItems = useCallback((reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  }, []);

  const renderIssueUserLicenseModal = () => {
    return (
      <PackIssueLicenseToUserModal
        itemsPacks={[
          {
            type: "Pack",
            id: pack?.id?.toString(),
            title: pack?.title,
            thumbnailUrl: pack?.thumbnailImageUrl,
          },
        ]}
        onSecondStep={onSecondStep}
        onConfirm={requestLicenseIssue}
        showModal={
          issueLicenseModal.modal.selectedPeopleType === AssignmentPeopleContext.User && issueLicenseModal.modal.show
        }
        onCancel={onCancel}
        onSearchChanged={onSearchUsersTermChanged}
        setReloadListItems={createReloadListItems}
        setIssueLicensePackIds={setIssueLicensePackIds}
      />
    );
  };

  const renderIssueGroupLicenseModal = () => {
    const { itemsCount, isLoading, items } = issueLicenseModal.groupsAvailableForLicensing;

    return (
      <PackIssueLicenseToGroupModal
        itemsPacks={[
          {
            type: "Pack",
            id: pack?.id?.toString(),
            title: pack?.title,
            thumbnailUrl: pack?.thumbnailImageUrl,
          },
        ]}
        loadPage={getGroups}
        groupsAmount={itemsCount}
        isListLoading={isLoading}
        isStepLoading={false}
        groups={items}
        onSecondStep={onSecondStep}
        onConfirm={requestLicenseIssue}
        showModal={
          issueLicenseModal.modal.selectedPeopleType === AssignmentPeopleContext.Group && issueLicenseModal.modal.show
        }
        onCancel={onCancel}
        setReloadListItems={createReloadListItems}
        setIssueLicensePackIds={setIssueLicensePackIds}
      />
    );
  };

  const renderRemoveUserLicenseModal = () => (
    <RemoveLicenseConfirmationDialog
      groupLicensesCount={groupLicensesCount}
      userLicensesCount={peoplelicensesCount}
      triggerOpen={triggerRemoveUserLicenseDialog}
      onCancel={closeRemoveLicenseConfirmation}
      onContinue={onRemovePeopleLicense}
      isPackContext={true}
    />
  );

  const renderRemoveGroupLicenseModal = () => (
    <RemoveGroupLicenseConfirmationDialog
      groupsCount={peoplelicensesCount}
      packsCount={1}
      triggerOpen={triggerRemoveGroupLicenseDialog}
      onCancel={closeRemoveLicenseConfirmation}
      onContinue={onRemovePeopleLicense}
    />
  );

  return (
    <div className="pack-purchased-view-container">
      <div className="nested-content pack-purchased-view">
        <DetailsHeader
          entityStateActions={{
            onPublish: /* istanbul ignore next */ () => { }, //NOSONAR
            onClose: /* istanbul ignore next */ () => { }, //NOSONAR
            onEdit: /* istanbul ignore next */ () => { }, //NOSONAR
            onRevert: /* istanbul ignore next */ () => { }, //NOSONAR
          }}
          renderEditButton={false}
          canBePublished={false}
          canBeEdited={false}
          isReadOnly={true}
          showReadOnlyLabel={false}
          backButton={backPath}
          defaultURL={availablePacksUrl}
          {...pack}
        >
          {renderHeaderButtons()}
        </DetailsHeader>
        <Segments to={`/licensing/packs/purchased/${packId}`}>
          <Segments.Segment key={pages.performance.index} {...pages.performance.segment} />
          <Segments.Segment key={pages.licensing.index} {...pages.licensing.segment} />
        </Segments>
        <Routes>
          <Route path="/" element={<PackPerformance packId={packId} />} />
          <Route
            path={pages.licensing.segment.to}
            element={
              <PeopleContextWrapper
                usersGroupsContext={usersGroupsContext}
                onContextChanged={onPeopleContextChanged}
                renderGroupLicenses={(customHeaderContent: React.ReactElement) => (
                  <GroupLicenses
                    packId={packId}
                    renderIssueLicenseBtn={renderIssueLicenseBtn}
                    customHeaderContent={customHeaderContent}
                    permissionPredicate={permissionPredicate}
                    selectedGroupIds={selectedPeopleIds}
                    onSelectedGroupsChanged={setSelectedPeopleIds}
                    onRemoveLicenseClick={onRemoveLicenseClick}
                    licenseRemovalEnabled={licenseRemovalEnabled}
                  />
                )}
                renderUserLicenses={(customHeaderContent: React.ReactElement) => (
                  <UserLicenses
                    packId={packId}
                    renderIssueLicenseBtn={renderIssueLicenseBtn}
                    customHeaderContent={customHeaderContent}
                    permissionPredicate={permissionPredicate}
                    selectedUserIds={selectedPeopleIds}
                    onSelectedUsersChanged={setSelectedPeopleIds}
                    onRemoveLicenseClick={onRemoveLicenseClick}
                    licenseRemovalEnabled={licenseRemovalEnabled}
                  />
                )}
              />
            }
          />
        </Routes>

        {renderIssueUserLicenseModal()}
        {renderIssueGroupLicenseModal()}
        {renderRemoveUserLicenseModal()}
        {renderRemoveGroupLicenseModal()}
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  pack: state.packs.purchasedPack.packPurchasedView.pack,
  issueLicenseModal: state.packs.purchasedPack.issueLicenseModal,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPack: bindActionCreators(fetchPurchasedPack, dispatch),
  showIssueLicenseModal: bindActionCreators(show, dispatch),
  hideIssueLicenseModal: bindActionCreators(hide, dispatch),
  resetIssueLicenseModalContext: bindActionCreators(resetContext, dispatch),
  issuePackLicense: bindActionCreators(issuePackLicenseToPeople, dispatch),
  removePackLicense: bindActionCreators(removePackLicenseFromPeople, dispatch),
  getGroups: bindActionCreators(fetchGroupsAvailableForLicensing, dispatch),
  selectPeople: bindActionCreators(setSelectedPeopleIds, dispatch),
  setUsersSearchTerm: bindActionCreators(setSearchTerm, dispatch),
  resetUsersSearchTerm: bindActionCreators(resetSearchTerm, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackPurchasedViewSegments);
