import TriggerableConfirmationModal from "components/triggeredConfirmationModal/TriggerableConfirmationModal";
import ModalSizes from "enums/modalSizes";
import { IObservable } from "interfaces/IObservable";
import { FC, useState } from "react";
import PackAccountItems from "./packAccountItems/PackAccountItems";
import { PackAccountsContextItem, PackMap } from "./types";
import cn from "classnames";
import styles from "./groupTemplatePacksConfirmationModal.module.scss";

export interface GroupTemplatePacksConfirmationModalProps {
  onTriggerModalObserver: IObservable<(onConfirm: () => void, selectedPackIds: number[]) => void>;
  packMap: PackMap;
  templateId: number;
  selectedCustomerIds: number[];
  onConfirmAction: (templateId: number, packs: PackAccountsContextItem[], accountIds: number[]) => Promise<void>;
}

export const GroupTemplatePacksConfirmationModal: FC<GroupTemplatePacksConfirmationModalProps> = (props) => {
  const { packMap, templateId, selectedCustomerIds, onConfirmAction } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const onConfirmed = () => onConfirmAction(templateId, [...packMap.values()], selectedCustomerIds);

  const getContent = <PackAccountItems selectedIds={selectedIds} packMap={packMap} setIsDisabled={setIsDisabled} />;

  return (
    <TriggerableConfirmationModal
      title="Provision Licenses"
      scrolling
      content={getContent}
      confirmButtonLabel="Continue"
      onTriggerModalObserver={props.onTriggerModalObserver}
      onConfirmed={onConfirmed}
      disabled={isDisabled}
      size={ModalSizes.small}
      styles={styles}
      classNames={cn({ [styles["multiple-packs"]]: selectedIds.length > 2 })}
      onTrigger={(input: number[]) => {
        setSelectedIds(input);
      }}
    />
  );
};
