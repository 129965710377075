import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faCircleX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "@reduxjs/toolkit";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { ColumnOption } from "interfaces/columnOptions";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { selectPeopleDetails, selectPeopleDetailsValue } from "../state/slices/assessmentPerformanceSlice";
import * as assessmentPerformanceActions from "../state/thunks/assessmentPerformanceThunk";
import { AssessmentPeopleDetails } from "../types/state";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";

import styles from "./assessmentPerformancePeople.module.scss";

export type AssessmentPerformancePeopleProps = {
  assessmentId: number;
  flowId?: number;
  filter: PerformanceFilter;
  hideControls?: boolean;
} & PropsFromRedux;

export type EngagementLineStatus = "Fail" | "Pass";

type AssessmentStatusCompute = {
  [key in EngagementLineStatus]: {
    icon: IconProp;
  };
};

export const AssessmentPerformancePeopleStatusToCell: AssessmentStatusCompute = {
  Pass: {
    icon: faCircleCheck,
  },
  Fail: {
    icon: faCircleX,
  },
};

const columnOptions: ColumnOption<AssessmentPeopleDetails>[] = [
  {
    name: "NAME",
    width: 5,
    isSortable: false,
    render: (entity: AssessmentPeopleDetails) => {
      const secondaryInfo = [entity.UserTitle, entity.Department].filter((x) => x).join(", ");
      return (
        <div>
          <Link to={`/${RouteNames.peopleUsers}/${entity.UserId}`} className={styles.linkButton}>
            <Tooltip
              content={`${entity.FirstName} ${entity.LastName}`}
              target={
                <TextTruncate>
                  {entity.FirstName} {entity.LastName}
                </TextTruncate>
              }
            />
          </Link>
          <Tooltip
            content={secondaryInfo}
            target={<TextTruncate className="workTitle">{secondaryInfo}</TextTruncate>}
          />
        </div>
      );
    },
  },
  {
    width: 5,
    name: "EMAIL",
    isSortable: false,
    render: (entity: AssessmentPeopleDetails) => (
      <div>
        <Tooltip target={<TextTruncate>{entity.Email}</TextTruncate>} content={entity.Email} />
        <div>&nbsp;</div>
      </div>
    ),
  },
  {
    name: "DATE STARTED",
    width: 5,
    isSortable: false,
    render: (entity: AssessmentPeopleDetails) => {
      const date = dateTimeUtils.formatDate(entity.StartDate);

      return (
        <div>
          <div>{date}</div>
          <div>&nbsp;</div>
        </div>
      );
    },
  },
  {
    name: "DATE COMPLETED",
    width: 5,
    isSortable: false,
    render: (entity: AssessmentPeopleDetails) => {
      const date = dateTimeUtils.formatDate(entity.CompletionDate);

      return (
        <div>
          <div>{date}</div>
          <div>&nbsp;</div>
        </div>
      );
    },
  },
  {
    name: "RESULT",
    width: 5,
    isSortable: false,
    render: (entity: AssessmentPeopleDetails) => {
      return (
        <div>
          <FontAwesomeIcon
            color="#656568"
            icon={AssessmentPerformancePeopleStatusToCell[entity.Result as keyof AssessmentStatusCompute].icon}
          />
          &nbsp;
          {entity.Result}
        </div>
      );
    },
  },
];

export const AssessmentPerformancePeopleComponent: FC<AssessmentPerformancePeopleProps> = ({
  actions,
  assessmentId,
  flowId,
  filter,
  peopleDetails,
  peopleDetailsStatus,
  hideControls,
}) => {
  useEffect(() => {
    actions.fetchPeopleDetails(assessmentId, { ...filter, flowId });
  }, [actions, assessmentId, filter, flowId]);

  return (
    <RequestStatusRenderer state={peopleDetailsStatus}>
      <GenericPerformanceList
        hideControls={hideControls}
        rows={peopleDetails}
        columns={columnOptions}
        filter={filter}
        title="People"
      />
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    peopleDetails: selectPeopleDetailsValue(state),
    peopleDetailsStatus: selectPeopleDetails(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(assessmentPerformanceActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssessmentPerformancePeopleComponent);
