import { FC, Fragment, memo, useEffect, useMemo, useState } from "react";
import { PackAccountsContextItem, PackMap } from "../types";
import CardThumbnail from "components/cards/cardThumbnail/CardThumbnail";
import LicensePicker from "components/LicensePicker/LicensePicker";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fetchPackLicenseTypes } from "features/Licensing/Packs/state/thunks/packLicenseTypesThunk";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";
import { PackLicenseType } from "features/Licensing/Packs/types/state";
import { LicenseTypes } from "enums";
import styles from "./packAccountItems.module.scss";

interface PackAccountItemsPropsOwn {
  selectedIds: number[];
  packMap: PackMap;
  setIsDisabled: (value: boolean) => void;
  isEdit?: boolean;
}

interface PackItemProps {
  item: PackAccountsContextItem;
  options: PackLicenseType[];
  onExpirationDateChange: (selectedPackId: number, optionId?: number, date?: Date | null) => void;
  idx: number;
}

export type PackAccountItemsProps = PackAccountItemsPropsOwn & PropsFromRedux;

const PackItem = memo((props: PackItemProps) => {
  const { item, options, onExpirationDateChange, idx } = props;

  const licenseOptions = useMemo(
    () =>
      options
        .filter((option) => item.isTrialAllowed || option.id !== LicenseTypes.Trial)
        .map((option) => ({
          title: option.id === LicenseTypes.Trial ? `${item.trialPeriod?.name} ${option.name}` : option.name,
          id: option.id,
          duration: option.monthsCount,
        }))
        .reverse(),
    [item, options],
  );

  return (
    <Fragment key={item.id}>
      <div className={styles.item} key={item.id}>
        <div className={styles.thumbnail}>
          <CardThumbnail thumbnailUrl={item.thumbnailImageUrl} />
        </div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.licensing}>
          <span className={styles.licenses}>
            <span className="required-asterisk">Licenses</span>
            <b>Auto-increment</b>
          </span>
          <span className={styles.expiration}>
            <LicensePicker
              options={licenseOptions}
              onOptionChange={(optionId?: number) => onExpirationDateChange(item.id, optionId)}
              onExpirationDateChange={(date?: null | Date) => onExpirationDateChange(item.id, undefined, date)}
              verticalPosition={idx > 0 ? "top" : "bottom"}
            />
          </span>
        </div>
      </div>
    </Fragment>
  );
});

export const PackAccountItems: FC<PackAccountItemsProps> = (props) => {
  const { selectedIds, packMap, setIsDisabled, licenseTypes, isLoading, loadLicenseTypes, isEdit } = props;
  const [items, setSelectedItems] = useState<PackAccountsContextItem[]>([]);
  useEffect(() => {
    loadLicenseTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedItems(selectedIds.map((id) => packMap.get(id)) as PackAccountsContextItem[]);
    // eslint-disable-next-line
  }, [selectedIds]);

  useEffect(() => {
    validateFinishBtnEnabling();
    // eslint-disable-next-line
  }, [items]);

  const onExpirationDateChange = (selectedPackId: number, optionId?: number, date?: Date | null) => {
    const packs = [...items];

    const packIndex = packs.findIndex((el) => el.id === selectedPackId);

    if (packIndex !== -1) {
      const updatedPack = {
        ...packs[packIndex],
        licenseTypeId: optionId ?? packs[packIndex].licenseTypeId,
        expirationDate: date ?? undefined,
      };

      packs[packIndex] = updatedPack;

      setSelectedItems(packs);
      packMap.set(updatedPack.id, updatedPack);
    }
  };

  const validateFinishBtnEnabling = () => {
    const isNotValid = items.some(
      (p) => !p.expirationDate && (!p.licenseTypeId || p.licenseTypeId === LicenseTypes.CustomDate),
    );
    setIsDisabled(isNotValid);
  };

  const renderInfoSection = () => (
    <div className={styles.info}>
      License quantities for each Pack have been calculated based on the users from the{" "}
      {isEdit ? "existing customer accounts in this template." : "previously selected customer accounts."} Quantities
      may {isEdit ? "automatically adjust" : "adjust automatically"} as new users are added.
    </div>
  );
  return (
    <form className={styles.root} autoComplete="off">
      {!isLoading && renderInfoSection()}
      {!isLoading &&
        items.map((item, idx) => (
          <PackItem
            key={idx}
            item={item}
            options={licenseTypes}
            onExpirationDateChange={onExpirationDateChange}
            idx={idx}
          />
        ))}
      <Loader active={isLoading} />
    </form>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    licenseTypes: state.packs.packLicenseTypes.items,
    isLoading: state.packs.packLicenseTypes.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadLicenseTypes: bindActionCreators(fetchPackLicenseTypes, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackAccountItems);
