import { FormikProps } from "formik";
import { useCallback, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { WizardWrapper as Wizard } from "../../../../WizardWrapper";
import { useWizardStepsManager } from "../../../../../hooks/useWizardStepsManager";
import navigationUtils from "../../../../../utils/navigationUtils";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PublishAndSendWizardButtons } from "../../../components/PublishAndSendWizardButtons/PublishAndSendWizardButtons";
import MessageContentForm from "../Content/MessageContentForm";
import MessageInfoForm from "../Info/MessageInfoForm";
import { MessageDetails } from "../types";
import { useRtn } from "../../../../../hooks/useRtn";
import { MessagePublishSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

enum MessageCreateSteps {
  Configuration = 0,
  Content = 1,
}

const pageIndexes = [MessageCreateSteps.Configuration, MessageCreateSteps.Content];

export type Props = FormikProps<MessageDetails> & PropsFromRedux;

const CreateMessage: React.FC<Props> = (props) => {
  const { isSaving, dateModified, id } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onCancel = useCallback(() => {
    navigationUtils.goBackOrDefault(location, navigate, "/content/communications");
  }, [location, navigate]);

  const [isValid, setIsValid] = useState(false);
  const [sendSessionRedirect, setSendSessionRedirect] = useState(false);

  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager(pageIndexes);

  const onPublishedEvent = () => {
    navigate(
      sendSessionRedirect ? `/content/communications/messages/${id}/send-sessions/create` : "/content/communications",
    );
  };

  useRtn([MessagePublishSuccess], onPublishedEvent);

  const getPublishAndSendHandler = (onFinishAsync: () => Promise<void>) => () => {
    setSendSessionRedirect(true);
    return onFinishAsync();
  };

  const renderPublishAndSendButtons = (onFinishAsync: () => Promise<void>) => {
    return (
      <PublishAndSendWizardButtons
        onFinishAsync={onFinishAsync}
        getPublishAndSendHandler={getPublishAndSendHandler}
        isValid={isValid}
      />
    );
  };

  const onIsValidChange = (isValidUpdated: boolean) => {
    setIsValid(isValidUpdated);
  };

  return (
    <Wizard
      id="create-message-wizard"
      data-testid="create-message-wizard"
      title="Create Message"
      isSaveInProgress={isSaving}
      progressSavedDate={dateModified}
      onProgressAsync={onNext}
      onRegressAsync={onPrevious}
      onCancel={onCancel}
      onFinish={onFinish}
      renderCustomFinishButton={renderPublishAndSendButtons}
      isFinishButtonDisabled={!isValid}
    >
      <Wizard.Step label="Configuration" className="scrollable-content" isLocked={!isValid} required>
        <MessageInfoForm
          normalizedTags={{ softwareApplications: [] }}
          acceptHandlers={wizardPages[MessageCreateSteps.Configuration]}
          onIsValidChange={onIsValidChange}
        />
      </Wizard.Step>
      <Wizard.Step label="Content" className="scrollable-content">
        <MessageContentForm
          acceptHandlers={wizardPages[MessageCreateSteps.Content]}
          onIsValidChange={onIsValidChange}
        />
      </Wizard.Step>
    </Wizard>
  );
};

const mapStateToProps = (state: RootState) => ({
  id: state.library.messages.details.messageEntityStateReducer.entityId,
  isSaving: state.library.messages.details.messageEntityStateReducer.isEntityCommandInProgress,
  dateModified: state.library.messages.details.messageEntityStateReducer.lastModifiedDateTime,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateMessage);
