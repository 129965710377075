import { FC } from "react";
import { Icon } from "semantic-ui-react";

import { PriorityLevels } from "../../../enums";

import "./priorityLevel.scss";

export const getPriorityLevels = (disableDefault?: boolean) => {
  return [
    {
      text: <span className="priority priority-levels-element">None</span>,
      value: PriorityLevels.none,
      disabled: disableDefault,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-arrow-square-down" /> Normal
        </span>
      ),
      value: PriorityLevels.normal,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-arrow-square-up" /> Important
        </span>
      ),
      value: PriorityLevels.important,
    },
    {
      text: (
        <span className="priority priority-levels-element">
          <Icon className="fa-exclamation-square" /> Required
        </span>
      ),
      value: PriorityLevels.required,
    },
  ];
};

export const priorityLevelsElements = getPriorityLevels(false);

interface Props {
  priorityLevel: PriorityLevels;
}

export const PriorityLevel: FC<Props> = ({ priorityLevel }) => {
  if (priorityLevel === PriorityLevels.none) {
    return priorityLevelsElements[0].text;
  }

  return priorityLevelsElements.find((x) => x.value === priorityLevel)?.text || null;
};
