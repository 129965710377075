import { EllipsisButton, OverviewHeader, RestrictedByTooltip } from "components";
import { Button } from "components/buttons/button/Button";
import { Title } from "components/listViewTemplates";
import { RolePermissions } from "enums";
import { useRestrictedCheck } from "features/Application/Restricted";
import { ColumnOption } from "interfaces/columnOptions";
import { noop } from "lodash";
import React from "react";
import dateTimeUtils from "utils/dateTimeUtils";
import GenericItemsView from "views/ItemsView/GenericItemsView";

export const TemplatesOverview = () => {
  const hasManagePermission = useRestrictedCheck([RolePermissions.TemplatesManage]);
  return (
    <section className="nested-content">
      <OverviewHeader title="Templates" itemName="Template">
        <RestrictedByTooltip hasPermission={hasManagePermission}>
          <Button primary className="create-survey create-button" onClick={noop} disabled={!hasManagePermission}>
            Create Template
          </Button>
        </RestrictedByTooltip>
      </OverviewHeader>
      <GenericItemsView
        items={[
          {
            title: "Unboarding",
            customers: 100,
            dateCreated: "2023-05-11",
            lastModified: "2023-05-11",
            users: 100,
          },
        ]}
        isLoading={false}
        columnOptions={columnOptions}
        fetchData={noop}
        dataCount={0}
      />
    </section>
  );
};

interface Template {
  title: string;
  dateCreated: string;
  lastModified: string;
  customers: number;
  users: number;
}

export enum Columns {
  Template = "Template",
  DateCreated = "Date Created",
  LastModified = "Last Modified",
  Customers = "Customers",
  Users = "Users",
}

const columnOptions: ColumnOption<Template>[] = [
  {
    name: Columns.Template,
    width: 4,
    isSortable: false,
    render: (item) => <Title title={item.title} onTitleClick={noop} clamp={2} className="normal-color" />,
  },
  {
    name: Columns.DateCreated,
    width: 2,
    isSortable: false,
    render: (item) => dateTimeUtils.formatDate(item.dateCreated),
  },
  {
    name: Columns.LastModified,
    width: 2,
    isSortable: false,
    render: (item) => dateTimeUtils.formatDate(item.lastModified),
  },
  {
    name: Columns.Customers,
    width: 3,
    isSortable: false,
    render: (item) => item.customers,
  },
  {
    name: Columns.Users,
    width: 3,
    isSortable: false,
    render: (item) => item.users,
  },
  {
    name: "",
    width: 1,
    className: "align-right",
    render: () => (
      <EllipsisButton>
        <Button>Edit</Button>
      </EllipsisButton>
    ),
  },
];
