import React from "react";
import { Dimmer, Form, Grid, Loader } from "semantic-ui-react";

import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";
import LinkLoader from "../linkLoader/LinkLoader";
import PreventTransitionPrompt from "../preventTransition/PreventTransitionPrompt";
import CheckboxField from "./CheckboxField";
import ChecklistDropdownField from "./ChecklistDropdownField";
import DropdownField from "./DropdownField";
import ImagePreview from "./ImagePreview";
import InputField from "./InputField";
import { InputFieldDigitsOnly } from "./InputFieldDigitsOnly";
import { MultiSelectField } from "./MultiSelectField";
import MultiSelectWithAdditionField from "./MultiSelectWithAdditionField";
import PasswordField from "./PasswordField";
import TextAreaField from "./TextAreaField";
import UploadFileField from "./UploadFileField";
import TextEditorField from "./textEditorField";
import ThumbnailPreview from "./thumbnailPreview/thumbnailPreview";
import ValidatedFormProps from "./validatedFormProps";

import "./validatedForm.scss";

function ValidatedFormWithoutFormik(props: ValidatedFormWithoutFormikProps) {
  const {
    children,
    id,
    className,
    style,
    formWidthComputer,
    formWidthTablet,
    handleSubmit,
    isSubmitting,
    isLoading,
    loaderOnSubmit,
  } = props;

  const renderPreventTransitionPrompt = () => {
    const {
      dirty,
      unsavedChangesPrompt: { title, message },
    } = props;

    if (!props.disablePreventTransitionPrompt) {
      return <PreventTransitionPrompt when={!!dirty} title={title} message={message} />;
    }

    return null;
  };

  const isBusy = isLoading || (isSubmitting && loaderOnSubmit);

  return (
    <Dimmer.Dimmable dimmed={isBusy} className={className} style={style}>
      <Dimmer active={isBusy} inverted>
        <Loader active />
      </Dimmer>
      <Form className="validated-form" id={id} onSubmit={handleSubmit} as={Grid}>
        <Grid.Row>
          <Grid.Column computer={formWidthComputer} tablet={formWidthTablet} mobile={16}>
            {children}
          </Grid.Column>
        </Grid.Row>
      </Form>
      {renderPreventTransitionPrompt()}
    </Dimmer.Dimmable>
  );
}

ValidatedFormWithoutFormik.InputField = InputField;
ValidatedFormWithoutFormik.InputFieldDigitsOnly = InputFieldDigitsOnly;
ValidatedFormWithoutFormik.TextAreaField = TextAreaField;
ValidatedFormWithoutFormik.DropdownField = DropdownField;
ValidatedFormWithoutFormik.PasswordField = PasswordField;
ValidatedFormWithoutFormik.CheckboxField = CheckboxField;
ValidatedFormWithoutFormik.UploadFileField = UploadFileField;
ValidatedFormWithoutFormik.LinkLoader = LinkLoader;
ValidatedFormWithoutFormik.ImagePreview = ImagePreview;
ValidatedFormWithoutFormik.ThumbnailPreview = ThumbnailPreview;
ValidatedFormWithoutFormik.MultiSelectWithAdditionField = MultiSelectWithAdditionField;
ValidatedFormWithoutFormik.MultiSelectField = MultiSelectField;
ValidatedFormWithoutFormik.ChecklistDropdownField = ChecklistDropdownField;
ValidatedFormWithoutFormik.TextEditorField = TextEditorField;

ValidatedFormWithoutFormik.defaultProps = {
  unsavedChangesPrompt: {
    title: "Unsaved changes",
    message: "Leave now and lose all of the amazing changes?",
  },
  disablePreventTransitionPrompt: false,
  loaderOnSubmit: false,
  formWidthComputer: 8,
  formWidthTablet: 12,
};

interface ValidatedFormWithoutFormikProps extends ValidatedFormProps {
  children?: React.ReactNode;
  id?: string;
  className?: string;
  style?: object;
  formWidthComputer: SemanticWIDTHS;
  unsavedChangesPrompt: {
    title: string;
    message: string;
  };
  disablePreventTransitionPrompt?: boolean;
  isLoading?: boolean;
  loaderOnSubmit?: boolean;
  formWidthTablet?: SemanticWIDTHS;
}

export default ValidatedFormWithoutFormik;
