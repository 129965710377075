import { AppThunk } from "features/Application/globaltypes/redux";
import { fetchBegin, fetchSuccess } from "../TemplateCustomersSlice";
import { SortingDirection } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";

export const getAvailableCustomers =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getAvailableCustomers({
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.customers.search,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const getCustomers =
  (id: number, skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getCustomers(id, {
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.customers.search,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const saveCustomers =
  (templateId: number): AppThunk =>
  async (_, getState) => {
    const customers = getState().people.groupTemplate.customers.customersToAdd;
    if (customers.length === 0) return;
    try {
      await DataService.groupTemplates.saveCustomers(templateId, customers);
    } catch {}
  };
