import { FC, useEffect, useState } from "react";
import { Strings } from "../../../../enums";
import { NotifyStepSettings, PacksContextItem } from "../../../../interfaces";
import { Filters } from "../../../../utils/queryUtils";
import AvailableUsersStep from "../../peopleIssueLicenseModal/AvailableUsersStep";
import { RenderModalActionsType } from "components/assignmentModals/types";
import PackIssueLicenseToPeopleModal from "../PackIssueLicenseToPeopleModal";

export interface PackIssueLicenseToUserModalProps {
  showModal: boolean;
  itemsPacks: PacksContextItem[];
  onSecondStep: (selectedIds: number[]) => Promise<void>;
  onConfirm: (notificationSettings?: NotifyStepSettings) => Promise<void>;
  onCancel: () => void;
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  onSearchChanged: (value: string) => void;
  setReloadListItems: (reloadItems: (enableSorting: boolean) => void) => void;
  setIssueLicensePackIds: (ids: number[]) => void;
}

export const PackIssueLicenseToUserModal: FC<PackIssueLicenseToUserModalProps> = (
  props: PackIssueLicenseToUserModalProps,
) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [appliedFilter, setFilter] = useState<Filters>({});
  const { showModal, onSearchChanged, setReloadListItems } = props;

  useEffect(() => {
    if (!showModal) {
      setSelectedIds([]);
      setFilter({});
    }
  }, [showModal]);

  const resetUserFilter = () => setFilter({});

  const renderUsersStep = (renderModalActions: RenderModalActionsType) => (
    <AvailableUsersStep
      filter={appliedFilter}
      header={Strings.modalTitles.issueLicense}
      onSelectedListItemsChanged={setSelectedIds}
      selectedItemIds={selectedIds}
      applyFilter={setFilter}
      resetFilter={resetUserFilter}
      setReloadListItems={setReloadListItems}
      onSearchChanged={onSearchChanged}
      renderModalActions={renderModalActions}
    />
  );

  return (
    <PackIssueLicenseToPeopleModal
      className="pack-license-modal"
      selectedIds={selectedIds}
      renderFirstStep={renderUsersStep}
      licenseConfirmationData={{
        groupIds: [],
        userIds: selectedIds,
      }}
      {...props}
    />
  );
};

export default PackIssueLicenseToUserModal;
