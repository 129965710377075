import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";

import { usePerformanceBreadcrumbs } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import FlowPerformanceBody from "./FlowPerformanceBody";

import cn from "classnames";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import {
  reset as resetExports,
  selectExportInfo,
  selectExporting,
  selectIsExportActive,
} from "features/Reporting/state/export/exportSlice";
import { IWizardStep, bindAction } from "interfaces";
import { EntityType } from "../../../../components/charts/types/Sankey";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "../../../../featureFlags";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { RStatus } from "../../../Application/globaltypes/fetchRequest";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { reset, selectCardData, selectDateRangeValue, selectDateRanges } from "../state/slices/flowPerformanceSlice";
import { fetchFlowDateRanges } from "../state/thunks/flowPerformanceThunk";
import { FlowFilter } from "./Filter/FlowPerformanceFilter";

import { selectIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import "../../Common/utils/performanceSCSSUtils.scss";
import "./flowPerformance.scss";

const SERVER_FORMAT = "MM/DD/YYYY HH:mm:ss";

function formatForServer(date: string) {
  return moment(date).format(SERVER_FORMAT);
}

export type Props = PropsFromRedux &
  IWizardStep & {
    flowId: number;
    flowTitle: string;
  };

export interface AssetInfo {
  id: number;
  type: EntityType;
  name: string;
}

export const FlowPerformance: React.FC<Props> = ({
  flowId,
  flowTitle,
  exportInfo,
  dateRangeState,
  dateRangeValue,
  cardState,
  exportStatus,
  getDateRanges,
  reset,
  acceptHandlers,
  resetExports,
  isExportEnabled,
  reportEnabled,
}) => {
  const [selectedAsset, setSelectedAsset] = useState<AssetInfo>();
  const [filterIndex, setFilterIndex] = useState(-1);
  const initialFilterSet = useRef(false); // Sets local state once date ranges are first loaded
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();

  const reportFlagEnabled = useFeatureFlag(FeatureFlags.FlowReport);

  const noCardData = useMemo(() => Object.values(cardState.value).every((value) => value === 0), [cardState.value]);

  useEffect(() => {
    if (reportFlagEnabled) {
      getDateRanges(flowId);
    }

    return () => {
      reset();
      resetExports();
    };
  }, [flowId, getDateRanges, reportFlagEnabled, reset, resetExports]);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load filter index to last value on first load
  useEffect(() => {
    if (dateRangeState.status === RStatus.Got && !initialFilterSet.current) {
      initialFilterSet.current = true;
      setFilterIndex(dateRangeValue.length - 1);
    }
  }, [dateRangeState.status, dateRangeValue]);

  const filterParamFormatted = useMemo(() => {
    let tempFilterIndex = filterIndex;
    if (dateRangeValue.length > 0 && filterIndex === -1) tempFilterIndex = dateRangeValue.length - 1;
    if (tempFilterIndex < 0) {
      return null;
    }
    let date = dateRangeValue[tempFilterIndex];
    if (!date) return null;

    return {
      dateFrom: formatForServer(date.DATETIME_START),
      dateTo:
        date.DATETIME_END === null
          ? dateTimeUtils.formatCurrentDateToUtc(SERVER_FORMAT)
          : formatForServer(date.DATETIME_END),
    };
  }, [dateRangeValue, filterIndex]);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const handleFilterReset = useCallback(() => {
    setFilterIndex(dateRangeValue.length - 1);
  }, [dateRangeValue.length]);

  if (!reportFlagEnabled) {
    return <ReportUnavailable />;
  }

  const tools = (
    <>
      <div className="flowActions">
        <FlowFilter
          currentFilter={filterIndex}
          dateRanges={dateRangeValue}
          setCurrentFilter={setFilterIndex}
          handleReset={handleFilterReset}
          // Disabled filter if drilled down
          // as the lower item may not exist
          // in the new flow version
          disabled={hasBreadcrumbsToDisplay}
        />
        {isExportEnabled && (
          <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={noCardData} />
        )}
      </div>
    </>
  );

  const toolbar = reportEnabled ? tools : <div></div>;

  return (
    <div className={cn("flowPerformanceRoot stretch", { drilldownReportUnavailable: !reportEnabled })}>
      {/* Skips renderer check if feature flag is disabled */}
      <RequestStatusRenderer state={dateRangeState}>
        <header className="flowPerformanceHeader">
          {hasBreadcrumbsToDisplay ? <div>{domElements}</div> : <h2 className="performanceTitle">Summary</h2>}
          {selectedAsset ? toolbar : tools}
        </header>

        <div className={cn("flowPerformanceRoot", { flowPerformanceBody: selectedAsset && !reportEnabled })}>
          <FlowPerformanceBody
            setSelectedAssetFlow={setSelectedAsset}
            flowId={flowId}
            flowTitle={flowTitle}
            registerBreadcrumb={registerBreadcrumb}
            dateFilter={filterParamFormatted}
          />
        </div>
      </RequestStatusRenderer>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    dateRangeState: selectDateRanges(state),
    dateRangeValue: selectDateRangeValue(state),
    cardState: selectCardData(state),
    exportStatus: selectExporting(state),
    exportInfo: selectExportInfo(state),
    isExportEnabled: selectIsExportActive(state),
    reportEnabled: selectIsReportEnabled(state),
  };
};

/* istanbul ignore next */
const mapActionToProps = (dispatch: AppDispatch) => {
  return {
    getDateRanges: bindAction(fetchFlowDateRanges, dispatch),
    reset: bindAction(reset, dispatch),
    resetExports: bindAction(resetExports, dispatch),
  };
};

const connector = connect(mapStateToProps, mapActionToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowPerformance);
