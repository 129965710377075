import { FC } from "react";
import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface RemoveContentConfirmationModalProps {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
  peopleType: PeopleType;
}

const RemovePriorityConfirmationModal: FC<RemoveContentConfirmationModalProps> = (
  props: RemoveContentConfirmationModalProps,
) => {
  const { open, onContinue, onCancel, peopleType } = props;

  const content =
    peopleType === PeopleType.User
      ? "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for this user."
      : "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for all users in this group.";

  return (
    <ActionConfirmationModal
      open={open}
      title={"Clear Priority Settings?"}
      onContinue={onContinue}
      onCancel={onCancel}
      withWarningLabel={false}
      confirmLabel="Clear Priority"
    >
      {content}
    </ActionConfirmationModal>
  );
};

export default RemovePriorityConfirmationModal;
