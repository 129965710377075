import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion } from "semantic-ui-react";
import { connect } from "react-redux";
import NavigationMenuItem from "./navigationMenuItem/NavigationMenuItem";
import environmentConfig from "../../configuration/environmentConfig";
import ReportingNavigationMenu from "./reportingNavigationMenu/ReportingNavigationMenu";

import { fetchMostAccessedAccounts as fetchMostAccessedAccountsRedux } from "../../features/Accounts/MostAccessedAccounts/state/mostAccessedAccountsActions";
import { RolePermissions, RouteNames } from "../../enums";
import { Tooltip } from "../common/tooltip";
import { useRestrictedCheck } from "../../features/Application/Restricted";

import { bindAction } from "interfaces";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

function renderContents(topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation) {
  return (
    <NavigationMenuItem
      label="Content"
      icon="fa-folder-open"
      className="content-menu-item"
      path={RouteNames.content}
      onClick={topLevelItemClick}
      expanded={!collapsed && activeItems.includes(RouteNames.content)}
      toggleNavigation={toggleNavigation}
    >
      {collapsed && (
        <NavigationMenuItem
          label="Content"
          path="content"
          onClick={topLevelItemClick}
          expanded={activeItems.includes(RouteNames.content)}
          container={true}
        >
          <NavigationMenuItem label="Flows" path="flows" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="Events" path="events" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="Communications" path="communications" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="Videos" path="assets/videos" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="Surveys" path="assets/surveys" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="Assessments" path="assets/assessments" hideNavigation={hideNavigation} />
          <NavigationMenuItem label="PDFs" path="assets/pdfs" hideNavigation={hideNavigation} />
        </NavigationMenuItem>
      )}

      {!collapsed && <NavigationMenuItem label="Flows" path="flows" />}
      {!collapsed && <NavigationMenuItem label="Events" path="events" />}
      {!collapsed && <NavigationMenuItem label="Communications" path="communications" />}
      {!collapsed && <NavigationMenuItem label="Videos" path="assets/videos" />}
      {!collapsed && <NavigationMenuItem label="Surveys" path="assets/surveys" />}
      {!collapsed && <NavigationMenuItem label="Assessments" path="assets/assessments" />}
      {!collapsed && <NavigationMenuItem label="PDFs" path="assets/pdfs" />}

      <NavigationMenuItem
        label="Simulated Phishing"
        onClick={topLevelItemClick}
        path={RouteNames.simulatedPhishing}
        expanded={activeItems.includes(RouteNames.contentSimulatedPhishing)}
        toggleNavigation={toggleNavigation}
      >
        <NavigationMenuItem label="Campaigns" path="campaigns" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Email Templates" path="email-templates" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Landing Pages" path="landing-pages" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Feedback Pages" path="feedback-pages" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Sending Profiles" path="sending-profiles" hideNavigation={hideNavigation} />
      </NavigationMenuItem>
    </NavigationMenuItem>
  );
}

function renderRestrictedContents(topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation) {
  return (
    <NavigationMenuItem
      label="Content"
      icon="fa-folder-open"
      className="content-menu-item"
      path={RouteNames.content}
      onClick={topLevelItemClick}
      expanded={!collapsed && activeItems.includes(RouteNames.content)}
      toggleNavigation={toggleNavigation}
    >
      <NavigationMenuItem label="Flows" path="flows" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="Events" path="events" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="Communications" path="communications" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="Videos" path="assets/videos" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="Surveys" path="assets/surveys" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="Assessments" path="assets/assessments" hideNavigation={hideNavigation} />
      <NavigationMenuItem label="PDFs" path="assets/pdfs" hideNavigation={hideNavigation} />
    </NavigationMenuItem>
  );
}

export function TopMenuAccordion({
  activeItems,
  collapsed,
  topLevelItemClick,
  accountId,
  fetchMostAccessedAccounts,
  hasAnyLinkedAccount,
}) {
  const [toggleNavigation, setToggleNavigation] = useState(true);
  const linkedAccountPath = `${RouteNames.accounts}/${RouteNames.linkedAccounts}`;
  const showAccounts = useRestrictedCheck([RolePermissions.AccountsSettingsCreate]) || hasAnyLinkedAccount;
  const hasTemplatesPermissions = useRestrictedCheck([RolePermissions.TemplatesView]);
  const templatesFlag = useFeatureFlag(FeatureFlags.AccountTemplates);
  const showTemplates = hasTemplatesPermissions && templatesFlag;

  useEffect(() => {
    fetchMostAccessedAccounts();
  }, [fetchMostAccessedAccounts]);

  const hideNavigation = () => {
    setToggleNavigation(false);
  };

  const showNavigation = () => {
    setToggleNavigation(true);
  };

  const renderAccounts = () => {
    if (!showTemplates) {
      return (
        showAccounts && (
          <Tooltip
            content={"Accounts"}
            showAlways={collapsed}
            position={"right center"}
            target={
              <NavigationMenuItem
                key="allAccounts"
                label="Accounts"
                icon="building"
                path={linkedAccountPath}
                activePath={linkedAccountPath}
                onClick={topLevelItemClick}
                expanded={activeItems.includes(linkedAccountPath)}
              />
            }
          />
        )
      );
    }

    return (
      <NavigationMenuItem
        label="Accounts"
        icon="building"
        path={RouteNames.accounts}
        onClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.accounts)}
        toggleNavigation={toggleNavigation}
        container
      >
        {showAccounts && (
          <NavigationMenuItem label="Linked" path={RouteNames.linkedAccounts} hideNavigation={hideNavigation} />
        )}
        <NavigationMenuItem label="Templates" path={RouteNames.templates} hideNavigation={hideNavigation} />
      </NavigationMenuItem>
    );
  };

  return (
    <Accordion fluid onMouseOver={showNavigation} className="global-navigation">
      {renderAccounts()}

      <NavigationMenuItem
        label="People"
        icon="fa-user"
        path={RouteNames.people}
        onClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.people)}
        toggleNavigation={toggleNavigation}
      >
        <NavigationMenuItem label="Users" path="users" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Roles" path="roles" hideNavigation={hideNavigation} />
        <NavigationMenuItem label="Groups" path="groups" hideNavigation={hideNavigation} />
      </NavigationMenuItem>
      <ReportingNavigationMenu
        topLevelItemClick={topLevelItemClick}
        expanded={activeItems.includes(RouteNames.reporting)}
        toggleNavigation={toggleNavigation}
        hideNavigation={hideNavigation}
      />

      <Tooltip
        content={"Marketplace"}
        showAlways={collapsed}
        position={"right center"}
        target={
          <NavigationMenuItem
            label="Marketplace"
            icon="fa-shopping-cart"
            path={RouteNames.availablePacks}
            activePath={RouteNames.licensingPacks}
            onClick={topLevelItemClick}
            expanded={activeItems.includes(RouteNames.availablePacks)}
          />
        }
      />
      {useRestrictedCheck([RolePermissions.CampaignsManage])
        ? renderContents(topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation)
        : renderRestrictedContents(topLevelItemClick, collapsed, activeItems, toggleNavigation, hideNavigation)}
      <Tooltip
        content={"Help"}
        showAlways={collapsed}
        position={"right center"}
        target={
          <NavigationMenuItem
            blur
            label="Help"
            onClick={(e) => e.currentTarget.blur()}
            icon="fa-life-ring"
            className="help-menu-item"
            path={environmentConfig.helpCenterUrl}
            external
          />
        }
      />
    </Accordion>
  );
}

TopMenuAccordion.propTypes = {
  activeItems: PropTypes.array,
  collapsed: PropTypes.bool,
  fetchMostAccessedAccounts: PropTypes.func,
  topLevelItemClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accountId: state.userProfile.accountId,
  hasAnyLinkedAccount: state.accounts.mostAccessed.items.length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMostAccessedAccounts: bindAction(fetchMostAccessedAccountsRedux, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuAccordion);
