import { QueryFunctionContext } from "@tanstack/react-query";
import { ColumnDataPoint } from "components/charts/types/ColumnChart";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { AxisDomain, scaleLinear, scaleLog } from "d3";
import { AccountTypes, AddOn } from "features/Accounts/types";
import { ChartPeriod } from "hooks/useChartPeriodMeasure";
import { intersection, isEqual } from "lodash";
import moment from "moment";
import sanitize from "sanitize-filename";
import { NoDataMonth, NoFilteredData } from "../../../../components/charts";
import { getFormatFromValueTypes } from "../../../../components/charts/shared/public";
import { RStatus } from "../../../Application/globaltypes/fetchRequest";
import { BasePerformanceRequestFilterParams } from "../models";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";
import { RolePermissions } from "enums";

export type selectedType = "outreach" | "interaction" | undefined;

export type LineChartState = {
  yScale: typeof scaleLinear | typeof scaleLog;
  yFormatterFunc: string | undefined;
  yExtraTickFormat?: ReturnType<typeof getFormatFromValueTypes>;
};

export interface PerformanceFilter {
  dateFrom: string;
  dateTo: string;
}

export interface PerformanceWithAccountFilter {
  dateFrom: string;
  dateTo: string;
  showCustomers: boolean;
}

const emptyFilter = { dateFrom: "", dateTo: "" };

export const columnDateFormatter = (d: AxisDomain): string => {
  const asDate = new Date(d.toString());
  return `${asDate.getMonth() + 1}/${asDate.getDate()}`;
};

export const linearChartState: LineChartState = {
  yScale: scaleLinear,
  yFormatterFunc: ",d",
};

export const longFormDateFilter = (dateFrom: string, dateTo: string, ...other: any) => ({
  dateFrom: moment(dateFrom).format("MM/DD/YYYY"),
  dateTo: moment(dateTo).format("MM/DD/YYYY"),
  ...other,
});

export const logChartState: LineChartState = {
  yScale: scaleLog,
  yFormatterFunc: "10",
};

export const dateRange30 = (): PerformanceFilter => {
  const today = moment().toISOString();
  const todayMinus30 = moment().subtract(30, "days").toISOString();
  let formattedDates = longFormDateFilter(today, todayMinus30);
  return {
    dateTo: formattedDates.dateFrom,
    dateFrom: formattedDates.dateTo,
  };
};

const validDateFormats = [
  "M/D/YY",
  "MM/D/YY",
  "M/DD/YY",
  "MM/DD/YY",
  "M/D/YYYY",
  "MM/D/YYYY",
  "M/DD/YYYY",
  "MM/DD/YYYY",
];
export const validDateWithMoment = (dateString: string) => moment(dateString, validDateFormats, true).isValid();

export const noData = (dateFilter: PerformanceFilter | BasePerformanceRequestFilterParams | null) =>
  dateFilter === null || dateFilter.dateFrom || dateFilter.dateTo ? <NoFilteredData /> : <NoDataMonth />;

export const normalizeDateString = (date: string) => {
  const parsed = new Date(date);
  return new Date(new Date(parsed.getTime() - parsed.getTimezoneOffset() * -60000));
};

export const filterCallback = (filter: PerformanceFilter, emailFilter: PerformanceFilter) => {
  if (emailFilter.dateFrom !== filter.dateFrom || emailFilter.dateTo !== filter.dateTo) {
    return { dateFrom: filter.dateFrom, dateTo: filter.dateTo };
  }
  return emptyFilter;
};

export const isLoading = (status: RStatus) => {
  return status === RStatus.Pending;
};

export const invalidFilterData = (funnelData: { [key: string]: number | undefined; }, filter: PerformanceFilter) => {
  const zeroValues = Object.values(funnelData).every((value) => value === 0 || value === undefined);
  const filterValues = Object.values(filter).every((value) => value !== "");

  return zeroValues && filterValues;
};

export const isFilterPresent = (dateFilter: PerformanceFilter) => {
  return !isEqual(dateFilter, emptyFilter);
};

export const dataPresent = (data?: { [key: string]: number; }) => {
  if (data === undefined) return false;

  const keysExist = Object.keys(data).length !== 0;
  const someValueHasData = Object.values(data).some((item) => item !== 0);

  return keysExist && someValueHasData;
};

export const validLineData = (lines: number[][]) => {
  return (
    lines.length &&
    lines.every((p) => p !== undefined) &&
    lines.some((line) => line.some((num) => num !== null)) &&
    lines.some((line) => line.some((num) => num !== 0))
  );
};

export const noBarData = (...data: number[]) => data.every((num) => num <= 0 || num === undefined);

export const noTableData = <T,>(rows: Array<T>): boolean => rows.length === 0;

export function formattedAverageTime(num: number) {
  if (num || num === 0) {
    const millisecondsPerSecond = 1000;
    const secondsPerMinute = 60;
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;
    const minutesPerHour = 60;
    const hoursPerDay = 24;

    const fromTime = new Date(+0);
    const toTime = new Date(num * millisecondsPerSecond);

    // get total seconds between the times
    let delta = Math.abs(toTime.getTime() - fromTime.getTime()) / millisecondsPerSecond;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / secondsPerDay);
    delta -= days * secondsPerDay;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / secondsPerHour) % hoursPerDay;
    delta -= hours * secondsPerHour;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / minutesPerHour) % minutesPerHour;
    delta -= minutes * minutesPerHour;

    const seconds = Math.floor(delta % secondsPerMinute);

    if (days !== 0) {
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else if (hours !== 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes !== 0) {
      return `${minutes}m ${seconds}s`;
    } else if (seconds !== 0) {
      return `${seconds}s`;
    }
    return "0s";
  }
  return "";
}

export function downloadExcelExport(data: any, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", sanitize(`${fileName}.xlsx`));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const createDateRange = (dateFrom: string, dateTo: string, dateFormat = "MM/DD/YYYY") => {
  const start = moment(dateFrom, dateFormat).local(true);
  const end = moment(dateTo, dateFormat).local(true);
  const dates = [];
  let current = start.clone();

  while (current.isSameOrBefore(end)) {
    dates.push(current.toDate());
    current.add(1, "day");
  }

  return dates;
};

export const getRange = <T,>(
  dateRange: Date[],
  boilerplateObject: T,
  dateFormat = "YYYY-MM-DDTHH:mm:ssZ",
): (T & { Date: string; })[] => {
  const dates = [];
  for (const date of dateRange) {
    const boiler = {
      ...boilerplateObject,
      Date: moment(date).local(true).format(dateFormat),
    };
    dates.push(boiler);
  }
  return dates;
};

export const findIndexInRange = (date: string | null | undefined, state: { Date: string; }[]) => {
  if (!date) return -1;

  const formattedDate = moment(date)
    .startOf("day")
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDTHH:mm:ssZ");

  return state.findIndex(
    (item) => moment(item.Date).format("YYYY-MM-DDTHH:mm:ssZ") === moment(formattedDate).format("YYYY-MM-DDTHH:mm:ssZ"),
  );
};

export const transitionTime = 500;
export const barPadding = 0.2;

export const lineChartMargins = { top: 34, left: 75, right: 55, bottom: 39 };
export const groupedBarChartMargins = lineChartMargins;
export const horizontalBarChartMargins = { top: 12, right: 50, bottom: 40, left: 120 };
export const columnChartMargins = { top: 20, left: 80, right: 20, bottom: 30 };
export const sankeyWrapperMargins = { top: 50, left: 50, right: 50, bottom: 50 };

export const totalActivity = "Total Activity";
export const dailyActivity = "Daily Activity";
export const lineChartTitles = [totalActivity, dailyActivity];
export const packTitles = ["Trials", "Purchased"];
export const starts = "Starts";
export const completes = "Completes";
export const chartLegendLabels = [starts, completes];

export const startsColor = "#288bed";
export const completesColor = "#ef9e08";
export const performanceGray = "#686569";
export const chartColorScale = [startsColor, completesColor];
export const sendsColor = startsColor;
export const opensColor = "#58a57b";
export const clicksColor = completesColor;
export const emailReportColorScale = [sendsColor, opensColor, clicksColor];
export const fourthColor = "#f26a5e";

type LineChartKeys<T> = "Date" | keyof T;
type IncomingLineChartValues<Key extends string | number | symbol> = Key extends "Date" ? string : number;
type OutgoingLineChartValues<Key extends string | number | symbol> = Key extends "Date" ? Date : number;

type IncomingData<T> = {
  [key in LineChartKeys<T>]: IncomingLineChartValues<key>;
};

export type FormattedLineData<T extends IncomingData<T>> = {
  [key in LineChartKeys<T>]: OutgoingLineChartValues<key>[];
};
type OtherFormattedDataOptional<T extends IncomingData<T>> = Partial<FormattedLineData<T>>;

export const lineChartFactory = <T extends IncomingData<T>>(dataPoints: T[]) => {
  if (dataPoints.length === 0) return {} as FormattedLineData<T>;
  const getKeys = Object.keys(dataPoints[0]) as (keyof T)[];
  const formattedData: OtherFormattedDataOptional<T> = {};

  for (const key of getKeys) {
    formattedData[key] = [];
  }

  for (const dataPoint of dataPoints) {
    for (const [key, value] of Object.entries(dataPoint)) {
      if (key === "Date") {
        (formattedData as FormattedLineData<T>)["Date"].push(moment(value).local(true).toDate());
      } else {
        (formattedData as FormattedLineData<T>)[key as keyof T].push(value);
      }
    }
  }

  return formattedData as FormattedLineData<T>;
};

// If there is a decimal, show 2 decimals
// otherwise, leave as a whole integer
export const roundToTwoDigits = (num: number) =>
  num % 1 === 0
    ? num.toLocaleString()
    : num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const canViewCustomersInformation = (accountType: AccountTypes, addOns?: AddOn[]) => {
  const customerTypeValid = accountType !== AccountTypes.Customer;
  const addOnsPresent = Array.isArray(addOns);
  if (!addOnsPresent) {
    return customerTypeValid;
  }
  const foundAddon = addOns.find((addon) => addon.name === "Create Accounts");
  const addOnValid = !!foundAddon?.isEnabled;
  return customerTypeValid || addOnValid;
};

export const getBarDomain = (datapoints: DataPoint[] | ColumnDataPoint[]): [number, number] => {
  return [0, Math.max(...datapoints.map((d) => d.value)) || 1];
};

export const defaultDateFilter = () => ({
  ...dateRange30(),
  showCustomers: false,
});
export type QueryFilter = QueryFunctionContext<[_: string, filter: PerformanceFilter], unknown>;

export type QueryFilterFlow = QueryFunctionContext<[_: string, filter: BasePerformanceRequestFilterParams], unknown>;

export const getFormattedTimeStringFromPeriod = (d: Date | string, period: ChartPeriod, dates: Date[]): string => {
  let dateToFormat = typeof d === "string" ? moment(d, SERVER_DATE_ONLY_FORMAT).local(true) : moment(d).local(true);
  switch (period) {
    case "WEEK":
      let startMoment = moment(dates[0]);
      let startDate = dateToFormat.isBefore(startMoment, "day") ? startMoment : dateToFormat;
      // Test one week later
      let final = moment(dates.at(-1));
      let endDate = dateToFormat.clone().add(1, "week").subtract(1, "day");
      if (endDate.isAfter(final)) {
        endDate = final;
      }
      if (startDate.isSame(endDate, "day")) {
        return startDate.format("M/D");
      }

      return `${startDate.format("M/D")} - ${endDate.format("M/D")}`;
    case "MONTH":
    case "DAY":
    default:
      return dateToFormat.format("MMM DD").replace(",", "");
  }
};

export const largestToSmallest = (a: DataPoint, b: DataPoint) => b.value - a.value;
export const periodFormat = (period: ChartPeriod) => (period === "MONTH" ? "M/YY" : "M/D");

export const permissionPredicateForPacks = (userPermissionsList: RolePermissions[]) => {
  const possiblePermissionCombinations = [
    [RolePermissions.AssetsCreate, RolePermissions.FlowsCreate],
    [RolePermissions.AssetsCreate, RolePermissions.PacksManage],
    [RolePermissions.AssetsManage, RolePermissions.FlowsCreate],
    [RolePermissions.AssetsManage, RolePermissions.PacksManage]
  ];

  return possiblePermissionCombinations.some((permissionCombination) => intersection(userPermissionsList, permissionCombination).length === 2);
};
