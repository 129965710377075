import { FC } from "react";
import { Loader } from "semantic-ui-react";
import { LicenseTypes } from "../../../../../enums/licensing/licenseType";
import { AccountPackLicense } from "../../../../../features/Accounts/types";
import { PackLicenseType } from "../../../../../features/Licensing/Packs/types/state";
import CardThumbnail from "../../../../cards/cardThumbnail/CardThumbnail";
import LicensePicker from "../../../../LicensePicker/LicensePicker";
import { ModalWithStepsStep } from "../../../../modal/ModalWithSteps";
import { MAX_LICENSE_COUNT } from "../../constants";
import { LicenseCountInput } from "./licenseCountInput/LicenseCountInput";

import "./licensesAddingStep.scss";

export interface LicenseEditingStepPropsOwn extends ModalWithStepsStep {
  packId: number;
  isLoading: boolean;
  error?: Error;
  accountPackLicense?: AccountPackLicense;
  licenseTypes: PackLicenseType[];
  onLicenseCountChange: (licensesCount?: number) => void;
  onExpirationDateChange: (optionId?: number, date?: Date | null) => void;
}

const LicenseEditingStep: FC<LicenseEditingStepPropsOwn> = ({
  packId,
  isLoading,
  error,
  accountPackLicense,
  licenseTypes,
  onLicenseCountChange,
  onExpirationDateChange,
}) => {
  const getLicenseOptions = (options: PackLicenseType[]) => {
    return options
      .filter((lt) => lt.id !== LicenseTypes.Trial)
      .map((lt) => {
        return { id: lt.id, title: lt.name };
      });
  };

  return (
    <div className="licenses-adding-step">
      {!isLoading && (
        <div className="pack-assign-to-account-info">
          <div className="thumbnail-image">
            <CardThumbnail thumbnailUrl={accountPackLicense?.thumbnailImageUrl} />
          </div>
          <div className="pack-info">{accountPackLicense?.packTitle}</div>

          <div className="license-expires">
            <span className="licenses-wrapper">
              <LicenseCountInput
                onLicenseCountChange={(_packId: number, licensesCount?: number) => {
                  onLicenseCountChange(licensesCount);
                }}
                packId={packId}
                licensesCount={accountPackLicense?.totalLicensesCount}
                assingedLicensesCount={accountPackLicense?.assingedLicensesCount}
                maxLicenseCount={MAX_LICENSE_COUNT}
              />
            </span>
            <span className="expires">
              <LicensePicker
                options={getLicenseOptions(licenseTypes)}
                onOptionChange={(optionId?: number) => {
                  onExpirationDateChange(optionId);
                }}
                onExpirationDateChange={(date?: null | Date) => {
                  onExpirationDateChange(undefined, date);
                }}
                preselectedOptionId={accountPackLicense?.licenseTypeId}
                preselectedExpirationDate={accountPackLicense?.expirationDate}
                applyOnEditMinDateValidationRule={true}
                verticalPosition="bottom"
              />
            </span>
          </div>
        </div>
      )}
      <Loader active={isLoading} />
      {error && <div data-testid="error-info">Error loading data!</div>}
    </div>
  );
};

export default LicenseEditingStep;
