import { FC, useEffect, useMemo, useState } from "react";
import {
  lineChartFactory,
  noData,
  roundToTwoDigits,
  validLineData,
  formattedAverageTime,
  startsColor,
  completesColor,
  chartColorScale,
  QueryFilter,
  lineChartTitles,
  chartLegendLabels,
  totalActivity,
  dailyActivity,
  starts,
  completes,
} from "features/Library/Common/utils/performanceUtils";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { ColumnOption } from "interfaces/columnOptions";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { AssessmentDetails, AssessmentCards } from "features/Reporting/types/content";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { useQuery } from "@tanstack/react-query";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import dateTimeUtils from "utils/dateTimeUtils";
import { CommonAccountReportPropsExtended } from "../../types";
import { EntityType } from "features/Library/Flows/Designer/types";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { setIsReportEnabled, reset } from "features/Reporting/state/toolbar/toolbarSlice";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { doAssessmentExport } from "features/Reporting/state/content/contentActions";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import styles from "../../contentOverviewReports.module.scss";

export const getCardsReportData = (cardData: AssessmentCards) => {
  return [
    {
      statDescription: "Accounts",
      stat: cardData.Customers ?? 0,
    },
    {
      statDescription: `Total ${completes}`,
      stat: cardData.Completed ?? 0,
    },
    {
      statDescription: "Completion Rate",
      stat: cardData.CompletionRate ? `${roundToTwoDigits(cardData.CompletionRate * 100)}%` : "0%",
    },
    {
      statDescription: "Avg. Completion Time",
      stat: cardData.AverageTime ? formattedAverageTime(cardData.AverageTime) : "0s",
    },
    {
      statDescription: "Average Grade",
      stat: cardData.AverageGrade ? `${roundToTwoDigits(cardData.AverageGrade * 100)}%` : "0%",
    },
  ];
};

export type Props = CommonAccountReportPropsExtended & PropsFromRedux;

const fetchDailyData = async ({ queryKey }: QueryFilter) => {
  const result = (await contentReportingService.getAssessmentActivity(queryKey[1])).data;
  return lineChartFactory(result);
};

const fetchEngagementQuery = async ({ queryKey }: QueryFilter) => {
  const result = (await contentReportingService.getAssessmentEngagement(queryKey[1])).data;

  return [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: result.Started ?? 0,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: result.Completed ?? 0,
      fillColor: completesColor,
    },
  ];
};

const fetchCardsQuery = async ({ queryKey }: QueryFilter) => {
  return (await contentReportingService.getAssessmentCards(queryKey[1])).data;
};

const fetchDetailsQuery = async ({ queryKey }: QueryFilter) => {
  const result = (await contentReportingService.getAssessmentDetails(queryKey[1])).data;
  return result.map((item) => {
    const roundedCompletionRate = roundToTwoDigits(item.CompletionRate as number);
    return {
      ...item,
      CompletionRate: roundedCompletionRate,
      id: item.AssessmentId,
    };
  });
};

const barDataValid = (...valueArr: number[]) => valueArr.every((value) => value !== 0);

export const AssessmentReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  assessmentExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.AssessmentPerformanceOverview);

  const lineChartQuery = useQuery({
    queryKey: ["AssessmentReportDailyData", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useQuery({
    queryKey: ["AssessmentReportEngagement", filter],
    queryFn: fetchEngagementQuery,
    enabled: reportEnabled,
  });

  const cardQuery = useQuery({
    queryKey: ["AssessmentReportCards", filter],
    queryFn: fetchCardsQuery,
    enabled: reportEnabled,
  });

  const detailsQuery = useQuery({
    queryKey: ["AssessmentReportDetails", filter],
    queryFn: fetchDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: assessmentExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [assessmentExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.Started, lineChartQuery.data?.Completed],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.CumulativeStarted, lineChartQuery.data?.CumulativeCompleted],
    };
  }, [chartState, lineChartQuery.data]);

  const cardData = useMemo(() => {
    if (cardQuery.isSuccess) {
      const cards = getCardsReportData(cardQuery.data);
      return filter.showCustomers ? cards : cards.slice(1);
    }
  }, [cardQuery.data, cardQuery.isSuccess, filter.showCustomers]);

  const barDomain = useMemo(() => {
    if (engagementQuery.isSuccess && barDataValid(engagementQuery.data[0].value, engagementQuery.data[1].value)) {
      let maxValue = Math.max(...engagementQuery.data.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [engagementQuery.isSuccess, engagementQuery.data]);

  const columns: ColumnOption<AssessmentDetails>[] = useMemo(
    () => [
      {
        name: "Assessment",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <Tooltip
              target={
                filter.showCustomers ? (
                  <TextTruncate lines={2} className={styles.wordBreak}>
                    {item.AssessmentTitle}
                  </TextTruncate>
                ) : (
                  <button
                    className={styles.linkButton}
                    onClick={() =>
                      setSelectedContent({
                        id: item.AssessmentId,
                        type: EntityType.Assessment,
                        name: item.AssessmentTitle,
                      })
                    }
                  >
                    <TextTruncate lines={2} className={styles.wordBreak}>
                      {item.AssessmentTitle}
                    </TextTruncate>
                  </button>
                )
              }
              content={item.AssessmentTitle}
              showAlways
            />
          );
        },
      },
      {
        width: 4,
        name: `Total ${starts}`,
        isSortable: false,
        render(item) {
          return <div>{item.TotalStarts.toLocaleString()}</div>;
        },
      },
      {
        width: 4,
        isSortable: false,
        name: `Total ${completes}`,
        render(item) {
          return <div>{item.TotalCompletes.toLocaleString()}</div>;
        },
      },
      {
        name: "Completion Rate",
        isSortable: false,
        width: 4,
        render(item) {
          return <div>{roundToTwoDigits(item.CompletionRate as number * 100)}%</div>;
        },
      },
      {
        isSortable: false,
        name: "Avg. Time",
        width: 4,
        render(item) {
          return <div>{formattedAverageTime(item.AverageTime)}</div>;
        },
      },
      {
        width: 4,
        name: "Avg. Grade",
        isSortable: false,
        render(item) {
          return <div>{roundToTwoDigits(item.AverageGrade * 100)}%</div>;
        },
      },
      {
        isSortable: false,
        width: 4,
        name: "Last Activity",
        render(item) {
          return <div>{dateTimeUtils.formatDate(item.LastActivity)}</div>;
        },
      },
    ],
    [filter.showCustomers, setSelectedContent],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={chartLegendLabels}
          colorRange={chartColorScale}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([
                lineChartQuery.data.Started,
                lineChartQuery.data.Completed,
                lineChartQuery.data.CumulativeStarted,
                lineChartQuery.data.CumulativeCompleted,
              ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineData}
                colorRange={chartColorScale}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && barDataValid(engagementQuery.data[0].value, engagementQuery.data[1].value) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                data={engagementQuery.data}
                domain={barDomain}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={cardQuery.status}>
          {cardQuery.isSuccess && <CardReporting items={cardData!} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  assessmentExport: bindActionCreators(doAssessmentExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssessmentReport);
