import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import EmailPerformanceBody from "./EmailPerformanceBody";
import { usePerformanceBreadcrumbs } from "../../../../Common/Hooks/usePerformanceBreadcrumbs";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { reset, selectSendSessions } from "../../state/slices/emailPerformanceSlice";

import { ReportingFilter } from "../../../../../../components/reportingFilter/ReportingFilter";
import { ReportingExport } from "../../../../../../components/reportingExport/ReportingExport";
import { dateRange30 } from "features/Library/Common/utils/performanceUtils";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { bindAction, IWizardStep } from "interfaces";
import { selectExportInfo, selectExporting, selectIsExportActive } from "features/Reporting/state/export/exportSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

import "../../../../Common/utils/performanceSCSSUtils.scss";
import "./emailPerformance.scss";

export type Props = PropsFromRedux &
  IWizardStep & {
    emailId: number;
    emailTitle: string;
  };

export const EmailPerformance: FC<Props> = ({
  exportInfo,
  emailId,
  emailTitle,
  exportStatus,
  sendSessionsData,
  resetState,
  isExportEnabled,
}) => {
  const [dateFilter, setDateFilter] = useState(dateRange30());
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs("All Sends");

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  const dataEmpty = useMemo(() => !sendSessionsData?.length, [sendSessionsData?.length]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceRoot">
      <header className="emailPerformanceHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="emailTitle">Summary</h2>
        )}
        <div className="emailActions">
          <ReportingFilter
            currentFilter={dateFilter}
            callback={({ dateFrom, dateTo }) => setDateFilter({ dateFrom, dateTo })}
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exportStatus} disabled={dataEmpty} />
          )}
        </div>
      </header>
      <div className={"performanceRoot"}>
        <EmailPerformanceBody
          emailId={emailId}
          emailTitle={emailTitle}
          registerBreadcrumb={registerBreadcrumb}
          dateFilter={dateFilter}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    exportInfo: selectExportInfo(state),
    sendSessionsData: selectSendSessions(state).value,
    exportStatus: selectExporting(state),
    isExportEnabled: selectIsExportActive(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetState: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailPerformance);
